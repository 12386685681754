import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
	container: {
		marginBottom: '10px'
	},
	small: {
		width: '7%'
	},
	big: {
		width: '11%'
		// '& input': {
		// 	textTransform: 'capitalize'
		// }
	},
	medium: {
		width: '14%'
		// '& input': {
		// 	textTransform: 'capitalize'
		// }
	},
	extraxBig: {
		width: '18%',
		'& p': {
			textAlign: 'left',
			paddingLeft: '12px'
		}
	},
	text: {
		width: '100%',
		textAlign: 'center'
	}
}));
