import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Grid, TextField, CircularProgress } from '@mui/material';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import EnterpriseType from '../Inputs/EnterpriseType';
import EnterpriseOperations from '../Inputs/EnterpriseOperations';
import ChannelInput from '~components/Inputs/ChannelInput';
import SearchAgentInitials from '../../components/Inputs/InputSearchAgentInitials';
import PhoneNumberInput from '../../components/Inputs/InputForms/PhoneNumberInput';
import usePermissions from '../../hooks/usePermissions';
import { Validators } from '~util/validators';
import { Autocomplete } from '@material-ui/lab';
import { STATUS_LABELS } from '~pages/customer/ProspectsTable/columns';
import { enterpriseOperationsOptions } from '../Inputs/EnterpriseOperations';
import { enterpriseTypeOptions } from '../Inputs/EnterpriseType';
import { channelOptions } from '~components/Inputs/ChannelInput';
import DropdownPaper from '~components/Forms/DropdownPaper';
import { ErrorOption } from 'react-hook-form';
import { capitalizeAll } from '~util/capitalize';
import { ProspectValidationStatus } from '~store/actions/ActionTypes';

interface Props {
	control: Control;
	errors: FieldErrors;
	responseError: string;
	oldBussinesName: string;
	oldEmail: string;
	initialValues?: any;
	disableSpecial?: boolean;
	setError?: (name: string, error: ErrorOption) => void;
	clearErrors?: (name?: string | string[] | undefined) => void;
	isProfile?: boolean;
}

const SignUpForm: React.FC<Props> = ({
	control,
	errors,
	responseError,
	oldBussinesName,
	oldEmail,
	initialValues,
	disableSpecial,
	setError,
	clearErrors,
	isProfile
}) => {
	const { isRoot } = usePermissions();
	const location = useLocation();
	const params: { id?: string } = useParams();
	const isCustomerPage = useMemo(() => location.pathname.includes('cliente'), [location]);
	const isProspectPage = useMemo(() => location.pathname.includes('prospecto'), [location]);
	const enterpriseOpsProspectTitle = useMemo(() => {
		const item = enterpriseOperationsOptions.find(
			(item) => item.value === initialValues?.set_of_operations
		);
		return item ? item.title : '';
	}, [initialValues]);
	const companyTypeProspectTitle = useMemo(() => {
		const item = enterpriseTypeOptions.find((item) => item.value === initialValues?.company_type);
		return item ? item.title : '';
	}, [initialValues]);
	const channelProspectTitle = useMemo(() => {
		const item = channelOptions.find((item) => item.value === initialValues?.channel);
		return item ? item.title : '';
	}, [initialValues]);

	if (params?.id && !initialValues) {
		return (
			<Grid container justifyContent='center'>
				<CircularProgress />
			</Grid>
		);
	}
	return (
		<React.Fragment>
			{/* Business name */}
			{(isCustomerPage || isProspectPage || isProfile) && (
				<Grid item xs={12} sm={4}>
					<Controller
						name='business_name'
						rules={{
							required: 'Ingrese el Nombre Comercial',
							minLength: { value: 1, message: 'Al menos 1 carácter' },
							maxLength: { value: 50, message: 'La longitud máxima es de 50 caracteres' },
							validate: (bn: string) => {
								if (responseError === 'CUSTOMER_ALREADY_EXISTS' && bn === oldBussinesName) {
									return 'Ya existe un registro con esta información';
								}
								return true;
							}
						}}
						as={
							<TextField
								variant='filled'
								error={Boolean(errors?.business_name)}
								fullWidth
								label='Nombre Comercial&nbsp;*'
								autoComplete='none'
							/>
						}
						control={control}
						defaultValue={initialValues?.business_name ? initialValues?.business_name : ''}
						helperText={errors?.business_name?.message}
						inputProps={{ style: { textTransform: 'uppercase' } }}
					/>
				</Grid>
			)}

			{/* Name */}
			<Grid item xs={12} sm={4}>
				<Controller
					name='name'
					rules={{
						required: 'Ingrese su Nombre',
						pattern: {
							value: /^[A-Za-zÁÉÍÓÚÑñáéíóú .]+$/,
							message: 'Ingrese únicamente letras'
						},
						maxLength: {
							value: 50,
							message: 'El nombre es muy largo, max. 50'
						}
					}}
					as={
						<TextField
							disabled={disableSpecial && !isProfile}
							variant='filled'
							error={Boolean(errors?.name)}
							fullWidth
							label='Nombre&nbsp;*'
							autoComplete='none'
						/>
					}
					control={control}
					defaultValue={
						!isProspectPage && initialValues?.name
							? initialValues?.name
							: isProspectPage || isProfile
							? initialValues?.contact.name
							: ''
					}
					helperText={errors?.name?.message}
				/>
			</Grid>

			{/* Last name */}
			{(isCustomerPage || isProspectPage || isProfile) && (
				<Grid item xs={12} sm={4}>
					<Controller
						name='last_name'
						rules={
							isProspectPage
								? {
										pattern: {
											value: /^[A-Za-zÁÉÍÓÚÑñáéíóú .]+$/,
											message: 'Ingrese únicamente letras'
										},
										maxLength: {
											value: 50,
											message: 'El apellido es muy largo, max. 50'
										}
								  }
								: {
										required: 'Ingrese su Apellido',
										pattern: {
											value: /^[A-Za-zÁÉÍÓÚÑñáéíóú .]+$/,
											message: 'Ingrese únicamente letras'
										},
										maxLength: {
											value: 50,
											message: 'El apellido es muy largo, max. 50'
										}
								  }
						}
						as={
							<TextField
								disabled={disableSpecial && !isProfile}
								variant='filled'
								error={Boolean(errors?.last_name)}
								fullWidth
								label='Apellido&nbsp;*'
								autoComplete='none'
							/>
						}
						control={control}
						defaultValue={
							!isProspectPage && initialValues?.last_name
								? initialValues?.last_name
								: isProspectPage || isProfile
								? initialValues?.contact.last_name
								: ''
						}
						helperText={errors?.last_name?.message}
					/>
				</Grid>
			)}

			{/* Email */}
			<Grid item xs={12} sm={4}>
				<Controller
					name='email'
					rules={{
						required: 'Ingrese su Email',
						pattern: {
							value: Validators.Email,
							message: 'Ingrese un correo válido'
						},
						validate: (email: string) => {
							if (responseError === 'USER_ALREADY_EXISTS' && email === oldEmail) {
								return 'Ya existe un registro con esta información';
							}
							return true;
						}
					}}
					as={
						<TextField
							disabled={disableSpecial && !isProfile}
							variant='filled'
							error={Boolean(errors?.email)}
							fullWidth
							label='Correo&nbsp;*'
							autoComplete='none'
						/>
					}
					control={control}
					defaultValue={
						!isProspectPage && initialValues?.email
							? initialValues?.email
							: isProspectPage || isProfile
							? initialValues?.contact.email
							: ''
					}
					helperText={errors?.email?.message}
				/>
			</Grid>

			{/* Phone */}
			<Grid item xs={12} sm={4}>
				<PhoneNumberInput
					control={control}
					errors={errors}
					name='phone_number'
					disabled={disableSpecial && !isProfile}
					defaultValue={initialValues?.phone_number || ''}
					clearErrors={clearErrors}
					setError={setError}
				/>
			</Grid>

			{/* Regime */}
			<Grid item xs={12} sm={4}>
				<Controller
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							options={['Persona física', 'Persona moral']}
							getOptionLabel={(option) => capitalizeAll(option)}
							getOptionSelected={(option, value) => option === value}
							onChange={(_, data) => onChange(data)}
							renderInput={(params) => (
								<TextField
									{...params}
									disabled={isProfile}
									label='Régimen&nbsp;*'
									variant='filled'
									error={Boolean(errors?.business_type)}
									helperText={errors?.business_type?.message}
									autoComplete='off'
								/>
							)}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					name='business_type'
					control={control}
					rules={{ required: 'Ingrese el régimen' }}
					defaultValue={!!initialValues?.business_type ? initialValues?.business_type : null}
				/>
			</Grid>

			{/* Type */}
			{(isCustomerPage || isProspectPage || isProfile) && (
				<Grid item xs={12} sm={4}>
					<EnterpriseType
						defaultValue={
							!isProfile
								? {
										title: companyTypeProspectTitle,
										value: initialValues?.company_type ?? ''
								  }
								: undefined
						}
						control={control}
						errors={errors}
					/>
				</Grid>
			)}

			{/* Giro */}
			{(isCustomerPage || isProspectPage || isProfile) && (
				<Grid item xs={12} sm={4}>
					<EnterpriseOperations
						defaultValue={{
							title: enterpriseOpsProspectTitle ?? '',
							value: initialValues?.set_of_operations ?? ''
						}}
						control={control}
						errors={errors}
						disabled={isProfile}
					/>
				</Grid>
			)}

			{/* Channel */}
			{(isCustomerPage || isProspectPage || isProfile) && (
				<Grid item xs={12} sm={4}>
					<ChannelInput
						defaultValue={
							isProfile
								? undefined
								: isProspectPage
								? { title: channelProspectTitle, value: initialValues?.channel }
								: initialValues?.channel
						}
						control={control}
						errors={errors}
					/>
				</Grid>
			)}

			{/* Web Site  */}
			<Grid item xs={12} sm={6}>
				<Controller
					name='webpage'
					as={
						<TextField
							variant='filled'
							error={Boolean(errors?.webpage)}
							fullWidth
							label='Página web'
						/>
					}
					control={control}
					rules={{
						maxLength: { value: 200, message: 'El URL es demasiado largo' },
						validate: (value) =>
							value === '' ? true : Validators.URL(value as string) || 'Debe ser una URL'
					}}
					defaultValue={
						isProfile ? undefined : initialValues?.webpage ? initialValues?.webpage : ''
					}
					helperText={errors?.webpage?.message}
				/>
			</Grid>

			{/* TODO: seller? */}
			{/* Assing agent just for root users  */}
			{isRoot || isProfile ? (
				<Grid item xs={12} sm={6}>
					<SearchAgentInitials
						defaultValue={initialValues?.seller}
						control={control}
						errors={errors}
						defaultValueProspect={initialValues?.seller}
						disabled={isProfile}
					/>
				</Grid>
			) : null}

			{isProspectPage && !isProfile && (
				<Grid item xs={12} sm={6}>
					<Controller
						render={({ onChange, ...propsown }) => (
							<Autocomplete
								{...propsown}
								openOnFocus
								options={[
									{ name: 'Validado', value: ProspectValidationStatus.VALIDATED },
									{ name: 'Prospección', value: ProspectValidationStatus.PROSPECTING },
									{ name: 'Documentación', value: ProspectValidationStatus.DOCUMENTATION },
									{ name: 'Registro', value: ProspectValidationStatus.REGISTER }
								]}
								getOptionLabel={(option) => option.name}
								getOptionSelected={(option, value) => option.value === value.value}
								onChange={(_, data) => {
									if (!data) return;
									onChange(data);
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										disabled={isProfile}
										label='Estatus&nbsp;*'
										variant='filled'
										error={Boolean(errors?.validation_status)}
										helperText={errors?.validation_status?.message}
										autoComplete='none'
									/>
								)}
								PaperComponent={(paperProps) => (
									<DropdownPaper>{paperProps.children}</DropdownPaper>
								)}
							/>
						)}
						name='validation_status'
						control={control}
						rules={{ required: true }}
						defaultValue={
							initialValues
								? {
										value: initialValues?.validation_status,
										name:
											STATUS_LABELS[initialValues?.validation_status.normalize()] ??
											initialValues?.validation_status
								  }
								: null
						}
					/>
				</Grid>
			)}
		</React.Fragment>
	);
};

export default SignUpForm;
