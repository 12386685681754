import { FC, useEffect, useState, useRef } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { ArrowLeft } from 'react-feather';
import { shallow } from 'zustand/shallow';
import { Grid } from '@mui/material';
import Skeleton from '@material-ui/lab/Skeleton';
import { useForm, useWatch } from 'react-hook-form';
import {
	ReportItem,
	ReportName,
	ReportType,
	SchemaType,
	ReportSubType,
	ReportFileType,
	ReportNumber
} from '~store/actions/ActionTypes';
// Store and context
import useReportStore from '~store/ReportStore';
// Hooks
import useCustomerForm, { CustomerFormValues } from './useCustomerForm';
import { useCardStyles } from '~hooks/useStyles';
import useStyles from './style';
import usePermissions from '~hooks/usePermissions';
import { useSelector } from '~store/store';
import useQuery from '~hooks/useQuery';
// Components
import ClientForm from '~components/Forms/Client/ClientForm';
import ClientFiscalForm from '~components/Forms/Client/ClientFiscalForm';
import ServicesSelector from '~components/Forms/ServicesSelector';
import ControlBar from '~components/Bar/ControlBar';
import CreateOrUpdateClientControl from './CreateOrUpdateClientControl';
import { HeadingOne } from '~components/Headings';
import ClientReportsConfig from '~components/Forms/Client/ClientReports/ClientReportsConfig';
import ConfirmActionDialog from '~components/Modals/ConfirmActionDialog';

const CreateOrUpdateCustomer: FC = () => {
	const classes = useStyles();
	const { cardClasses } = useCardStyles();
	const history = useHistory();
	const location: { state: { fromCreateDispersion?: boolean } } = useLocation();
	const { id } = useParams<{ id?: string }>();
	const query = useQuery();

	const [oldEmail, setOldEmail] = useState<null | string>(null);
	const [oldBussinesName, setBusinessName] = useState<null | string>(null);
	const [guidesType, setGuidesType] = useState('');
	const [overweighType, setOverweightType] = useState('');
	const [insuranceType, setInsuranceType] = useState('');
	const [isOpenDialog, setIsOpenDialog] = useState(false);
	const [formPayload, setFormPayload] = useState<Partial<CustomerFormValues> | null>(null);

	const [guidesRowOfReport, setGuidesRowOfReport] = useState<ReportItem>();
	const [overweightRowOdReport, setOverweightRwOfReport] = useState<ReportItem>();
	const [insuranceRowOfReport, setInsuranceRowOfReport] = useState<ReportItem>();

	const reportsRef = useRef<HTMLElement | null>(null);
	const servicessRef = useRef<HTMLElement | null>(null);

	const { servicesInfoDraftCUCustomer } = useSelector((state) => state.cuCustomer, shallow);

	const {
		save,
		loading,
		editing,
		posting,
		customer,
		responseError,
		getPayloadServices,
		formatPayload,
		saveRates,
		getNewServices
	} = useCustomerForm(location?.state?.fromCreateDispersion);

	const { isRoot, isCustomer } = usePermissions();

	const [
		overweigthReportNumber,
		insuranceReportNumber,
		guidesCutoffDate,
		overweightCutoffDate,
		insuranceCutoffDate,
		setInsirancetCutoffDate,
		setoverweightCutoffDate
	] = useReportStore(
		(state) => [
			state.overweigthReportNumber,
			state.insuranceReportNumber,
			state.guidesCutoffDate,
			state.overweightCutoffDate,
			state.insuranceCutoffDate,
			state.setInsirancetCutoffDate,
			state.setoverweightCutoffDate
		],
		shallow
	);

	//Saving the status that the client has in the db
	const [status, setStatus] = useState(customer?.status);
	//Control to know if the client has been blocked
	const [blocked, setBlocked] = useState<boolean>(false);

	useEffect(() => {
		customer?.status && setStatus(customer.status);
		setBlocked(customer?.status === 'LOCKED');
	}, [customer?.status]);

	/** Setting the type of report that is sent */
	useEffect(() => {
		if (overweigthReportNumber === '2' && insuranceReportNumber === '3') {
			setGuidesType(ReportType.ONLY_LABELS);
			setOverweightType(ReportType.ONLY_OVERWEIGHTS);
			setInsuranceType(ReportType.ONLY_INSURANCES);
		} else if (overweigthReportNumber === '1' && insuranceReportNumber === '1') {
			setGuidesType(ReportType.ALL);
			setOverweightType(ReportType.ALL);
			setInsuranceType(ReportType.ALL);
		} else if (overweigthReportNumber === '1' && insuranceReportNumber === '2') {
			setGuidesType(ReportType.LABELS_AND_OVERWEIGHTS);
			setOverweightType(ReportType.LABELS_AND_OVERWEIGHTS);
			setInsuranceType(ReportType.ONLY_INSURANCES);
		} else if (overweigthReportNumber === '2' && insuranceReportNumber === '2') {
			setGuidesType(ReportType.ONLY_LABELS);
			setOverweightType(ReportType.OVERWEIGHTS_AND_INSURANCES);
			setInsuranceType(ReportType.OVERWEIGHTS_AND_INSURANCES);
		} else {
			setGuidesType('guías y seguros');
			setOverweightType('solo sobrepesos');
			setInsuranceType('guías y seguros');
		}
	}, [overweigthReportNumber, insuranceReportNumber]);

	const {
		handleSubmit,
		control,
		register,
		clearErrors,
		trigger,
		setValue,
		errors,
		watch,
		setError
	} = useForm<CustomerFormValues>({ mode: 'onTouched' });

	const {
		// General
		name,
		last_name,
		email,
		phone_number,
		business_name,
		// enterprise_operations,
		company_type,
		business_type,

		// Fiscal info
		modality,
		conditions,
		seller,

		//Reports
		guides_schema,
		guides_subtype,
		guides_date,
		guides_conditions_report,
		guides_pre_invoice_days,

		// overweight_schema,
		overweight_subtype,
		overweight_date,
		overweight_conditions_report,
		overweight_pre_invoice_days,
		overweight_name,

		// insurance_schema,
		insurance_subtype,
		insurance_date,
		insurance_conditions_report,
		insurance_pre_invoice_days
	} = watch();

	const reportFileType = useWatch({
		control,
		name: 'report_file_type'
	});

	//Saving default and changed values for the reports
	useEffect(() => {
		// Guides
		const guidesSubtype =
			guidesCutoffDate === SchemaType.BIWEEKLY
				? ReportSubType.BIWEEK
				: guidesCutoffDate === SchemaType.WEEKLY
				? ReportSubType.WEEK
				: guidesCutoffDate === SchemaType.IMMEDIATE || guidesCutoffDate === SchemaType.INDEPENDENT
				? ''
				: guides_subtype;
		const guidesDate =
			guidesCutoffDate === SchemaType.IMMEDIATE || guidesCutoffDate === SchemaType.INDEPENDENT
				? ''
				: guides_date;
		const guidesCondition =
			typeof guides_conditions_report === 'object'
				? guides_conditions_report?.title
				: guides_conditions_report;
		const guidesPreinvoice =
			typeof guides_pre_invoice_days === 'object'
				? guides_pre_invoice_days?.title
				: guides_pre_invoice_days;

		setGuidesRowOfReport({
			name: ReportName.LABELS,
			type: guidesType,
			sub_type: guidesSubtype?.toLowerCase(),
			schema: guidesCutoffDate?.toLowerCase(),
			date: guidesDate?.toLowerCase(),
			conditions: guidesCondition,
			pre_invoice_days: guidesPreinvoice
		});

		// Overweight
		const overweightSubtype =
			overweightCutoffDate === SchemaType.BIWEEKLY
				? ReportSubType.BIWEEK
				: overweightCutoffDate === SchemaType.WEEKLY
				? ReportSubType.WEEK
				: overweightCutoffDate === SchemaType.IMMEDIATE ||
				  overweightCutoffDate === SchemaType.INDEPENDENT
				? ''
				: overweight_subtype;
		const overweightDate =
			overweightCutoffDate === SchemaType.IMMEDIATE ||
			overweightCutoffDate === SchemaType.INDEPENDENT
				? ''
				: overweight_date;
		const overweightConditions =
			typeof overweight_conditions_report === 'object'
				? overweight_conditions_report?.title
				: overweight_conditions_report;
		const overweightPreinvoice =
			typeof overweight_pre_invoice_days === 'object'
				? overweight_pre_invoice_days?.title
				: overweight_pre_invoice_days;

		setOverweightRwOfReport({
			name: overweight_name?.value ?? ReportName.OVERWEIGHTS,
			type: overweighType,
			sub_type: overweightSubtype?.toLowerCase(),
			schema: overweightCutoffDate?.toLowerCase(),
			date: overweightDate?.toLowerCase(),
			conditions: overweightConditions,
			pre_invoice_days: overweightPreinvoice
		});

		// Insurance
		const insuranceSubtype =
			insuranceCutoffDate === SchemaType.BIWEEKLY
				? ReportSubType.BIWEEK
				: insuranceCutoffDate === SchemaType.WEEKLY
				? ReportSubType.WEEK
				: insuranceCutoffDate === SchemaType.IMMEDIATE ||
				  insuranceCutoffDate === SchemaType.INDEPENDENT
				? ''
				: insurance_subtype;
		const insuranceDate =
			insuranceCutoffDate === SchemaType.IMMEDIATE || insuranceCutoffDate === SchemaType.INDEPENDENT
				? ''
				: insurance_date;
		const insuranceCondition =
			typeof insurance_conditions_report === 'object'
				? insurance_conditions_report?.title
				: insurance_conditions_report;
		const insurancePreinvoice =
			typeof insurance_pre_invoice_days === 'object'
				? insurance_pre_invoice_days?.title
				: insurance_pre_invoice_days;
		setInsuranceRowOfReport({
			name: ReportName.INSURANCES,
			type: insuranceType,
			sub_type: insuranceSubtype?.toLowerCase(),
			schema: insuranceCutoffDate?.toLowerCase(),
			date: insuranceDate?.toLowerCase(),
			conditions: insuranceCondition,
			pre_invoice_days: insurancePreinvoice
		});
	}, [
		guidesCutoffDate,
		overweightCutoffDate,
		insuranceCutoffDate,
		guidesType,
		overweighType,
		insuranceType,
		customer?.reports,
		guides_subtype,
		guides_date,
		guides_conditions_report,
		guides_pre_invoice_days,
		overweight_subtype,
		overweight_date,
		overweight_conditions_report,
		overweight_pre_invoice_days,
		insurance_subtype,
		insurance_date,
		insurance_conditions_report,
		insurance_pre_invoice_days,
		overweight_name
	]);

	useEffect(() => {
		const sameFileConfig = reportFileType === ReportFileType.SAME_FILE;
		const setGuidesCutConfig = (
			type: 'overweight' | 'insurance',
			setCutoffDate: (cuttofDate: string) => void
		) => {
			setCutoffDate(guidesCutoffDate?.toLowerCase());
			setValue(`${type}_schema`, guides_schema);
			setValue(`${type}_subtype`, guides_subtype);
			setValue(`${type}_date`, guides_date);
		};

		if (sameFileConfig) {
			if (
				overweight_name?.value === ReportName.OVERWEIGHTS &&
				overweigthReportNumber === ReportNumber.ONE
			) {
				setGuidesCutConfig('overweight', setoverweightCutoffDate);
			}
			setValue('overweight_conditions_report', guides_conditions_report);
			setValue('overweight_pre_invoice_days', guides_pre_invoice_days);

			if (insuranceReportNumber === ReportNumber.ONE) {
				setGuidesCutConfig('insurance', setInsirancetCutoffDate);
			}
			setValue('insurance_conditions_report', guides_conditions_report);
			setValue('insurance_pre_invoice_days', guides_pre_invoice_days);
		}
	}, [
		guidesCutoffDate,
		overweightCutoffDate,
		insuranceCutoffDate,
		guides_subtype,
		guides_date,
		guides_conditions_report,
		guides_pre_invoice_days,
		reportFileType,
		guides_schema,
		overweigthReportNumber,
		insuranceReportNumber,
		setInsirancetCutoffDate,
		setoverweightCutoffDate,
		setValue,
		overweight_name
	]);

	const handleStatus = () => {
		setBlocked((prev) => !prev);
		if (blocked) {
			setStatus('ACTIVE');
		} else {
			setStatus('LOCKED');
		}
	};

	// Handle Submit
	const onSubmit = async (data: CustomerFormValues) => {
		// Discount field alert for services other than estafeta
		// if (
		// 	servicesInfoDraftCUCustomer
		// 		.filter((item) => item.discount === 0)
		// 		.some((item) => item.carrier !== 'Estafeta')
		// ) {
		// 	setNotification({
		// 		message: 'Algunos servicios no tienen aumento definido.',
		// 		severity: 'warning'
		// 	});
		// 	return;
		// }

		setOldEmail(data.email);
		setBusinessName(data.business_name);

		const payload = formatPayload({
			data,
			guidesRowOfReport,
			overweightRowOdReport,
			insuranceRowOfReport,
			status
		});

		// Confirm or not new services for all users
		const newServices = getNewServices();
		if (!!newServices?.length) {
			setFormPayload(payload);
			setIsOpenDialog(true);
			// if (location?.state?.fromCreateDispersion) {
			// 	const service = newServices[0];
			// 	if (!service) return;
			// 	setSelectedCarrier(service.carrier);
			// 	setService(service?.name);
			// }
			return;
		}

		const { success, customerId } = await save(payload);
		if (!success) {
			await trigger('email');
			await trigger('business_name');

			// Save rate if it's a new customer or if there is new services from estafeta
			const newEstafetaServices = getNewServices(true);
			if (!id || !!newEstafetaServices?.length) {
				await saveRates(customerId);
			}
		}
	};

	// Trigger error if resource exists
	// this is if the email or the bussiness name already exists
	useEffect(() => {
		if (responseError) {
			trigger(['email', 'business_name']);
		}
	}, [responseError, trigger]);

	useEffect(() => {
		if (
			errors.guides_date ||
			errors.guides_conditions_report ||
			errors.guides_schema ||
			errors.guides_subtype ||
			errors.guides_pre_invoice_days ||
			errors.insurance_conditions_report ||
			errors.insurance_date ||
			errors.insurance_pre_invoice_days ||
			errors.insurance_subtype ||
			errors.overweight_date ||
			errors.overweight_conditions_report ||
			errors.overweight_pre_invoice_days ||
			errors.overweight_subtype
		) {
			reportsRef.current?.scrollIntoView({ behavior: 'smooth' });
		}
	}, [errors]);

	// Scroll to services if is commig from add service in create dispersion
	useEffect(() => {
		if (location?.state?.fromCreateDispersion) {
			servicessRef.current?.scrollIntoView({ behavior: 'smooth' });
		}
	}, [location?.state]);

	if (loading) {
		return (
			<>
				<Skeleton
					variant='rect'
					height={800}
					className={clsx(cardClasses.card, classes.container)}
					style={{ backgroundColor: '#343434' }}
				/>
			</>
		);
	}

	return (
		<Grid
			container
			direction='row'
			justifyContent='center'
			className={clsx(cardClasses.card, classes.container)}
		>
			<Grid container item xs={12} direction='column' className={classes.title}>
				<Grid container direction='row' alignItems='center'>
					<button
						className={classes.leftArrow}
						onClick={() => {
							const fromDispersionQuery = query.get('from') === 'dispersion';
							if (!location?.state?.fromCreateDispersion && !fromDispersionQuery) {
								history.push('/clientes');
							} else {
								history.push('/comprar-guias');
							}
						}}
					>
						<ArrowLeft size={32} />
					</button>
					<HeadingOne
						text={
							(isCustomer
								? customer?.business_name
								: editing
								? `Edición de ${customer?.business_name}`
								: 'Alta de Cliente') ?? ''
						}
					/>
				</Grid>
			</Grid>

			<form autoComplete='off'>
				<Grid container direction='row' justifyContent='center'>
					{/* Datos generales */}
					<Grid item xs={12}>
						<ClientForm
							customID={customer?.customID || ''}
							initialValues={customer}
							oldBussinesName={oldBussinesName || ''}
							oldEmail={oldEmail || ''}
							responseError={responseError || ''}
							control={control}
							errors={errors}
							disableSpecial={editing}
							blocked={blocked}
							handleStatus={handleStatus}
							clearErrors={clearErrors}
							setError={setError}
						/>
					</Grid>

					{/* Servicios */}
					<Grid item xs={12} ref={(instance) => (servicessRef.current = instance)}>
						<ServicesSelector
							register={register}
							control={control}
							onChange={() => clearErrors('services')}
							defaultValues={servicesInfoDraftCUCustomer as any}
							editable
							customer={customer}
							fromDispersion={location?.state?.fromCreateDispersion}
							// onCreateRateFromDispersion={handleSubmit(onSubmit)}
						/>
					</Grid>

					{/* Facturacion */}
					<Grid item xs={12}>
						<ClientFiscalForm
							clearSchema={() => clearErrors('schema')}
							initialValues={customer}
							control={control}
							errors={errors}
							setValue={setValue}
						/>
					</Grid>

					{/* Reportes */}
					<Grid item xs={12} ref={(instance) => (reportsRef.current = instance)}>
						<ClientReportsConfig
							control={control}
							initialValues={customer?.reports}
							errors={errors}
							setValue={setValue}
						/>
					</Grid>
				</Grid>
			</form>

			<ControlBar
				onContinue={
					handleSubmit(onSubmit)
					// setTemporatyValues // to see temp values
				}
				onLeftControl={history.goBack}
				isLoading={posting}
				rightText={editing ? 'Actualizar' : 'Guardar'}
			>
				<CreateOrUpdateClientControl
					generalInfo={Boolean(
						name &&
							last_name &&
							email &&
							phone_number &&
							business_name &&
							company_type &&
							business_type &&
							(isRoot ? seller : true) &&
							!errors?.name &&
							!errors?.last_name &&
							!errors?.email &&
							!errors?.phone_number &&
							!errors?.business_name &&
							!errors?.enterprise_operations &&
							(isRoot ? seller && !errors?.seller : true)
					)}
					legalInfo={Boolean(modality && conditions && !errors?.modality && !errors?.conditions)}
					services={isCustomer || servicesInfoDraftCUCustomer.length > 0}
				/>
			</ControlBar>
			<ConfirmActionDialog
				isOpen={isOpenDialog}
				onClose={() => setIsOpenDialog(false)}
				bodyText={'¿Deseas agregar los nuevos servicios a todos los usuarios?'}
				handleClickConfirm={async () => {
					setIsOpenDialog(false);
					const payload = location?.state?.fromCreateDispersion
						? {
								...formPayload,
								services: getPayloadServices()
						  }
						: formPayload;
					const { success, customerId } = await save({ ...payload, add_services_to_users: true });
					// Save rate if it's a new customer or if there is new services from estafeta
					const newEstafetaServices = getNewServices(true);
					if (success && (!id || !!newEstafetaServices?.length)) {
						await saveRates(customerId);
					}
				}}
				handleClickCancel={async () => {
					setIsOpenDialog(false);
					const payload = location?.state?.fromCreateDispersion
						? {
								...formPayload,
								services: getPayloadServices()
						  }
						: formPayload;
					const { success, customerId } = await save({ ...payload, add_services_to_users: false });
					// Save rate if it's a new customer or if there is new services from estafeta
					const newEstafetaServices = getNewServices(true);
					if (success && (!id || !!newEstafetaServices?.length)) {
						await saveRates(customerId);
					}
				}}
				confirmBtnLabel={'Sí'}
				cancelBtnLabel={'No'}
			/>
		</Grid>
	);
};

export default CreateOrUpdateCustomer;
