import React from 'react';
import { styled } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { Omit } from '@material-ui/types';

interface MyButtonProps {
	color: 'red' | 'blue';
}

const GradientButton = styled(
	({ color, ...other }: MyButtonProps & Omit<ButtonProps, keyof MyButtonProps>) => (
		<Button {...other} />
	)
)({
	background: 'linear-gradient(0deg, #2196F3 30%, #21CBF3 90%)',
	border: 0,
	// borderRadius: 3,
	boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .1)',
	color: 'white',
	height: 48,
	padding: '0 30px',
	margin: 4
});

export default GradientButton;
