import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { User, Folder, ShoppingCart, CheckCircle, DollarSign } from 'react-feather';

const useStyles = makeStyles(() => ({
	font: {
		fontWeight: 'bold',
		fontSize: 15
	}
}));

interface Props {
	generalInfo: boolean;
	legalInfo: boolean;
	services: boolean;
	addRateStep?: boolean;
	rateInfo?: boolean;
}

const CreateOrUpdateClientControl: React.FC<Props> = ({
	generalInfo,
	legalInfo,
	services,
	addRateStep,
	rateInfo
}) => {
	const classes = useStyles();

	return (
		<Grid container justifyContent='flex-start' className={classes.font}>
			<Grid item container alignItems='flex-end' justifyContent='center' xs={!addRateStep ? 4 : 3}>
				{generalInfo ? <CheckCircle color='#6FE46D' /> : <User size={22} />}
				&nbsp;&nbsp;Generales
			</Grid>
			<Grid item container alignItems='flex-end' justifyContent='center' xs={!addRateStep ? 4 : 3}>
				{legalInfo ? <CheckCircle color='#6FE46D' /> : <Folder size={22} />}
				&nbsp;&nbsp;Facturación
			</Grid>
			<Grid item container alignItems='flex-end' justifyContent='center' xs={!addRateStep ? 4 : 3}>
				{services ? <CheckCircle color='#6FE46D' /> : <ShoppingCart size={22} />}
				&nbsp;&nbsp;Servicios
			</Grid>
			{addRateStep && (
				<Grid item container alignItems='flex-end' justifyContent='center' xs={3}>
					{rateInfo ? <CheckCircle color='#6FE46D' /> : <DollarSign size={22} />}
					&nbsp;Tarifas
				</Grid>
			)}
		</Grid>
	);
};

export default CreateOrUpdateClientControl;
