import produce from 'immer';
import { create } from 'zustand';

type ReportId = {
	overweigthReportNumber?: string;
	setOverweightReportNumber: (overweigthReportNumber: string) => void;
	insuranceReportNumber?: string;
	setInsuranceReportNumber: (insuranceReportNumber: string) => void;

	/** Cutoff Date */
	guidesCutoffDate: string;
	setGuidesCutoffDate: (guidesCutoffDate: string) => void;
	overweightCutoffDate: string;
	setoverweightCutoffDate: (guidesCutoffDate: string) => void;
	insuranceCutoffDate: string;
	setInsirancetCutoffDate: (guidesCutoffDate: string) => void;

	/** Type */
	type: string;
	setType: (type: string) => void;
};

const useReportStore = create<ReportId>((set) => ({
	overweigthReportNumber: undefined,
	setOverweightReportNumber: (overweigthReportNumber: string) =>
		set((state) =>
			produce(state, (draft) => {
				draft.overweigthReportNumber = overweigthReportNumber;
			})
		),
	insuranceReportNumber: undefined,
	setInsuranceReportNumber: (insuranceReportNumber: string) =>
		set((state) =>
			produce(state, (draft) => {
				draft.insuranceReportNumber = insuranceReportNumber;
			})
		),
	guidesCutoffDate: '',
	setGuidesCutoffDate: (guidesCutoffDate: string) =>
		set((state) =>
			produce(state, (draft) => {
				draft.guidesCutoffDate = guidesCutoffDate;
			})
		),
	overweightCutoffDate: '',
	setoverweightCutoffDate: (overweightCutoffDate: string) =>
		set((state) =>
			produce(state, (draft) => {
				draft.overweightCutoffDate = overweightCutoffDate;
			})
		),
	insuranceCutoffDate: '',
	setInsirancetCutoffDate: (insuranceCutoffDate: string) =>
		set((state) =>
			produce(state, (draft) => {
				draft.insuranceCutoffDate = insuranceCutoffDate;
			})
		),
	type: '',
	setType: (type: string) =>
		set((state) =>
			produce(state, (draft) => {
				draft.type = type;
			})
		)
}));

export default useReportStore;
