import { useContext, useEffect, useState, useCallback, ChangeEvent, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
	Accordion,
	AccordionSummary,
	Button,
	CircularProgress,
	Grid,
	TextField,
	Tooltip
} from '@mui/material';
import { Autocomplete } from '@material-ui/lab';
import { shallow } from 'zustand/shallow';
import axios from 'axios';
import AuthContext from '~context/AuthContext';
import DrawContext from '~context/DrawContext';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import { FileText, Plus } from 'react-feather';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Hooks and store
import { useCardStyles } from '~hooks/useStyles';
import useRateStore from '~store/useRateStore';
import { setServicesCUCustomer } from '~store/actions/agents';
import usePermissions from '~hooks/usePermissions';
import { useStyles } from './styles';
// Components
import { HeadingOne, HeadingTwo } from '~components/Headings';
import DropdownPaper from '~components/Forms/DropdownPaper';
// Utils
import capitalize from '~util/capitalize';
import axiosInstante from '~util/axios';
import clsx from 'clsx';
import { MUIDataGridTranslations } from '~util/index';
import { CARRIERS } from '~util/logos';
import { useDropzone } from 'react-dropzone/.';

type RateFileType = {
	id: number;
	carrier: string;
	url: string;
	name?: string;
	range?: string;
	service?: string;
};

const ProfileRates = () => {
	const { cardClasses } = useCardStyles();
	const classes = useStyles();

	const params: { id?: string } = useParams();
	const dispatch = useDispatch();

	const [ratesList, setRatesList] = useState<RateFileType[] | null>(null);
	const [inactivesRatesList, setInactivesRatesList] = useState<RateFileType[] | null>(null);
	const [selectedRate, setSelectedRate] = useState<Partial<RateFileType> | null>(null);
	const [expandActivePDF, setExpandActivePDF] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { isRootOrAgent } = usePermissions();
	const { currentUser } = useContext(AuthContext);
	const { setNotification } = useContext(DrawContext);
	const [setService] = useRateStore((state) => [state.setService], shallow);

	const isEstafetaRate = (carrier?: string) =>
		carrier && carrier?.toLowerCase() === CARRIERS.ESTAFETA;

	const saveTariff = useCallback(
		async (file: File) => {
			if (!params?.id || !selectedRate?.carrier) return;
			try {
				const fileItem = new File([file], file.name, {
					type: file.type
				});

				const formData = new FormData();
				formData.append('files', fileItem);
				formData.append('customer_id', params?.id);
				formData.append('carrier', selectedRate?.carrier?.toLowerCase());

				await axiosInstante.post('/api/customer/tariffs', formData);
				setNotification({
					message: 'Se subió con éxito',
					severity: 'success'
				});
			} catch (error) {
				const errorMessage = 'Hubo un error al subir la tarifa';
				setNotification({
					message: axios.isAxiosError(error)
						? error?.response?.data?.description ?? errorMessage
						: errorMessage,
					severity: 'error'
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[params?.id, selectedRate]
	);

	const onDrop = useCallback(
		async (acceptedFile: any) => {
			if (!acceptedFile?.length) return;
			setIsSubmitting(true);
			const file = acceptedFile[0];
			const fileItem = new File([file], file.name, {
				type: file.type
			});
			await saveTariff(fileItem);
			setIsSubmitting(false);
		},
		[saveTariff]
	);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		noDrag: true,
		maxFiles: 1,
		multiple: false
	});

	const ratesOptions = useMemo(() => {
		let list = ratesList;
		const countMap = ratesList?.reduce((acc, item) => {
			// @ts-ignore
			acc[item?.carrier] = (acc[item?.carrier] || 0) + 1;
			return acc;
		}, {});
		// @ts-ignore
		const duplicates = ratesList?.filter((item) => countMap && countMap[item?.carrier] > 1);
		if (!!duplicates?.length && duplicates?.length > 1) {
			// @ts-ignore
			list = Object.keys(countMap)?.map((item) => ({ carrier: item }));
		}
		return list;
	}, [ratesList]);

	const servicesOptions = useMemo(() => ratesList?.filter((item) => !!item?.service), [ratesList]);

	const proposalOptions = useMemo(
		() => ratesList?.filter((item) => item?.carrier === selectedRate?.carrier),
		[ratesList, selectedRate]
	);

	const hasProposalsToChoose = useMemo(
		() => !!proposalOptions && proposalOptions?.length > 1,
		[proposalOptions]
	);

	const columns = useMemo(
		() => [
			{
				field: 'created_at',
				flex: 1,
				headerName: 'Fecha de Alta',
				renderCell: (params: GridCellParams) => ''
			},
			{
				field: 'inactive_at',
				flex: 1,
				headerName: 'Fecha de Baja',
				renderCell: (params: GridCellParams) => ''
			},
			{
				field: 'carrier',
				flex: 1,
				headerName: 'Transportista',
				renderCell: (params: GridCellParams) => params.row.carrier
			},
			{
				field: 'proposal',
				flex: 1,
				headerName: 'Propuesta',
				renderCell: (params: GridCellParams) => ''
			},
			{
				field: 'range',
				flex: 1,
				headerName: 'Rangos',
				renderCell: (params: GridCellParams) => ''
			},
			{
				field: 'actions',
				headerName: 'Acciones',
				flex: 1,
				sortable: false,
				renderCell: (params: GridCellParams) => {
					return (
						<Tooltip title='Ver documento'>
							<FileText size={19} onClick={() => window.open(params.row.url, '_blank')} />
						</Tooltip>
					);
				}
			}
		],
		[]
	);

	const getRatesFiles = useCallback(async () => {
		const url = isRootOrAgent
			? `/api/customer/files/documents?customer_id=${params?.id}`
			: '/api/customer/files/documents';
		const { data } = await axiosInstante.get(url);
		setRatesList(data?.tariffs?.active);
		setInactivesRatesList(data?.tariffs?.inactive);
	}, [isRootOrAgent, params?.id]);

	useEffect(() => {
		if (!currentUser?.services?.length) return;
		setService(currentUser?.services[0]?.service);
		dispatch(
			setServicesCUCustomer(
				currentUser?.services?.map((item) => ({
					...item,
					name: item.service,
					carrier: capitalize(item.carrier)
				}))
			)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser]);

	useEffect(() => {
		getRatesFiles();
	}, [getRatesFiles]);

	return (
		<Grid container className={clsx(cardClasses.card, classes.ratesContainer)} direction='column'>
			<Grid item container alignItems='center' justifyContent='space-between'>
				<HeadingOne text='Tarifas' />
			</Grid>
			{/* ACTIVE */}
			<Grid container className={classes.spacing}>
				<Grid item container alignItems='center' justifyContent='space-between'>
					<Grid item>
						<HeadingTwo text='Activas' classes={classes.subsectionTitle} />
					</Grid>
					{isRootOrAgent && (
						<Grid item>
							<Button
								{...getRootProps()}
								disabled={!selectedRate?.carrier || isSubmitting}
								className={classes.uploadBtn}
							>
								<input {...getInputProps()} />
								{!isSubmitting ? (
									<>
										<Plus size='16' />
										<span style={{ marginLeft: 4 }}>Nuevo</span>
									</>
								) : (
									<CircularProgress size={18} color='primary' />
								)}
							</Button>
						</Grid>
					)}
				</Grid>

				<Grid item container spacing={2} alignItems='flex-end'>
					<Grid item xs={3}>
						<Autocomplete
							openOnFocus
							options={ratesOptions ?? []}
							getOptionLabel={(option) => option.carrier}
							getOptionSelected={(option, value) => option === value}
							onChange={(_, data) => {
								const carrierHasMoreThanOneRate =
									!!ratesList &&
									ratesList?.filter((item) => item?.carrier === data?.carrier)?.length > 1;
								setSelectedRate(
									carrierHasMoreThanOneRate
										? { carrier: data?.carrier }
										: ratesList?.find((item) => item?.carrier === data?.carrier)!
								);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Transportista'
									variant='filled'
									autoComplete='none'
									value={selectedRate?.carrier}
									size='small'
								/>
							)}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					</Grid>

					{isEstafetaRate(selectedRate?.carrier) && (
						<Grid item xs={3}>
							<Autocomplete
								size='small'
								selectOnFocus
								options={servicesOptions ?? []}
								getOptionLabel={(option) => option?.service ?? ''}
								getOptionSelected={(option, value) => option?.service === value?.service}
								value={selectedRate}
								renderInput={(params) => (
									<Grid container alignItems='center' justifyContent='center'>
										<Grid item xs={12}>
											<TextField {...params} label='Servicio' variant='filled' />
										</Grid>
									</Grid>
								)}
								clearOnBlur
								onChange={(_, value) => {
									const newValue = !hasProposalsToChoose
										? value
										: {
												carrier: value?.carrier,
												id: value?.id,
												service: value?.service
										  };
									setSelectedRate(newValue);
								}}
								PaperComponent={(paperProps) => (
									<DropdownPaper>{paperProps.children}</DropdownPaper>
								)}
							/>
						</Grid>
					)}

					<Grid item xs={3}>
						<Autocomplete
							openOnFocus
							options={proposalOptions ?? []}
							getOptionLabel={(option) => option?.name ?? ''}
							getOptionSelected={(option, value) => option?.name === value?.name}
							onChange={(_, data) => {
								setSelectedRate(data);
							}}
							value={selectedRate}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Propuesta'
									variant='filled'
									autoComplete='none'
									size='small'
								/>
							)}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					</Grid>
				</Grid>
				{!!selectedRate?.url && (
					<Accordion
						expanded={expandActivePDF}
						onChange={(e: ChangeEvent<{}>, expanded: boolean) => setExpandActivePDF(expanded)}
						style={{ boxShadow: 'none', width: '100%', backgroundColor: 'transparent' }}
					>
						<AccordionSummary expandIcon={<ExpandMoreIcon fontSize='large' />} id='panel1-header'>
							<Grid container justifyContent='flex-end'>
								{!selectedRate?.url ? null : expandActivePDF ? 'Ocultar PDF' : 'Mostrar PDF'}
							</Grid>
						</AccordionSummary>
						<Grid item container className={classes.spacing}>
							{selectedRate?.url && (
								<embed
									src={selectedRate?.url}
									type='application/pdf'
									width='100%'
									height='1100px'
									key={selectedRate?.url}
								/>
							)}
						</Grid>
					</Accordion>
				)}
			</Grid>

			{/* INACTIVE */}
			<Grid container className={classes.spacing}>
				<HeadingTwo text='Inactivas' classes={classes.subsectionTitle} />
				<Grid container className={classes.spacing}>
					<DataGrid
						localeText={MUIDataGridTranslations}
						disableSelectionOnClick
						autoHeight
						columns={columns}
						// loading={loading}
						rowCount={inactivesRatesList?.length}
						rows={inactivesRatesList ?? []}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ProfileRates;
