// Libraries
import { useHistory, useParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { Grid, IconButton } from '@mui/material';
import { ArrowLeft } from 'react-feather';
import clsx from 'clsx';
// Hooks
import useStyles from './styles';
import { useCardStyles } from '~hooks/useStyles';
// Components
import { HeadingOne } from '~components/Headings';

const DocumentsPage = () => {
	const classes = useStyles();
	const { cardClasses } = useCardStyles();
	const history = useHistory();

	const params: { id?: string } = useParams();

	const isLoading = false;

	if (isLoading) {
		return (
			<Grid container spacing={3}>
				<Grid item>
					<Skeleton
						variant='rounded'
						height={550}
						className={clsx(cardClasses.card, classes.container)}
					/>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid container className={clsx(cardClasses.card, classes.root)}>
			<Grid item xs={12} container alignItems='center'>
				<IconButton onClick={() => history.goBack()} color='primary'>
					<ArrowLeft size={32} />
				</IconButton>
				<HeadingOne text={'Documentos'} />
			</Grid>

			<Grid item xs={12} container alignItems='center' className={classes.container}>
				<div>ID: {params?.id}</div>
			</Grid>
		</Grid>
	);
};

export default DocumentsPage;
