// Libraries
import { Fragment, useState, useMemo, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { Grid } from '@mui/material';
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid';
import clsx from 'clsx';
// Hooks
import useStyles from './style';
import { useCardStyles } from '~hooks/useStyles';
import useProspects from '../ProspectsTable/useProspects';
// Components
import StatusCell from '~components/Tables/_StatusCell';
import DocumentDetailModal from './DocumentDetailModal';
import { HeadingOne, TextTwo } from '~components/Headings';
// Utils
import { getCellStatusColor } from '~util/cellStatusColor';
import { DocumentNameType, Entity } from '~store/actions/ActionTypes';
import UploadDocumentBtn, { DocumentFileType } from '~pages/documents/UploadDocumentBtn';
import GoToDetailIconButton from '~components/Buttons/GoToDetailIconButton';
import { CustomerDocumentType } from '~store/actions/ActionTypes';
import useCustomerForm from './useCustomerForm';
import { MUIDataGridTranslations } from '~util/index';

export const STATUS_REF: { [key: string]: string } = {
	approved: 'Aprobado',
	pending: 'Pendiente',
	rejected: 'Rechazado'
};

const STATUS_PAYLOAD: { [key: string]: string } = {
	Validado: 'approved',
	Pendiente: 'pending',
	Rechazado: 'rejected'
};

export type DocumentRow = {
	status: string;
	type?: string;
	url: string;
	comment?: string;
	name: string;
};

export const NAME_REF: { [key: string]: string } = {
	ine: DocumentNameType.INE,
	tax: DocumentNameType.TAX,
	proof: DocumentNameType.PROOF_ADDRESS,
	constitutive_act: DocumentNameType.CONSTITUTIVE_ACT,
	assembly_act: DocumentNameType.ASSEMBLY_ACT
};

const all = ['ine', 'tax', 'proof'];
const onlyMoral = ['constitutive_act', 'assembly_act'];

const CustomerDocuments = () => {
	const classes = useStyles();
	const params: { id?: string } = useParams();
	const { cardClasses } = useCardStyles();
	const history = useHistory();
	const [rows, setRows] = useState<DocumentRow[]>([]);
	const [data, setData] = useState<CustomerDocumentType | null>(null);
	const [openDetail, setOpenDetail] = useState(false);
	const [files, setFiles] = useState<DocumentFileType[]>([]);
	const [loadingSubmit, setLoadingSubmit] = useState(false);

	const { getProspect, prospect, loading: loadingProspect, updateDocument } = useProspects();
	const {
		loading: loadingCustomer,
		// editing,
		// posting,
		customer
	} = useCustomerForm();

	const isProfile = useMemo(() => history.location.pathname.includes('perfil'), [history]);
	const isCustomerView = useMemo(() => history.location.pathname.includes('cliente'), [history]);

	const onSubmit = useCallback(
		async (formData: any) => {
			if (!params?.id) return;
			setLoadingSubmit(true);

			const payload = {
				...formData,
				prospectID: params?.id,
				document_type: data?.document_type,
				status: STATUS_PAYLOAD[formData?.status]
			};
			await updateDocument(params?.id, payload, () => {
				setLoadingSubmit(false);
				setOpenDetail(false);
			});
		},
		[updateDocument, data, params?.id]
	);

	const goToDetail = (documentData: CustomerDocumentType) => {
		if (!documentData?.url) return;
		setData(documentData);
		setOpenDetail(true);
	};

	useEffect(() => {
		if (isCustomerView) return;
		if (params.id) {
			getProspect(params.id);
		}
	}, [params.id, getProspect, isCustomerView]);

	const updateRows = useCallback(() => {
		if (!prospect) return;
		const requiredDocs = prospect?.business_type === Entity.PHYSICAL ? all : [...all, ...onlyMoral];
		const result = Object.entries(prospect?.documents)
			.filter(([key, _]) => requiredDocs?.includes(key))
			.map(([key, value]) => {
				return {
					...value,
					name: key,
					id: key
				};
			});
		// @ts-ignore
		setRows(result);
	}, [prospect]);

	useEffect(() => {
		if (prospect?.documents) {
			updateRows();
		} else {
			setRows([]);
		}
	}, [prospect, updateRows]);

	const columns = useMemo(() => {
		const resultColumns = [
			{
				field: 'documents',
				headerName: 'Documento',
				flex: 1.5,
				renderCell: (params: GridCellParams) => (
					<span onClick={() => goToDetail(params.row)}>{NAME_REF[params.row.name]}</span>
				)
			},
			{
				field: 'status',
				headerName: 'Estatus',
				flex: 0.75,
				align: 'center',
				renderCell: (params: GridCellParams) =>
					STATUS_REF[params.row.status] && (
						<Grid item xs={8}>
							<StatusCell
								status={STATUS_REF[params.row.status]}
								backgroundColor={
									params.row.status === 'approved'
										? getCellStatusColor('validated')
										: getCellStatusColor(params.row.status)
								}
							/>
						</Grid>
					)
			},
			{
				field: 'created_date',
				headerName: 'Fecha de creación',
				flex: 0.75
			}
			// {
			// 	field: 'last_update',
			// 	headerName: 'última actualización',
			// 	flex: 0.75
			// }
		];
		if (!isProfile) {
			resultColumns.push({
				field: 'actions',
				headerName: 'Acciones',
				flex: 0.5,
				renderCell: (params: GridCellParams) => {
					return (
						<Grid item container justifyContent='center'>
							<div style={{ marginRight: '4px' }}>
								<UploadDocumentBtn
									rowData={params.row}
									setFiles={setFiles}
									files={files}
									updateRows={updateRows}
								/>
							</div>
							<div>
								<GoToDetailIconButton onClick={() => goToDetail(params.row)} />
							</div>
						</Grid>
					);
				}
			});
		}
		return resultColumns;
	}, [isProfile, files, updateRows]);

	if (loadingProspect || loadingCustomer) {
		return (
			<Fragment>
				<Skeleton
					variant='rectangular'
					height={550}
					className={clsx(cardClasses.card, classes.container)}
				/>
			</Fragment>
		);
	}

	return (
		<Grid container direction='column' className={clsx(cardClasses.card, classes.container)}>
			<Grid item container className={classes.nameContainer}>
				<Grid item xs={6} container>
					<Grid item xs={12} container alignItems='center' spacing={2}>
						<Grid item>
							<HeadingOne text={'Documentos'} />
						</Grid>
						{/* TODO: global status for documents ? */}
						{/* <Grid item>
							<Chip label='Por validar' color='default' />
						</Grid> */}
					</Grid>
					{!isProfile && (
						<Grid item xs={12}>
							<TextTwo
								text={
									prospect?.business_name?.toLocaleUpperCase() ??
									customer?.business_name?.toLocaleUpperCase() ??
									''
								}
							/>
						</Grid>
					)}
				</Grid>

				{/* {!isProfile && (
					<Grid item xs={6} container justifyContent='flex-end' alignItems='center'>
						<Tooltip title='Mandar por wapp' aria-label='Mandar por wapp'>
							<IconButton onClick={() => console.log('Mandar por wapp')}>
								<Phone size={20} />
							</IconButton>
						</Tooltip>
						<Tooltip title='Copiar link' aria-label='Copiar link'>
							<IconButton onClick={() => console.log('Copiar link')}>
								<Link size={20} />
							</IconButton>
						</Tooltip>
						<Tooltip title='Copiar link' aria-label='Copiar link'>
							<Button onClick={() => history.push('/subir-documentos')}>Ir al form </Button>
						</Tooltip>
					</Grid>
				)} */}
			</Grid>

			<Grid item xs={12}>
				<DataGrid
					rowHeight={64}
					localeText={MUIDataGridTranslations}
					rows={rows}
					columns={columns as GridColumns<DocumentRow>}
					autoHeight
					disableSelectionOnClick
					loading={loadingProspect || loadingCustomer || loadingSubmit}
					hideFooter
				/>
			</Grid>

			<DocumentDetailModal
				isOpen={openDetail}
				handleOpen={setOpenDetail}
				data={data}
				isProfile={isProfile}
				onSubmit={onSubmit}
				isLoading={loadingProspect || loadingCustomer || loadingSubmit}
			/>
		</Grid>
	);
};

export default CustomerDocuments;
