import { forwardRef, ReactElement, Ref, useState, useMemo } from 'react';
import { Edit, Trash2 } from 'react-feather';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Grid, IconButton, Tooltip } from '@mui/material';
// Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const Transition = forwardRef(function Transition(
	props: TransitionProps & { children?: ReactElement<any, any> },
	ref: Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

type Props = {
	// Optional to not break the current uses of this component
	showAlert?: boolean;
	onDelete?: () => any;
	onDeleteText?: string;
	deleteModalMessage?: string;
	onEdit?: () => any;
	onEditText?: string;
	title?: string;
	body?: string;
};

const MenuActionButton = ({
	showAlert = false,
	onDelete,
	onEdit,
	onDeleteText,
	deleteModalMessage,
	onEditText,
	title,
	body
}: Props) => {
	const [isOpen, setOpen] = useState(false);
	const onOpen = () => setOpen(true);
	const onClose = () => setOpen(false);
	const deleteText = useMemo(() => onDeleteText || 'Eliminar', [onDeleteText]);
	const editText = useMemo(() => onEditText || 'Editar', [onEditText]);

	const del = () => {
		if (onDelete) {
			onDelete();
		}
		onClose();
	};

	const handleDelete = () => {
		if (showAlert) {
			onOpen();
		} else {
			del();
		}
	};

	const handleEdit = () => {
		if (onEdit) {
			onEdit();
		}
	};

	return (
		<>
			<Dialog
				open={isOpen}
				TransitionComponent={Transition}
				keepMounted
				onClose={onClose}
				aria-labelledby='alert-dialog-slide-title'
				aria-describedby='alert-dialog-slide-description'
			>
				<DialogTitle id='alert-dialog-slide-title'>
					{title ?? '¿Estás seguro de cancelar la recolección?'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-slide-description'>
						{body ??
							'La siguiente acción cancelará la recolección de manera definitiva y el transportista no pasara por el o los paquetes indicados. ¿Deseas proceder con esta acción?'}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} variant='outlined'>
						Cancelar
					</Button>
					<Button onClick={del} color='primary' variant='contained'>
						{deleteModalMessage || deleteText}
					</Button>
				</DialogActions>
			</Dialog>

			<Grid container justifyContent='center'>
				{onEdit && (
					<Tooltip title={editText ? editText : 'Editar'}>
						<IconButton onClick={handleEdit} aria-label='edit'>
							<Edit size={20} />
						</IconButton>
					</Tooltip>
				)}
				{onDelete && (
					<Tooltip title={deleteText ? deleteText : 'Eliminar'}>
						<IconButton onClick={handleDelete} aria-label='delete'>
							<Trash2 size={20} />
						</IconButton>
					</Tooltip>
				)}
			</Grid>
		</>
	);
};

export default MenuActionButton;
