import React, { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Plus as PlusIcon, Edit as EditIcon } from 'react-feather';

import RenderInput from './_RenderInput';

import { useAddBtnAutocompleteStyles } from '../../hooks/useStyles';
import useInputSearchAddress from '~hooks/useInputSearchAddress';
import DropdownPaper from '~components/Forms/DropdownPaper';

import {
	setAddressPickUp,
	setAddressRate,
	setAddressSend,
	setAddressQuery,
	setAddressCreate
} from '~store/actions';
import { AddressRequest, NewAddress } from '~store/actions/ActionTypes';
import { useSelector } from '~store/store';
import usePermissions from '~hooks/usePermissions';

//FIXME This can only be fixed when the issue get resolved
// https://github.com/mui-org/material-ui/issues/20991
// UPDATE: Now is posiblle
// TODO change
let showAddBtn = true;

interface PlsBtnAutoComplete {
	callBack?: () => void;
	target: 'origin' | 'destination';
	isCreating?: boolean;
	children: React.ReactNode;
	saveCurrentFields?: () => void;
}

// https://stackoverflow.com/questions/59291614/how-can-i-create-a-clickable-first-option-in-material-ui-labs-autocomplete
const PlusButtonAutoComplete: React.FC<PlsBtnAutoComplete> = (props) => {
	const history = useHistory();
	const { btnAutocompleteClasses } = useAddBtnAutocompleteStyles();
	const { id } = useParams<{ id?: string }>();

	return (
		<DropdownPaper>
			{showAddBtn && (
				<Button
					fullWidth
					onMouseDown={(e) => {
						e.preventDefault();
					}}
					className={btnAutocompleteClasses.btnAdd}
					onClick={() => {
						if (props.callBack) {
							props.callBack();
						}
						props.saveCurrentFields && props.saveCurrentFields();
						if (props.isCreating) {
							let url = `/direccion-simple?target=${props.target}`;
							history.push(id ? url.concat(`&labelId=${id}`) : url);
						} else {
							history.push(`/direccion?target=${props.target}`);
						}
					}}
				>
					<IconButton
						component={Typography}
						onMouseDown={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
							e.preventDefault();
						}}
						disableRipple
						className={btnAutocompleteClasses.iconAdd}
					>
						<PlusIcon />
					</IconButton>
					&nbsp; Agregar nueva dirección
				</Button>
			)}
			{props.children}
		</DropdownPaper>
	);
};

// to handle add address
const idForNewAddresses = 'newAddresses';

// Filter search for Address, to find by anything
const filterOptionsAddress = createFilterOptions<NewAddress>({
	trim: true,
	stringify: (option) => JSON.stringify(option)
});

interface Props {
	for: 'addressOrigin' | 'addressDestination';
	label: string; //pass to the render input
	isRating?: boolean; // when rating, to show or no the add btn
	isPickingUp?: boolean; // when pickup, to show or no the add btn
	isCreating?: boolean; //when creating guide
	isCreatingCustomer?: boolean; // when creating and customer, to dispatch to the correct redux
	onAddButton?: () => void; // callback when click on add new address
	reset?: boolean;
	isPickingUpFromDelivery?: boolean;
	disabled?: boolean;
	saveCurrentFields?: () => void;
	fromAddressForm?: boolean;
}

/**
 * Autocomplete for search address
 */
const InputSearchAddress: React.FC<Props> = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { id } = useParams<{ id?: string }>();

	const [addr, setAddr] = useState<null | NewAddress>(null);
	const [simpleAddr, setSimpleAddr] = useState<null | SimpleAddress>(null);
	const [showError, setShowError] = useState(false);
	const firstTimeMounted = useRef(true);

	const { btnAutocompleteClasses } = useAddBtnAutocompleteStyles();
	const { isRootOrAgent } = usePermissions();
	const [query, setQuery] = useState('');
	const [queryDebounce] = useDebounce(query, 300);
	const { simulatedUser } = useSelector((state) => state.simulatedUser);
	const {
		data: addresses,
		updateParam,
		params: pms,
		updateParams,
		simpleData: simpleAddresses
	} = useInputSearchAddress({ isRootOrAgent });

	const { currentFlow } = useSelector((state) => state.flow);
	const { addressPickUp, isTouchedPickup, labelsPickup } = useSelector((state) => state.pickup);
	const {
		originAddressSend,
		destinationAddressSend,
		isTouchedSend,
		originQuery,
		destinationQuery
	} = useSelector((state) => state.send);
	const { originAddressRate, destinationAddressRate, isTouchedRate } = useSelector(
		(state) => state.rate
	);
	const { originAddressCreate, destinationAddressCreate, isTouchedCreate } = useSelector(
		(state) => state.create
	);

	//Se resetean los campos
	useEffect(() => {
		if (props.reset) {
			// resetSend()
			const target = props.for === 'addressDestination' ? 'destination' : 'origin';
			dispatch(setAddressQuery(null, target));
			dispatch(setAddressSend(null, 'origin', []));
			dispatch(setAddressSend(null, 'destination', []));
			dispatch(setAddressCreate(null, 'destination', []));
			dispatch(setAddressCreate(null, 'origin', []));
		}
	}, [props.reset, dispatch, props.for]);

	useEffect(() => {
		if (
			currentFlow !== 'send' &&
			currentFlow !== 'pickup' &&
			currentFlow !== 'rate' &&
			currentFlow !== 'create' &&
			!firstTimeMounted.current
		)
			return;

		const target = props.for === 'addressDestination' ? 'destination' : 'origin';
		if (queryDebounce.length === 0) {
			updateParam('query', undefined);
			if (currentFlow === 'send') dispatch(setAddressQuery(null, target));
		} else {
			updateParam('query', queryDebounce);
			if (currentFlow === 'send') dispatch(setAddressQuery(queryDebounce, target));
		}
	}, [queryDebounce, updateParam, currentFlow, dispatch, props.for]);

	useEffect(() => {
		if (simulatedUser && simulatedUser.id !== pms.user_id) {
			updateParams((prevState) => ({
				...prevState,
				rows: [],
				params: {
					...prevState.params,
					page: 0,
					user_id: simulatedUser.id,
					customer_id: undefined,
					query: undefined
				}
			}));
		}

		if (!simulatedUser && pms.user_id !== undefined) {
			updateParams((prevState) => ({
				...prevState,
				rows: [],
				params: {
					...prevState.params,
					page: 0,
					user_id: undefined,
					query: undefined
				}
			}));
		}
	}, [simulatedUser, pms.user_id, updateParams]);

	// Show add button
	if (props.isRating || props.isPickingUp || props.isCreating) {
		showAddBtn = false;
	}

	// Clear error
	useEffect(() => {
		if (
			(currentFlow === 'rate' && !isTouchedRate) ||
			(currentFlow === 'send' && !isTouchedSend) ||
			(currentFlow === 'pickup' && !isTouchedPickup)
		) {
			setShowError(false);
		}
	}, [currentFlow, isTouchedRate, isTouchedSend, isTouchedPickup]);

	useEffect(() => {
		if (
			currentFlow === 'create' &&
			isTouchedCreate &&
			(!originAddressCreate || !destinationAddressCreate)
		) {
			setShowError(true);
		}
	}, [currentFlow, isTouchedCreate, destinationAddressCreate, originAddressCreate]);

	useEffect(() => {
		if (currentFlow === 'create') {
			const simpleAddr = simpleAddresses?.find((a) => a.id === addr?.id);
			setSimpleAddr(simpleAddr || null);
		}
	}, [addr?.id, currentFlow, simpleAddresses]);

	/**
	 * Set Default Value
	 * This turns complicated because setaddr will dipatch to the value
	 * and get looped
	 */
	useEffect(() => {
		if (!firstTimeMounted.current) return;
		if (currentFlow === 'pickup' && addressPickUp) {
			// PICKUP
			setAddr(addresses.find((a) => a.id === addressPickUp.id) as NewAddress);
		} else if (currentFlow === 'send') {
			if (props.for === 'addressDestination' && destinationAddressSend) {
				setAddr(addresses.find((a) => a.id === destinationAddressSend.id) as NewAddress);
				if (props.reset) {
					setAddr(null);
				}
			} else if (props.for === 'addressOrigin' && originAddressSend) {
				setAddr(addresses.find((a) => a.id === originAddressSend.id) as NewAddress);
				if (props.reset) {
					setAddr(null);
				}
			}
		} else if (currentFlow === 'rate') {
			// RATE
			let add = null;
			if (props.for === 'addressDestination' && destinationAddressRate) {
				add = addresses.find((a) => a.id === destinationAddressRate.id);
			} else if (props.for === 'addressOrigin' && originAddressRate) {
				add = addresses.find((a) => a.id === originAddressRate.id);
			}
			setAddr(add ? add : null);
		} else if (currentFlow === 'create') {
			// MANUAL
			let add = null;
			if (props.for === 'addressDestination' && destinationAddressCreate) {
				add = (addresses.find((a) => a.address === destinationAddressCreate.address) ??
					destinationAddressCreate) as NewAddress;
			} else if (props.for === 'addressOrigin' && originAddressCreate) {
				add = (addresses.find((a) => a.address === originAddressCreate.address) ??
					originAddressCreate) as NewAddress;
			}
			setAddr(add ? add : null);
		}

		if (currentFlow === 'send') {
			// SEND
			const target = props.for === 'addressDestination' ? 'destination' : 'origin';
			if (target === 'origin' && originQuery) {
				updateParam('query', originQuery);
				setQuery(originQuery!);
				if (props.reset) {
					setQuery('');
				}
			}

			if (target === 'destination' && destinationQuery) {
				updateParam('query', destinationQuery);
				setQuery(destinationQuery!);
				if (props.reset) {
					setQuery('');
				}
			}
		}
		firstTimeMounted.current = false;
	}, [
		currentFlow,
		addressPickUp,
		addresses,
		props.for,
		originAddressSend,
		destinationAddressSend,
		originAddressRate,
		destinationAddressRate,
		originQuery,
		destinationQuery,
		setQuery,
		updateParam,
		props.reset,
		originAddressCreate,
		destinationAddressCreate
	]);

	// handle changes for the swap btn when sending or when it resets
	useEffect(() => {
		if (firstTimeMounted.current) return;
		if (currentFlow === 'send') {
			// SEND
			if (props.for === 'addressDestination') {
				if (destinationAddressSend) {
					setAddr(addresses.find((a) => a.id === destinationAddressSend.id) as NewAddress);
				} else {
					setAddr(null);
				}
				//parra resetear al cambiar de user
				if (props.reset) {
					setAddr(null);
				}
			} else if (props.for === 'addressOrigin') {
				if (originAddressSend && !props.isPickingUpFromDelivery) {
					setAddr(addresses.find((a) => a.id === originAddressSend.id) as NewAddress);
				} else if (!originAddressSend) {
					setAddr(null);
				}
				//parra resetear al cambiar de user
				if (props.reset) {
					setAddr(null);
				}
			}
		} else if (currentFlow === 'rate') {
			// RATE
			if (props.for === 'addressDestination') {
				if (destinationAddressRate) {
					setAddr(addresses.find((a) => a.id === destinationAddressRate.id) as NewAddress);
				} else {
					setAddr(null);
				}
			} else if (props.for === 'addressOrigin') {
				if (originAddressRate) {
					setAddr(addresses.find((a) => a.id === originAddressRate.id) as NewAddress);
				} else {
					setAddr(null);
				}
			}
		} else if (currentFlow === 'pickup') {
			// PICKUP
			if (addressPickUp) {
				const result = addresses.find((a) => a.id === addressPickUp.id) as NewAddress;
				result && setAddr(result);
			} else {
				setAddr(null);
			}
		} else if (currentFlow === 'create') {
			// MANUAL
			if (!addresses.length) return;
			if (props.for === 'addressDestination') {
				if (destinationAddressCreate) {
					const defaultValue = (addresses.find((a) =>
						destinationAddressCreate?.id
							? destinationAddressCreate.id === a.id
							: destinationAddressCreate.address === a.address ||
							  destinationAddressCreate.business_name === a.alias
					) ?? destinationAddressCreate) as NewAddress;
					dispatch(
						setAddressCreate(destinationAddressCreate, 'destination', defaultValue?.contacts ?? [])
					);
					setAddr(defaultValue);
				} else {
					setAddr(null);
				}
			} else if (props.for === 'addressOrigin') {
				if (originAddressCreate) {
					const defaultValue = (addresses.find((a) =>
						originAddressCreate?.id
							? originAddressCreate.id === a.id
							: originAddressCreate.address === a.address ||
							  originAddressCreate.business_name === a.alias
					) ?? originAddressCreate) as NewAddress;
					dispatch(setAddressCreate(originAddressCreate, 'origin', defaultValue?.contacts ?? []));
					setAddr(defaultValue);
				} else {
					setAddr(null);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		currentFlow,
		props.for,
		destinationAddressSend,
		originAddressSend,
		addresses,
		destinationAddressRate,
		originAddressRate,
		addressPickUp,
		props.reset,
		props.isPickingUpFromDelivery,
		originAddressCreate,
		destinationAddressCreate
	]);

	// Load default value for picking up from summary sending
	useEffect(() => {
		if (currentFlow === 'send' && originAddressSend && props.isPickingUpFromDelivery) {
			const address = addresses.find((a) => a.id === originAddressSend.id);
			setAddr(address as NewAddress);
			address &&
				dispatch(
					setAddressPickUp(
						{ ...address, contact: originAddressSend?.contact } as AddressRequest,
						originAddressSend?.contacts || []
					)
				);
		}
	}, [currentFlow, originAddressSend, props.isPickingUpFromDelivery, addresses, dispatch]);

	return (
		<Autocomplete
			size='small'
			selectOnFocus //to helps the user clear the selected value
			clearOnBlur //to helps the user to enter a new value
			// autoHighlight // to high always the first option
			openOnFocus
			PaperComponent={(paperProps) => (
				<PlusButtonAutoComplete
					target={props.for === 'addressDestination' ? 'destination' : 'origin'}
					callBack={props.onAddButton}
					isCreating={props.isCreating}
					saveCurrentFields={props.saveCurrentFields}
				>
					{paperProps.children}
				</PlusButtonAutoComplete>
			)}
			onInputChange={(event) => {
				if (event && event.type === 'change') {
					// @ts-ignore
					setQuery(event.nativeEvent.target?.value);
				}
			}}
			filterOptions={(options, params) => {
				const filtered = filterOptionsAddress(options, params);
				if (props.isRating || props.isPickingUp) {
					return filtered;
				}
				if (params.inputValue !== '' && filtered.length === 0) {
					showAddBtn = false; //hide the btn and suggest a new option
					filtered.push({
						alias: 'Agregar Dirección',
						zip_code: params.inputValue,
						address: '',
						business_name: '',
						city: '',
						contacts: [],
						id: idForNewAddresses,
						neighborhood: '',
						scope: 'Personal',
						state: '',
						coordinates: '',
						country: '',
						number: '',
						street: '',
						reference: '',
						reference_streets: '',
						tax_id: ''
					});
				} else {
					showAddBtn = true;
				}

				return filtered;
			}}
			// when defaults, custom equals | value.id === idForNewAddresses for new address
			getOptionSelected={(option, value) => {
				return option?.id === value?.id || value?.id === idForNewAddresses;
			}}
			// filter address options for pickup in summary sending
			options={
				props.isPickingUpFromDelivery
					? addresses.filter((item) => item.state === originAddressSend?.state)
					: props.isPickingUp
					? props.isPickingUp && labelsPickup.length > 0
						? addresses.filter((item) => item.state === labelsPickup[0].origin.state)
						: addresses
					: addresses
			}
			groupBy={(option) => option.scope}
			// The options that are shown
			getOptionLabel={(option) =>
				`${option.alias?.toUpperCase() || option.address || option.business_name || ''} - ${
					option.zip_code
				}`
			}
			disabled={props.disabled}
			renderInput={(params) => {
				return (
					<Grid container style={{ backgroundColor: 'inherit' }}>
						<Grid item xs={!props.isPickingUpFromDelivery ? 11 : 12}>
							<RenderInput
								error={showError}
								errorMessage={
									currentFlow === 'cucustomer' || props.isPickingUpFromDelivery
										? 'Ingrese la dirección'
										: props.for === 'addressDestination'
										? 'Ingrese un destino'
										: 'Ingrese un origen'
								}
								params={params}
								hideIcon={Boolean(props.isCreatingCustomer) || props.isPickingUpFromDelivery}
								disabled={props.disabled}
								{...props}
							/>
						</Grid>
						{!props.isPickingUpFromDelivery && (
							<Grid item xs={1}>
								<Grid
									style={{ height: '100%' }}
									container
									justifyContent={'center'}
									alignItems={'center'}
								>
									<Tooltip title='Editar' placement='top'>
										<span>
											<button
												className={btnAutocompleteClasses.withoutStyles}
												disabled={!Boolean(addr?.id)}
												onClick={(event) => {
													event.preventDefault();
													props.saveCurrentFields && props.saveCurrentFields();
													const target =
														props.for === 'addressDestination' ? 'destination' : 'origin';
													let url =
														currentFlow === 'create' &&
														simpleAddr?.address_type === 'address_label_manual'
															? `/direccion-simple/${addr!.id}?target=${target}`
															: `/direccion/${addr!.id}?target=${target}`;
													if (id) {
														url = url.concat(`&labelId=${id}`);
													}
													if (pms.query) {
														url = url.concat(`&query=${pms.query}`);
													}
													history.push(url);
												}}
											>
												<EditIcon />
											</button>
										</span>
									</Tooltip>
								</Grid>
							</Grid>
						)}
					</Grid>
				);
			}}
			noOptionsText='No se encontraron direcciones'
			value={addr}
			onChange={(_, value) => {
				if (typeof value !== 'string') {
					if (value?.id === idForNewAddresses) {
						// To automatically add the new address for origin or destination
						//dispatch(addAddressInFlow(props.for === 'addressDestination' ? 'destination' : 'origin'));
						props.saveCurrentFields && props.saveCurrentFields();
						const target = props.for === 'addressDestination' ? 'destination' : 'origin';
						let url = props.isCreating
							? `/direccion-simple?target=${target}`
							: `/direccion?target=${target}`;
						if (id) {
							url = url.concat(`&labelId=${id}`);
						}
						history.push(url);
					} else {
						setAddr(value);
						let addr: AddressRequest | null = null;
						if (value) {
							addr = {
								id: value.id,
								coordinates: value.coordinates,
								alias: value.alias,
								street: value.street,
								city: value.city,
								business_name: value.business_name,
								neighborhood: value.neighborhood,
								state: value.state,
								zip_code: value.zip_code,
								country: value.country,
								number: value.number as string,
								reference: value.reference as string,
								reference_streets: value.reference_streets as string,
								suite_number: value.suite_number as string,
								contact: null,
								tax_id: value.tax_id
							};
						}

						if (props.isPickingUp) {
							dispatch(setAddressPickUp(addr, value?.contacts || []));
						} else if (currentFlow === 'cucustomer') {
							// TODO fix the type
							// dispatch(setAddressInfoDraftCUCustomer(addr as any));
						} else {
							if (props.for === 'addressDestination') {
								if (props.isRating) {
									dispatch(setAddressRate(addr, 'destination'));
								} else if (props.isCreating) {
									dispatch(setAddressCreate(addr, 'destination', value?.contacts || []));
								} else {
									dispatch(setAddressSend(addr, 'destination', value?.contacts || []));
								}
							} else if (props.for === 'addressOrigin') {
								if (props.isRating) {
									dispatch(setAddressRate(addr, 'origin'));
								} else if (props.isCreating) {
									dispatch(setAddressCreate(addr, 'origin', value?.contacts || []));
								} else {
									dispatch(setAddressSend(addr, 'origin', value?.contacts || []));
								}
							}
						}
					}
				}
			}}
		/>
	);
};

export default InputSearchAddress;
