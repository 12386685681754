import { FC, Fragment, useMemo } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { PermissionsKey } from '~context/AuthContext';
//Hooks
import usePermissions from '~hooks/usePermissions';
//Components
import CreateOrUpdateCustomer from './CreateOrUpdateCustomer';
import CustomerTabs from '~components/Tabs/CustomerFormTabs';
import CreateOrUpdateProspect from '../CreateOrUpdateProspect';
import CustomerDocuments from './CustomerDocuments';
import ProfileRates from '~pages/profile/ProfileRates';
import ProfileContract from '~pages/profile/ProfileContract';

type BookletOption = {
	path: string;
	name: string;
	component: FC;
	permission: PermissionsKey;
};

const CustomerFormPage = () => {
	const location = useLocation();
	const history = useHistory();
	const params: { id?: string } = useParams();

	const { can } = usePermissions();

	const isCustomerForm = useMemo(() => location.pathname.includes('cliente'), [location]);

	const customerBooklets: BookletOption[] = useMemo(
		() => [
			{
				path: '/cliente/datos',
				name: 'Datos',
				component: CreateOrUpdateCustomer,
				permission: 'canCreateUpdateDelete.client'
			},
			{
				path: '/cliente/tarifas',
				name: 'Tarifas',
				component: ProfileRates,
				permission: 'canCreateUpdateDelete.client'
			},
			{
				path: '/cliente/documentos',
				name: 'Documentos',
				component: CustomerDocuments,
				permission: 'canCreateUpdateDelete.client'
			},
			{
				path: '/cliente/contrato',
				name: 'Contrato',
				component: ProfileContract,
				permission: 'canCreateUpdateDelete.client'
			}
		],
		[]
	);

	const prospectBooklets: BookletOption[] = useMemo(
		() => [
			{
				path: '/prospecto/datos',
				name: 'Datos',
				component: CreateOrUpdateProspect,
				permission: 'canCreateUpdateDelete.client'
			},
			{
				path: '/prospecto/documentos',
				name: 'Documentos',
				component: CustomerDocuments,
				permission: 'canCreateUpdateDelete.client'
			}
		],
		[]
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const enabledTabs = useMemo(
		() =>
			(isCustomerForm ? customerBooklets : prospectBooklets).filter((booklet) =>
				can(booklet.permission)
			),
		[can, isCustomerForm, customerBooklets, prospectBooklets]
	);
	const tabs = useMemo(
		() => enabledTabs.map((booklet) => ({ name: booklet.name, path: booklet.path })),
		[enabledTabs]
	);

	const index = useMemo(() => {
		return enabledTabs.findIndex((b) => location.pathname?.includes(b.path));
	}, [location, enabledTabs]);

	const Component = enabledTabs[index]?.component;

	return (
		<Fragment>
			<CustomerTabs
				tabs={tabs}
				currentTab={index}
				handleChange={(tabIndex) => {
					const booklet = enabledTabs[tabIndex];
					const url = !params?.id ? booklet.path : booklet.path.concat(`/${params?.id}`);
					history.replace(url);
				}}
			/>
			<Component />
		</Fragment>
	);
};

export default CustomerFormPage;
