import { useCallback, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axiosInstance from '~util/axios';
import axios from 'axios';
import DrawContext from '~context/DrawContext';
import { useDispatch } from 'react-redux';
import { setServicesCUCustomer } from '~store/actions/agents';
import { getCarrierName } from '~util/logos';
import { ProspectResponseType, ProspectRequestType } from '~store/actions/ActionTypes';

const useProspects = () => {
	const [prospects, setProspects] = useState<ProspectResponseType[] | null>(null);
	const [prospect, setProspect] = useState<ProspectResponseType | null>(null);
	const [loading, setLoading] = useState(false);
	const { setNotification } = useContext(DrawContext);
	const history = useHistory();
	const dispatch = useDispatch();

	const getProspects = useCallback(async () => {
		setLoading(true);
		try {
			const { data } = await axiosInstance.get('/api/prospects');
			setProspects(data.data);
		} catch (err) {
			if (axios.isAxiosError(err)) {
				setNotification({
					message: 'Hubo un error al obtener los prospectos'
				});
			}
		} finally {
			setLoading(false);
		}
	}, [setNotification]);

	const getProspect = useCallback(
		async (prospectId?: string) => {
			setLoading(true);
			try {
				const { data } = await axiosInstance.get(`/api/prospect/${prospectId}`);
				setProspect({
					...data,
					phone_number:
						data?.phone_number?.length === 10 ? `+52${data?.phone_number}` : data?.phone_number
				});
				data.services &&
					dispatch(
						setServicesCUCustomer(
							data.services.map((s: any) => ({ ...s, carrier: getCarrierName(s.carrier) }))
						)
					);
			} catch (err) {
				if (axios.isAxiosError(err)) {
					setNotification({
						message: 'Hubo un error al obtener los datos del prospecto'
					});
				}
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setNotification]
	);

	const saveProspect = useCallback(
		async (payload: ProspectRequestType, prospectId?: string) => {
			setLoading(true);
			try {
				!prospectId
					? await axiosInstance.post('/api/prospect', payload)
					: await axiosInstance.put(`/api/prospect/${prospectId}`, payload);
				setNotification({
					message: `El prospecto fue ${prospectId ? 'actualizado' : 'creado'} correctamente`,
					severity: 'success'
				});
				history.push('/prospectos');
			} catch (err) {
				if (axios.isAxiosError(err)) {
					setNotification({
						message: `Hubo un error al ${prospectId ? 'actualizar' : 'crear'} el prospecto`
					});
				}
			} finally {
				setLoading(false);
			}
		},
		[setNotification, history]
	);

	const updateDocument = useCallback(
		async (prospectId: string, payload: any, cb: () => void) => {
			try {
				await axiosInstance.put(`/api/prospect/document/${prospectId}`, payload);
				setNotification({
					message: 'La información se actualizó con éxito',
					severity: 'success'
				});
				cb();
				await getProspect(prospectId);
			} catch (error) {
				setNotification({
					message: 'Hubo un error al actualizar la información',
					severity: 'error'
				});
			}
		},
		[setNotification, getProspect]
	);

	useEffect(() => {
		getProspects();
	}, [getProspects]);

	return { prospects, loading, getProspect, prospect, saveProspect, updateDocument };
};

export default useProspects;
