import { useState, useCallback } from 'react';
import { monthDateOptions, weeklyDateOptions, biweeklyDateOptions } from '../options';
import { ReportItem, ReportSubType, SchemaType } from '~store/actions/ActionTypes';

const useOptions = ({ setValue }: { setValue?: (key: string, value: string) => void }) => {
	const [dateOptions, setDateOptions] = useState<string[]>([]);
	const [subtype, setSubtype] = useState<string>('');
	const [date, setDate] = useState<string>('');
	const [reportNunberOptions, setReportNunberOptions] = useState<{ num: string }[]>([]);

	/** Determine cutoff date options to show depending on the type of cut chosen */
	const handleDateOptions = useCallback(
		(cutoffDate: string, dateValue?: string) => {
			if (cutoffDate === SchemaType.MONTHLY) {
				setDateOptions(monthDateOptions);
			} else if (cutoffDate === SchemaType.WEEKLY) {
				setDateOptions(weeklyDateOptions);
			} else {
				setDateOptions(biweeklyDateOptions);
			}
			setValue && dateValue && setValue('guides_date', dateValue);
		},
		[setValue]
	);

	/** Set the subtype depending on the type of schema chosen */
	const handleSubtypes = useCallback(
		(schema: string, type: 'guides' | 'insurance' | 'overweight', defautlValues?: ReportItem) => {
			if (schema === SchemaType.WEEKLY) {
				setValue && setValue(`${type}_subtype`, ReportSubType.WEEK);
			} else if (schema === SchemaType.BIWEEKLY) {
				setValue && setValue(`${type}_subtype`, ReportSubType.BIWEEK);
			} else if (schema === SchemaType.IMMEDIATE || schema === SchemaType.INDEPENDENT) {
				setValue && setValue(`${type}_subtype`, '');
			} else {
				setValue && setValue(`${type}_subtype`, defautlValues?.sub_type || '');
			}
		},
		[setValue]
	);

	/** Report numbers that can be chosen to define the type of report */
	const handleReportNumber = useCallback((reportNumber: string) => {
		if (reportNumber! === '1') {
			setReportNunberOptions([{ num: '1' }, { num: '2' }]);
		} else {
			setReportNunberOptions([{ num: '1' }, { num: '2' }, { num: '3' }]);
		}
	}, []);

	return {
		dateOptions,
		handleDateOptions,
		handleSubtypes,
		subtype,
		setSubtype,
		date,
		setDate,
		handleReportNumber,
		reportNunberOptions
	};
};

export default useOptions;
