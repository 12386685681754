import {
	useEffect,
	useContext,
	useState,
	useCallback,
	Dispatch,
	SetStateAction,
	useMemo
} from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { instanceRate } from '~util/axios';
import { shallow } from 'zustand/shallow';
import DrawContext from '~context/DrawContext';
import axios from 'axios';
//Store
import useRateStore from '~store/useRateStore';
import { removeStress } from '~util/removeStress';
import { usePurchaseStore } from '~store/newstore/usePurchaseStore';

export enum ServicesRateName {
	NEXT_DAY = 'Dia Siguiente',
	LOCAL_MESSAGING = 'Mensajeria Local',
	LTL = 'LTL'
}

export enum ServicesName {
	NEXT_DAY = 'Dia Sig.',
	LOCAL_MESSAGING = 'Mensajería Local',
	LTL = 'LTL / Tarima'
}

export enum ServicesModalityName {
	NO_CREDIT = 'Sin Crédito',
	PREPAID = 'Prepago',
	CONSUMPTION = 'Consumo'
}

function getModality(service: string) {
	if (service.includes(ServicesModalityName.NO_CREDIT)) return ServicesModalityName.NO_CREDIT;
	if (service.includes(ServicesModalityName.PREPAID)) return ServicesModalityName.PREPAID;
	if (service.includes(ServicesModalityName.CONSUMPTION)) return ServicesModalityName.CONSUMPTION;

	return '';
}
export function removeModalityIfPresent(service: string) {
	const modality = getModality(service);
	if (modality) {
		return service.replace(modality, '').trim();
	}
	return service;
}

export function formatServiceToRateName(name: string) {
	if (!name) return '';
	return removeStress(name).includes(ServicesRateName.NEXT_DAY)
		? removeStress(name).replace(ServicesRateName.NEXT_DAY, ServicesName.NEXT_DAY)
		: name.includes('LTL')
			? 'LTL'
			: name.includes('K')
				? removeStress(name)
					.split(' ')
					.filter(
						(item) =>
							!item.includes('K') &&
							(isNaN(parseInt(item)) || item.includes('11:30') || item.includes('12:30'))
					)
					.join(' ')
					.trim()
				: removeStress(name);
}

const useGetColumns = ({
	selectedProposal,
	setSelectedProposal
}: {
	setSelectedProposal?: Dispatch<SetStateAction<Proposal | null | undefined>>;
	selectedProposal?: Proposal | null;
}) => {
	const { setNotification, setRCardStatus } = useContext(DrawContext);
	const [columns, setColumns] = useState<GridColDef[]>([]);
	const [loading, setLoading] = useState(false);
	const [selectedYear, setSelectedYear] = useState<{ value?: string; fromUser: boolean }>({
		fromUser: false
	});
	const [
		setServices,
		services,
		rateService,
		setProposals,
		setZones,
		setRates,
		setRanges,
		setZonesCost,
		setClientRates,
		clientRates,
		setUrl,
		service,
		setRateService,
		years,
		setYears
	] = useRateStore(
		(state) => [
			state.setServices,
			state.services,
			state.rateService,
			state.setProposals,
			state.setZones,
			state.setRates,
			state.setRanges,
			state.setZonesCost,
			state.setClientRates,
			state.clientRates,
			state.setUrl,
			state.service,
			state.setRateService,
			state.years,
			state.setYears
		],
		shallow
	);

	const formattedServices = useMemo(
		() =>
			services?.map((item) => ({
				...item,
				estafetaServiceName: formatServiceToRateName(item.estafetaServiceName ?? '')
			})) ?? [],
		[services]
	);

	const { setPrices } = usePurchaseStore((state) => ({ setPrices: state.setPrices }));

	useEffect(() => {
		setRCardStatus('HIDDEN');
	}, [setRCardStatus]);

	//Get all services
	const getAllServices = useCallback(
		async (year: string) => {
			setLoading(true);
			try {
				const rates = await instanceRate.get(`/rates?year=${year}`);
				setServices(rates.data);
			} catch (error) {
				setNotification({
					message: 'Algo salió mal, por favor contacta al equipo de Encamino',
					severity: 'error'
				});
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setServices]
	);

	useEffect(() => {
		if (!selectedYear?.value) return;
		getAllServices(selectedYear.value);
	}, [selectedYear?.value, getAllServices]);

	// Get rate proposals
	const fetchRates = useCallback(() => {
		let range: GridColDef[] = [];
		let copyZones: unknown = [];
		let proposal: Proposals = [];
		/**
		 * Arrays getting objects with dynamic keys and values
		 * Example: rates[{[dinamicKey with the same name as the column value]: volume.cost}]
		 */
		let copyZonesCost: Record<string, string | number | undefined>[] = [];
		let rates: unknown = [];

		/**
		 * Loop through information and add it to the store to display it in tables
		 */
		let serviceState = removeModalityIfPresent(formatServiceToRateName(service));

		const selected = formattedServices?.filter((item) =>
			item.estafetaServiceName?.includes(serviceState)
		);
		if (!selected || !selected[0]) return;

		selected[0]?.proposals?.forEach((prop: Proposal) => {
			const modalityValue = !service
				? ''
				: service.includes('LTL')
					? ''
					: service.includes(ServicesModalityName.CONSUMPTION)
						? ServicesModalityName.CONSUMPTION
						: service.includes(ServicesModalityName.PREPAID)
							? ServicesModalityName.PREPAID
							: ServicesModalityName.NO_CREDIT;
			proposal.push({
				name: prop?.name.includes('.') ? prop?.name.split('.')[0] : prop?.name,
				id: prop?.id,
				modality: modalityValue,
				rates: prop?.rates,
				zones: prop?.zones
			});
		});

		setUrl('');
		setProposals(proposal);
		const proposalToRate = selected[0].proposals?.find(
			(item) => item.name === selectedProposal?.name
		);

		if (!proposalToRate) {
			setRates(rates as RateRanges);
			setRanges(range);
			setZones(copyZones as GridColDef[]);
			setZonesCost(copyZonesCost);
			setColumns([{ field: 'weight', headerName: 'Peso', width: 150 }, ...range]);
			return;
		}

		let formattedZones: Record<string, string | undefined> = {};
		// Setting ranges for columns
		if (!proposalToRate?.rates || proposalToRate?.rates.length === 0) return;
		range = proposalToRate?.rates[0].rangeVolumes.map((ranges: RateRange) => {
			return {
				field: `${ranges.from}-${ranges.to}`,
				headerName: `${ranges.from}-${ranges.to}`,
				width: 150,
				editable: true
			};
		});
		rates = proposalToRate?.rates?.map((rate: Rate) => {
			/**
			 * Objects that get dynamic keys and values
			 * Example: formattedInfo[dinamicKey with the same name as the column value] = volume.cost;
			 */
			let formattedInfo: Record<string, string | number | undefined> = {};
			rate?.rangeVolumes?.forEach((volume: RateRange) => {
				formattedInfo.id = rate.internalServiceName;
				formattedInfo.weight = rate.internalServiceName;
				formattedInfo[`${volume.from}-${volume.to}`] = volume.cost;
			});
			return { ...formattedInfo };
		});

		copyZones = proposalToRate?.zones?.map((zone: Zone) => {
			formattedZones.id = `${zone.cost}`;
			formattedZones[`${zone.name}`] = `${zone.cost}`;
			return {
				field: `${zone.name}`,
				headerName: `${zone.name}`,
				width: 150
			};
		});
		copyZonesCost.push({ ...formattedZones });
		copyZonesCost = copyZonesCost[0] ? [copyZonesCost[0]] : copyZonesCost;

		setRates(rates as RateRanges);
		setRanges(range);
		setZones(copyZones as GridColDef[]);
		setZonesCost(copyZonesCost);
		setColumns([{ field: 'weight', headerName: 'Peso', width: 150 }, ...range]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		formattedServices,
		setProposals,
		setRanges,
		setRates,
		setZones,
		setZonesCost,
		service,
		selectedProposal
	]);

	// Get proposal from a given client
	const getClientRates = useCallback(
		async (id: string, year?: string, fromDispersion?: boolean) => {
			setLoading(true);
			if (!setSelectedProposal) return;
			try {
				const yearRef = year ? year : selectedYear?.value;
				const response = await instanceRate.get(`/rates/${id}?with_data=true&year=${yearRef}`);
				if (!response.data.rate_configs || response.data.rate_configs.length === 0) {
					setRateService(null);
					setSelectedProposal(undefined);
					setClientRates(null);
					setUrl('');
					setLoading(false);
					return;
				}
				let selectedServiceByUser = formatServiceToRateName(service ?? '');

				// rates from the customer
				const formattedCustomerRates = response.data.rate_configs?.map((item: RateConfig) => ({
					...item,
					service: formatServiceToRateName(item.service ?? '')
				}));

				const rateProposal = formattedCustomerRates?.filter((formattedItem: RateConfig) => {
					return (
						formattedItem.service === selectedServiceByUser ||
						(formattedItem.service && formattedItem?.service?.includes(selectedServiceByUser))
					);
				});

				setClientRates(formattedCustomerRates);
				if (fromDispersion) {
					setPrices(response.data.rate_configs);
				}

				if (!rateProposal?.length || !rateProposal[0]?.prices?.length) {
					setRateService(null);
					setSelectedProposal(undefined);
					setUrl('');
					setLoading(false);
					return;
				}

				const modalityValue = !service
					? ''
					: service.includes('LTL')
						? ''
						: service.includes(ServicesModalityName.CONSUMPTION)
							? ServicesModalityName.CONSUMPTION
							: service.includes(ServicesModalityName.PREPAID)
								? ServicesModalityName.PREPAID
								: ServicesModalityName.NO_CREDIT;

				const volumeItem = rateProposal[0]?.prices[0]?.rangeVolumes?.find(
					(item: any) => item?.selected
				);
				setSelectedProposal({
					name: rateProposal[0].proposal_name ?? 'Proposal',
					id: rateProposal[0].id.toString(),
					modality: modalityValue,
					year: rateProposal[0].year || '',
					volume: `${volumeItem?.from}-${volumeItem?.to}`
				});

				setRateService({
					id: rateProposal[0].id,
					name: rateProposal[0].service,
					url: rateProposal[0].url
				});
				setLoading(false);
			} catch (error) {
				setNotification({
					message: 'Hubo un error al obtener las tarifas del cliente, inténtalo más tarde',
					severity: 'error'
				});
				setLoading(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setClientRates, setNotification, service, setRateService, setSelectedProposal, selectedYear]
	);

	const formatClientRatesInformation = useCallback(() => {
		let range: GridColDef[] = [];
		let copyZones: GridColDef[] = [];
		/**
		 * Arrays getting objects with dynamic keys and values
		 * Example: rates[{[dinamicKey with the same name as the column value]: volume.cost}]
		 */
		let copyZonesCost: Record<string, string | number | undefined>[] = [];
		let rates: unknown = [];
		/**
		 * Found selected service info
		 */
		const selected = clientRates?.find(
			(ser) => ser.id === rateService?.id || ser.id.toString() === selectedProposal?.id
		);

		if (selected?.url) {
			setUrl(selected?.url);
		}
		if (!selected?.prices) return;
		//Format information for Data Grid columns
		range = selected?.prices[0].rangeVolumes.map((ranges: RateRange) => {
			return {
				field: `${ranges.from}-${ranges.to}`,
				headerName: `${ranges.from}-${ranges.to}`,
				width: 150
			};
		});

		/**
		 * Format information for Data Grid rows
		 */
		rates = selected?.prices.map((price: Rate) => {
			/**
			 * Objects that get dynamic keys and values
			 * Example: formattedInfo[dinamicKey with the same name as the column value] = volume.cost;
			 */
			let formattedInfo: Record<string, string | number | undefined> = {};
			price?.rangeVolumes?.forEach((volume: RateRange) => {
				formattedInfo.id = price.internalServiceName;
				formattedInfo.weight = price.internalServiceName;
				formattedInfo[`${volume.from}-${volume.to}`] = volume.selected
					? `${volume.cost}`
					: volume.cost;
			});
			return { ...formattedInfo };
		});
		if (selected) {
			/**
			 * Objects that get dynamic keys and values
			 * Example: formattedInfo[dinamicKey with the same name as the column value] = volume.cost;
			 */
			let formattedZones: Record<string, string | undefined> = {};
			/**
			 * Format information for zones Data Grid rows and columns
			 */
			copyZones = selected?.zones?.map((zone: Zone) => {
				formattedZones.id = `${Date.now()}`;
				formattedZones[`${zone.name}`] = `${zone.cost}`;
				return {
					field: `${zone.name}`,
					headerName: `${zone.name}`,
					width: 150
				};
			});
			copyZonesCost.push({ ...formattedZones });
		}

		setRates(rates as RateRanges);
		setZones(copyZones);
		setZonesCost(copyZonesCost);
		setRanges(range);
		setColumns([{ field: 'weight', headerName: 'Peso', width: 150 }, ...range]);
	}, [
		clientRates,
		rateService,
		setRates,
		setUrl,
		setZones,
		setZonesCost,
		selectedProposal,
		setRanges
	]);

	const resetValues = () => {
		setRates([]);
		setClientRates(null);
		setColumns([{ field: 'weight', headerName: 'Peso', width: 150 }]);
	};

	// Sync proposals for a customer
	const syncClientRate = useCallback(
		async (customerId: string, proposalId: string, volume: string) => {
			try {
				await instanceRate.put(`/rates/${customerId}/sync/${proposalId}`, { volume });
				setNotification({
					message: 'Tarifa actualizada con éxito',
					severity: 'success'
				});
			} catch (error) {
				let errorMessage = 'Algo salió mal, contactar al equipo de Encamino';
				if (axios.isAxiosError(error)) {
					errorMessage = error.response?.data.message;
					if (error.response?.data?.errors.length) {
						errorMessage = `${errorMessage}: ${error.response?.data?.errors.join('\n,')}`;
					}
				}
				setNotification({
					message: errorMessage,
					severity: 'error'
				});
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[clientRates, setNotification]
	);

	const setIsFromUpdate = async (
		customerId: string,
		proposalId: string,
		volume: string,
		year?: string
	) => {
		await syncClientRate(customerId, proposalId, volume);
		await getClientRates(customerId, year);
	};

	const getRateYears = useCallback(async () => {
		try {
			const yearsResponse = await instanceRate.get('/rates/years');
			const yearsList = [...yearsResponse.data].reverse();
			setYears(yearsList);
		} catch (error) {
			setNotification({
				message: 'Hubo un error al obtener los años',
				severity: 'error'
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const deleteRate = useCallback(async (clientRatesId: string, rateConfigId: string) => {
		setLoading(true);
		try {
			await instanceRate.delete(`/rates/${clientRatesId}`, {
				data: { rate_config_id: parseInt(rateConfigId) }
			});
			resetValues();
			setSelectedProposal && setSelectedProposal(undefined);
			setNotification({
				message: 'Se eliminó la tarifa con éxito.',
				severity: 'success'
			});
		} catch (error) {
			let errorMessage = 'Hubo un error al eliminar la tarifa.';
			if (axios.isAxiosError(error)) {
				if (error.response?.data?.message === 'rate config not found') {
					errorMessage = 'No se encontró la tarifa seleccionada.';
				}
			}
			setNotification({
				message: errorMessage,
				severity: 'error'
			});
		} finally {
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			!!clientRates &&
			clientRates?.some((item) => item.id.toString() === selectedProposal?.id) &&
			!!selectedProposal
		) {
			formatClientRatesInformation();
		} else {
			fetchRates();
		}
	}, [clientRates, selectedProposal, fetchRates, formatClientRatesInformation]);

	useEffect(() => {
		if (!!selectedProposal) {
			fetchRates();
		}
	}, [selectedProposal]);

	useEffect(() => {
		if (!!years?.length) return;
		getRateYears();
	}, [getRateYears, years]);

	return {
		columns,
		getClientRates,
		resetValues,
		loading,
		syncClientRate,
		years,
		setSelectedYear,
		selectedYear,
		deleteRate,
		setIsFromUpdate,
		formattedServices,
		fetchRates
	};
};

export default useGetColumns;
