import produce from 'immer';
import { create } from 'zustand';
import { GridColDef } from '@mui/x-data-grid';

type RateData = {
	services: RateServices | null;
	setServices: (services: RateServices) => void;
	selectedCarrier: string;
	setSelectedCarrier: (selectedCarrier: string) => void;
	service: string;
	setService: (service: string) => void;
	rateService: RateService | null;
	setRateService: (service: RateService | null) => void;
	carrierOptions: string;
	setCarrierOptions: (carrierOptions: string) => void;
	servicesOptions: RateOptions | null;
	setServicesOptions: (servicesOptions: RateOptions) => void;
	proposals: Proposals | null;
	setProposals: (proposals: Proposals) => void;
	zones: GridColDef[] | null;
	setZones: (zones: GridColDef[]) => void;
	zonesCost: any[] | null;
	setZonesCost: (zonesCost: any[]) => void;
	rates: RateRanges | null;
	setRates: (rates: RateRanges | []) => void;
	dinamicRows: any[];
	setDinamicRows: (rates: any[]) => void;
	weights: any[] | null;
	setWeights: (weights: any[]) => void;
	ranges: any[] | [];
	setRanges: (ranges: any[]) => void;
	clientRates: RateConfigs | null;
	setClientRates: (clientRates: RateConfigs | null) => void;
	url: string;
	setUrl: (url: string) => void;
	years: { id: string; name: string }[];
	setYears: (years: { id: string; name: string }[]) => void;
};

const useRateStore = create<RateData>((set) => ({
	services: null,
	setServices: (services: RateServices) =>
		set((state) =>
			produce(state, (draft) => {
				draft.services = services;
			})
		),
	rateService: null,
	setRateService: (rateService: RateService | null) =>
		set((state) =>
			produce(state, (draft) => {
				draft.rateService = rateService;
			})
		),
	selectedCarrier: '',
	setSelectedCarrier: (selectedCarrier: string) =>
		set((state) =>
			produce(state, (draft) => {
				draft.selectedCarrier = selectedCarrier;
			})
		),
	service: '',
	setService: (service: string) =>
		set((state) =>
			produce(state, (draft) => {
				draft.service = service;
			})
		),
	carrierOptions: '',
	setCarrierOptions: (carrierOptions: string) =>
		set((state) =>
			produce(state, (draft) => {
				draft.carrierOptions = carrierOptions;
			})
		),
	servicesOptions: null,
	setServicesOptions: (servicesOptions: RateOptions) =>
		set((state) =>
			produce(state, (draft) => {
				draft.servicesOptions = servicesOptions;
			})
		),
	proposals: null,
	setProposals: (proposals: Proposals) =>
		set((state) =>
			produce(state, (draft) => {
				draft.proposals = proposals;
			})
		),
	zones: null,
	setZones: (zones: GridColDef[]) =>
		set((state) =>
			produce(state, (draft) => {
				draft.zones = zones;
			})
		),
	zonesCost: null,
	setZonesCost: (zonesCost: any[]) =>
		set((state) =>
			produce(state, (draft) => {
				draft.zonesCost = zonesCost;
			})
		),
	rates: null,
	setRates: (rates: RateRanges | []) =>
		set((state) =>
			produce(state, (draft) => {
				draft.rates = rates;
			})
		),
	dinamicRows: [],
	setDinamicRows: (dinamicRows: any[]) =>
		set((state) =>
			produce(state, (draft) => {
				draft.dinamicRows = dinamicRows;
			})
		),
	weights: null,
	setWeights: (weights: any[]) =>
		set((state) =>
			produce(state, (draft) => {
				draft.weights = weights;
			})
		),
	ranges: [],
	setRanges: (ranges: any[]) =>
		set((state) =>
			produce(state, (draft) => {
				draft.ranges = ranges;
			})
		),
	clientRates: null,
	setClientRates: (clientRates: RateConfigs | null) =>
		set((state) =>
			produce(state, (draft) => {
				draft.clientRates = clientRates;
			})
		),
	url: '',
	setUrl: (url: string) =>
		set((state) =>
			produce(state, (draft) => {
				draft.url = url;
			})
		),
	years: [],
	setYears: (years: { id: string; name: string }[]) =>
		set((state) =>
			produce(state, (draft) => {
				draft.years = years;
			})
		)
}));

export default useRateStore;
