import { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import TrackingContainer from '~components/Modals/_TrakingContainer';
import CarrierService from '~components/Tables/CarrierService';
import LogoMessage from '~pages/auth/_LogoMessage';
import StatusCell from '~components/Tables/_StatusCell';
import { HeadingOne, HeadingTwo } from '~components/Headings';

import { Grid, CircularProgress, Typography } from '@mui/material';
import { Truck, Send as SendIcon, MapPin } from 'react-feather';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

import axiosInstance from '~util/axios';
import clsx from 'clsx';
import moment from 'moment';
import { FollowUpLabelStatus } from '~util/cellStatusColor';
import { useParams } from 'react-router-dom';
import { EventType } from '~store/actions/ActionTypes';
import DrawContext from '~context/DrawContext';
import { useTrackingStyles } from './styles';
import { CARRIERS } from '~util/logos';

type TrackingGuideType = {
	id: number;
	parcel_number: string;
	tracking_number: string;
	carrier: string;
	origin: { city: string; state: string; zip_code: string };
	destination: { city: string; state: string; zip_code: string };
	service_description: string;
	service_id: number;
	created_at: string;
	follow_up: FollowUpLabelStatus;
	events: EventType[] | null;
};

const ShipmentTracking = () => {
	const classes = useTrackingStyles();
	const [guideData, setGuideData] = useState<TrackingGuideType | null>(null);
	const params: { id: string } = useParams();
	const { setNotification, setRCardStatus } = useContext(DrawContext);

	const createdAtDate = useMemo(
		() => moment(guideData?.created_at).format('LL') ?? '',
		[guideData]
	);

	const deliveredTime = useMemo(
		() =>
			guideData?.events?.find((item) => item.description === FollowUpLabelStatus.DELIVERED)
				? guideData?.events?.find((item) => item.description === FollowUpLabelStatus.DELIVERED)
						?.createdAt
				: '',
		[guideData]
	);

	const getTracking = useCallback(async () => {
		try {
			//
			const guide = await axiosInstance.get(`/api/tracking/${params.id}`);
			setGuideData(guide.data);
		} catch (error) {
			setNotification({
				message:
					'No se puedo obtener la información de la guía. Asegúrese que la URL sea correcta.',
				severity: 'error'
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.id]);

	useEffect(() => {
		getTracking();
	}, [getTracking]);

	useEffect(() => {
		setRCardStatus('HIDDEN');
	}, [setRCardStatus]);

	return (
		<Grid container justifyContent='center' alignItems='center' className={classes.root}>
			<Grid item container className={classes.container}>
				<LogoMessage text='' />
				<Grid item container justifyContent='center' alignItems='center' direction='column'>
					{guideData ? (
						<>
							<Grid item container direction='column'>
								<div className={clsx(classes.carrierContainer, classes.box)}>
									<CarrierService carrier={guideData.carrier} />
									<div className={classes.guideIdBox}>
										<HeadingTwo text='Código de rastreo' style={{ color: '#57BBD8' }} />
										<HeadingOne
											text={guideData.tracking_number}
											style={{ fontWeight: 400, paddingTop: 0 }}
										/>
									</div>
								</div>
								<div className={clsx(classes.guideData, classes.box)}>
									<p>
										<Truck />
										Servicio:
										<span>{guideData.service_description}</span>
									</p>
									<p>
										<EventAvailableIcon />
										Creación de guía:
										<span>{createdAtDate}</span>
									</p>
									<p>
										<SendIcon />
										N° de Guía:<span>{guideData.parcel_number}</span>
									</p>
									<p>
										<MapPin />
										Origen: <span>{`${guideData.origin.city}, ${guideData.origin.state}`}</span>
									</p>
								</div>
								<Grid container className={clsx(classes.box)}>
									<Grid item container alignItems='center' justifyContent='space-between'>
										<Grid item xs={3}>
											<HeadingTwo text='Estatus' />
										</Grid>
										<Grid item xs={9}>
											<StatusCell status={guideData.follow_up} />
										</Grid>
									</Grid>
									{deliveredTime && (
										<Grid item container justifyContent='flex-end' style={{ padding: '8px' }}>
											<Typography variant='caption'>{deliveredTime}</Typography>
										</Grid>
									)}
								</Grid>
							</Grid>
							{/* Tracking */}
							{!!guideData?.events && (
								<div className={clsx(classes.box, classes.boxWithBorder)}>
									<HeadingTwo text='Historial' style={{ marginBottom: 0, paddingTop: 0 }} />
									<TrackingContainer
										followUp={guideData.follow_up}
										service={guideData.service_description}
										carrier={guideData.carrier}
										events={
											[
												CARRIERS.REDPACK,
												CARRIERS.DHL,
												CARRIERS.ESTAFETA,
												CARRIERS.FEDEX,
												CARRIERS.PAQUETEXPRESS,
												CARRIERS.SENDEX
											].includes(guideData.carrier as CARRIERS)
												? guideData.events
												: undefined
										}
									/>
								</div>
							)}
						</>
					) : (
						<Grid container className={classes.loader} alignItems='center' justifyContent='center'>
							<CircularProgress />
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ShipmentTracking;
