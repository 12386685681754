import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { maxWidthContainer, radiusCard } from '~hooks/useStyles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		width: maxWidthContainer,
		backgroundColor: theme.palette.background.paper,
		borderRadius: radiusCard,
		padding: theme.spacing(3),
		marginTop: theme.spacing(3),
		'& > div': {
			marginBottom: `${theme.spacing(3)} !important`
		}
	},
	upload: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '12px',
		border: 'none !important',
		borderRadius: '50%',
		cursor: 'pointer',
		color: 'white !important',
		// '& > span': { marginLeft: theme.spacing(1), fontWeight: 'bold' },
		'&:hover': {
			// border: `1px solid ${theme.palette.primary.main}`, // #4ec0df
			backgroundColor: 'rgba(78, 192, 223, 0.08)'
		}
	},
	btnLoader: {
		// minWidth: '100px',
		display: 'flex',
		justifyContent: 'center'
	}
}));
