// Libraries
import { useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
// Hooks
import useDocumentsForm from './useDocumentsForm';
import { useStyles } from './styles';
// Components
import { HeadingOne, TextOne } from '~components/Headings';
import StatusCell from '~components/Tables/_StatusCell';
import UploadDocumentBtn from './UploadDocumentBtn';
// Utils
import { getCellStatusColor } from '~util/cellStatusColor';
import { DocumentNameType } from '~store/actions/ActionTypes';
import { capitalizeAll } from '~util/capitalize';
import DocumentDetailModal from '~pages/customer/CreateOrUpdateCustomer/DocumentDetailModal';
import { CustomerDocumentType } from '~store/actions/ActionTypes';
import GoToDetailIconButton from '~components/Buttons/GoToDetailIconButton';

export type DocumentFileType = { url: string; type: string; name: string };

export type DocumentRow = {
	status: string;
	type?: string;
	url: string;
	comment?: string;
	name: string;
};

const STATUS_REF: { [key: string]: string } = {
	approved: 'Aprobado',
	pending: 'Pendiente',
	rejected: 'Rechazado'
};

export const NAME_REF: { [key: string]: string } = {
	ine: DocumentNameType.INE,
	tax: DocumentNameType.TAX,
	proof_address: DocumentNameType.PROOF_ADDRESS,
	constitutive_act: DocumentNameType.CONSTITUTIVE_ACT,
	assembly_act: DocumentNameType.ASSEMBLY_ACT
};

const DocumentsForm = () => {
	const classes = useStyles();

	const [files, setFiles] = useState<DocumentFileType[]>([]);
	const [openDetail, setOpenDetail] = useState(false);
	const [detailDocument, setDetailDocument] = useState<Partial<CustomerDocumentType | null>>(null);

	const { isSubmitting, isLoading, rows } = useDocumentsForm();

	const uploadedFiles = useMemo(
		() => (!!rows ? rows?.filter((item) => !!item?.url)?.length : 0),
		[rows]
	);

	const goToDetail = (props: { url: string; description: string; status: string }) => {
		if (!props.status) return;
		setDetailDocument(props);
		setOpenDetail(true);
	};

	// Document table columns
	const columns: GridColDef[] = useMemo(
		() => [
			{
				field: 'documents',
				headerName: 'Documento',
				flex: 1.5,
				renderCell: (params: GridCellParams) => (
					<span
						onClick={() =>
							goToDetail({
								url: params.row.url,
								description:
									NAME_REF[params.row.name] === 'INE'
										? NAME_REF[params.row.name]
										: capitalizeAll(NAME_REF[params.row.name] ?? ''),
								status: params.row.status
							})
						}
					>
						{NAME_REF[params.row.name] === 'INE'
							? NAME_REF[params.row.name]
							: capitalizeAll(NAME_REF[params.row.name] ?? '')}
					</span>
				)
			},
			{
				field: 'description',
				headerName: 'Comentarios',
				flex: 1.5,
				valueGetter: (params) => params.row.comment
			},
			{
				field: 'status',
				headerName: 'Estatus',
				flex: 1,
				align: 'center',
				renderCell: (params: GridCellParams) => (
					<Grid item xs={8}>
						{params.row.status ? (
							<StatusCell
								status={STATUS_REF[params.row.status]}
								backgroundColor={
									params.row.status === 'approved'
										? getCellStatusColor('validated')
										: getCellStatusColor(params.row.status)
								}
							/>
						) : null}
					</Grid>
				)
			},
			{
				field: 'actions',
				headerName: 'Acciones',
				flex: 0.5,
				renderCell: (params: GridCellParams) => {
					return (
						<Grid item container justifyContent='center'>
							<div style={{ marginRight: '4px' }}>
								<UploadDocumentBtn rowData={params.row} setFiles={setFiles} files={files} />
							</div>
							<div style={{ minWidth: '44px' }}>
								{!!params.row.status && (
									<GoToDetailIconButton onClick={() => goToDetail(params.row)} />
								)}
							</div>
						</Grid>
					);
				}
			}
		],
		[files]
	);

	return (
		<Grid container justifyContent='center'>
			<Grid item container className={classes.container}>
				<Grid item>
					<HeadingOne text='Subir documentos' />
					{/* <TextOne text={capitalizeAll(documentsData?.prospect_type ?? '')} /> */}
					<TextOne text={`Avance documentación: ${uploadedFiles}/${rows?.length}`} />
				</Grid>

				<Grid item xs={12}>
					<DataGrid
						rowHeight={90}
						rows={rows}
						columns={columns}
						autoHeight
						disableSelectionOnClick
						loading={isLoading || isSubmitting}
						hideFooter
						getRowId={(props) => props.name}
					/>
				</Grid>
			</Grid>

			<DocumentDetailModal
				isOpen={openDetail}
				handleOpen={setOpenDetail}
				data={detailDocument}
				isProfile={true}
				isLoading={isLoading}
			/>
		</Grid>
	);
};

export default DocumentsForm;
