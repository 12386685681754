import { FC } from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import clsx from 'clsx';
import { useStyles } from '~components/Tabs/useStyles';

interface Props {
	currentTab: number;
	handleChange: (tab: number) => void;
	tabs: { name: string; path: string }[];
}

const CustomerTabs: FC<Props> = (props) => {
	const classes = useStyles();

	return (
		<BottomNavigation
			value={props.currentTab}
			onChange={(_, newValue) => {
				props.handleChange(newValue);
			}}
			showLabels
			className={classes.root}
		>
			{props.tabs.map((tab, index) => (
				<BottomNavigationAction
					key={tab.path}
					className={clsx([
						classes.option,
						props.currentTab === index && classes.optionSelected,
						index !== props.tabs.length - 1 && props.currentTab !== index && classes.tabBorder
					])}
					label={tab.name}
				/>
			))}
		</BottomNavigation>
	);
};

export default CustomerTabs;
