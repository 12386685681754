import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundColor: `${theme.palette.background.paper}`,
		// marginTop: '80px',
		width: '100%',
		borderRadius: '20px 20px 0 0',
		'& button': {
			borderRadius: 'inherit'
		},
		'& > *': {
			flex: 1,
			maxWidth: 'unset',
			fontWeight: 700,
			justifySelf: 'flex-start'
		}
	},
	option: {
		width: 150,
		borderBottom: 'solid 0.9px rgb(81, 81, 81)',
		backgroundColor: `${theme.palette.background.paper}`
	},
	label: {
		fontSize: 25
	},
	optionSelected: {
		borderBottom: `solid 4px ${theme.palette.primary.main}`,
		backgroundColor: '#4d4d4d',
		borderTopLeftRadius: '20px',
		borderTopRightRadius: '20px'
	},
	tabBorder: {
		boxSizing: 'border-box',
		borderBottom: 'solid 0.9px rgb(81, 81, 81)',
		paddingLeft: 0,
		paddingRight: 0,
		'& > span:nth-child(1)': {
			width: '100%',
			borderRight: '1px solid #505050',
			padding: '4px 0'
		}
	}
}));
