import { Theme } from '@mui/material/styles';
import { makeStyles, withStyles } from '@mui/styles';
import { forwardRef, Ref, ReactElement } from 'react';
import { IconButton, Typography, Slide } from '@mui/material';
import {
	Dialog as MuiDialog,
	DialogTitle as MuiDialogTitle,
	DialogContent as MuiDialogContent
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { X } from 'react-feather';

export const styles = makeStyles((theme: Theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(3),
		top: theme.spacing(3),
		color: theme.palette.grey[500]
	}
}));

// interface DialogTitleProps extends WithStyles<typeof styles> {
// 	children: ReactNode;
// 	onClose: () => void;
// }

export const Transition = forwardRef(function Transition(
	props: TransitionProps & { children: ReactElement },
	ref: Ref<unknown>
) {
	return <Slide direction='left' ref={ref} {...props} />;
});

export const DialogTitle = (props: any) => {
	const { children, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={props.classes?.root} {...other}>
			<Typography variant='h5'>{children}</Typography>
			{onClose ? (
				<IconButton aria-label='close' className={props.classes?.closeButton} onClick={onClose}>
					<X />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
};

export const DialogContent = withStyles((theme: Theme) => ({
	root: {
		padding: `0 ${theme.spacing(2)} !important`,
		backgroundColor: theme.palette.background.default
	}
}))(MuiDialogContent);

export const Dialog = withStyles(() => ({
	paperFullScreen: {
		width: '85%',
		maxWidth: '1300px'
	},
	scrollPaper: {
		justifyContent: 'flex-end'
	}
}))(MuiDialog);
