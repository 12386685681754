import React, { useEffect, useState } from 'react';
import axios from '~util/axios';
import { TextField } from '@mui/material';
import { Autocomplete } from '@material-ui/lab';
import { Controller, FieldErrors, Control } from 'react-hook-form';
import { SellerResponse } from '~store/actions/ActionTypes';
import usePermissions from '~hooks/usePermissions';
import { useSelector } from 'react-redux';
import { RootStore } from '~store/store';
import DropdownPaper from '~components/Forms/DropdownPaper';

interface Props {
	control: Control;
	errors: FieldErrors;
	defaultValue?: SellerResponse;
	defaultValueProspect?: number[];
	disabled?: boolean;
}

/**
 * Autocomplete for Agents initials
 */
const SearchAgentInitials: React.FC<Props> = (props) => {
	const [loading, setIsLoading] = useState<boolean>(true);
	const [agents, setAgents] = useState<SellerResponse[]>([]);
	const { isRootOrAgent } = usePermissions();
	const { simulatedCustomer } = useSelector((state: RootStore) => state.simulatedUser);

	// Fetch the agents
	useEffect(() => {
		(async () => {
			try {
				const { data } = await axios.get('/api/operators?role=seller');
				setAgents(data ?? []);
			} catch (error) {
				console.error('Error while fetching agents in [InputSearchAgentInitials]', error);
			} finally {
				setIsLoading(false);
			}
		})();
	}, [props.defaultValueProspect, isRootOrAgent, simulatedCustomer?.id]);

	if (loading) return <div></div>;
	return (
		<Controller
			render={({ onChange, ...propsown }) => (
				<Autocomplete
					{...propsown}
					disabled={props.disabled}
					openOnFocus
					limitTags={2}
					options={agents ?? []}
					getOptionLabel={(option) => option.name}
					getOptionSelected={(option, value) => option.id === value.id}
					onChange={(_, data) => onChange(data)}
					loading={loading}
					renderInput={(params) => (
						<TextField
							{...params}
							label='Vendedor'
							variant='filled'
							error={Boolean(props.errors?.seller)}
							helperText={props.errors?.seller?.message}
							autoComplete='off'
						/>
					)}
					PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
				/>
			)}
			name='seller'
			control={props.control}
			defaultValue={
				props.defaultValue
					? {
							...props.defaultValue,
							name: `${props.defaultValue.name} ${props.defaultValue.lastName}`
					  }
					: undefined
			}
		/>
	);
};

export default SearchAgentInitials;
