import { FC, Fragment, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { PermissionsKey } from '~context/AuthContext';
//Hooks
import usePermissions from '~hooks/usePermissions';
//Components
import ProfileData from './ProfileData';
import ProfileTabs from '~components/Tabs/ProfileTabs';
import ProfileRates from './ProfileRates';
import CustomerDocuments from '~pages/customer/CreateOrUpdateCustomer/CustomerDocuments';
import ProfileContract from './ProfileContract';

type BookletOption = {
	path: string;
	name: string;
	component: FC;
	permission: PermissionsKey;
};

const ProfilePage = () => {
	const location = useLocation();
	const history = useHistory();

	const { can } = usePermissions();

	const booklets: BookletOption[] = useMemo(
		() => [
			{
				path: '/perfil/datos',
				name: 'Datos',
				component: ProfileData,
				permission: 'canRead.app'
			},
			{
				path: '/perfil/tarifas',
				name: 'Tarifas',
				component: ProfileRates,
				permission: 'canRead.app'
			},
			{
				path: '/perfil/documentos',
				name: 'Documentos',
				component: CustomerDocuments,
				permission: 'canRead.app'
			},
			{
				path: '/perfil/contrato',
				name: 'Contrato',
				component: ProfileContract,
				permission: 'canRead.app'
			}
		],
		[]
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const enabledTabs = useMemo(() => booklets.filter((booklet) => can(booklet.permission)), [can]);
	const tabs = useMemo(
		() => enabledTabs.map((booklet) => ({ name: booklet.name, path: booklet.path })),
		[enabledTabs]
	);

	const index = useMemo(() => {
		return enabledTabs.findIndex((b) => location.pathname?.includes(b.path));
	}, [location, enabledTabs]);

	const Component = enabledTabs[index]?.component;

	return (
		<Fragment>
			<ProfileTabs
				tabs={tabs}
				currentTab={index}
				handleChange={(tabIndex) => {
					const booklet = enabledTabs[tabIndex];
					const url = booklet.path;
					history.replace(url);
				}}
			/>
			<Component />
		</Fragment>
	);
};

export default ProfilePage;
