import { FC, useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useFieldArray } from 'react-hook-form';
import produce from 'immer';
import { Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Autocomplete } from '@material-ui/lab';
import { shallow } from 'zustand/shallow';
import clsx from 'clsx';
import { User } from 'react-feather';
import { GridColDef, GridEditCellPropsParams } from '@mui/x-data-grid';
// Hooks
import useServices, { TariffListItem } from '~hooks/useServices';
import { useCardStyles } from '~hooks/useStyles';
import usePermissions from '~hooks/usePermissions';
// Store
import { BookletServiceForm, CustomerResponse } from '~store/actions/ActionTypes';
import { setServicesCUCustomer } from '~store/actions/agents';
import useRateStore from '~store/useRateStore';
import { RootStore } from '~store/store';
// Components
import DataGrid from '~components/Tables/DataGrid';
import { getColumns } from './columns';
import Selector from './Selector';
import { HeadingOne } from '~components/Headings';
import DropdownPaper from '~components/Forms/DropdownPaper';
// Utils
import { ProspectResponseType } from '~store/actions/ActionTypes';
import { CARRIERS, getCarrierName } from '~util/logos';
import useBalanceStore from '~store/useBalanceStore';
import useGetColumns, { formatServiceToRateName } from '../Client/ClientRate/useGetColumns';
import { getRateCurrentYear } from '~util/rateYear';

export enum NotSuitableForRates {
	ESTAFETA_PICKUP = 'Estafeta: Recolección',
	ESTAFETA_TRACKING = 'Estafeta: Rastreo',
	ESTAFETA_TRACKING_PICKUP = 'Estafeta: Rastreo Recolección'
}

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
		width: '100%',
		marginTop: theme.spacing(4),
		'& .MuiGrid-item': {
			paddingLeft: 8
		}
	},
	table: {
		marginTop: theme.spacing(2),
		backgroundColor: theme.palette.background.paper
	},
	settingsContainer: {
		marginTop: 0,
		marginBottom: theme.spacing(2.5)
	},
	label: {
		marginBottom: theme.spacing(1),
		display: 'inline-block'
	},
	error: {
		color: theme.palette.error.main
	},
	container: {
		backgroundColor: theme.palette.background.paper,
		marginTop: theme.spacing(2),
		borderRadius: theme.spacing(1)
		// padding: `0 ${theme.spacing(2)}`
	},
	icon: {
		marginBottom: theme.spacing(-1),
		marginRight: theme.spacing(1.25)
	},
	helpText: {
		color: 'rgba(255, 255, 255, 0.7)',
		margin: 0
	}
}));

interface ServicesTableProps {
	register: any;
	control: any;
	error?: string;
	onChange: () => void;
	defaultValues: BookletServiceForm[];

	// If editable in order to assign stock, etc
	editable?: boolean;
	customer?: CustomerResponse | ProspectResponseType;
	fromDispersion?: boolean;
	// callback for creating rates from dispersion flow
	onCreateRateFromDispersion?: () => void;
}

/**
 * This is the Service Selector Table
 * here can be edited and selected the carrier and corresponding service
 */
const ServicesTable: FC<ServicesTableProps> = ({
	register,
	control,
	error,
	onChange,
	defaultValues,
	// When managing clients
	editable,
	customer,
	fromDispersion,
	onCreateRateFromDispersion
}) => {
	const classes = useStyles();
	const { cardClasses } = useCardStyles();

	const { isRootOrAgent, isCustomer } = usePermissions();
	const history = useHistory();
	const { id } = useParams<{ id?: string }>();
	const dispatch = useDispatch();

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'services'
	});

	const {
		selectedServices,
		updateSelectedServices,
		carrierOptions,
		services,
		addService,
		carrier,
		updateCarrier,
		removeService,
		loading,
		tariffsList,
		selectedService,
		setSelectedService
	} = useServices({ initialValues: defaultValues, editable: Boolean(editable), isCustomer });

	const [setSelectedCarrier, setService, proposals, service, ranges, clientRates] = useRateStore(
		(state) => [
			state.setSelectedCarrier,
			state.setService,
			state.proposals,
			state.service,
			state.ranges,
			state.clientRates
		],
		shallow
	);

	const { currentFlow } = useSelector((state: RootStore) => state.flow);
	const { customerHasBalance } = useBalanceStore();

	const [columns, setColumns] = useState<GridColDef[]>([]);
	const [addedServices, setAddedServices] = useState<string[]>([]);
	const [selectedRate, setSelectedRate] = useState<TariffListItem | null>(null);
	const [volumeForEstafetaRate, setVolumeForEstafetaRate] = useState<string | null>(null);
	const [selectedProposal, setSelectedProposal] = useState<Proposal | null | undefined>(null);

	const { fetchRates, setSelectedYear, selectedYear, getClientRates } = useGetColumns({
		selectedProposal,
		setSelectedProposal
	});

	const isEstafeta = useMemo(() => carrier?.value?.toLowerCase() === CARRIERS.ESTAFETA, [carrier]);
	const hasFetchRates = useRef(false);
	const tariffsOptions = useMemo(
		() =>
			isEstafeta
				? proposals
				: tariffsList?.filter(
						(item) => item.carrier?.toLowerCase() === carrier?.value?.toLowerCase()
				  ) ?? [],
		[tariffsList, proposals, carrier, isEstafeta]
	);

	// FIXME
	// This is a kind of hotfix to render the service data grid
	// it looks like gives a problem when history push or replace
	const [done, setDone] = useState(false);
	useEffect(() => {
		setDone(selectedServices.length > 0);
	}, [selectedServices.length]);

	// Set columns
	useEffect(() => {
		setColumns(
			getColumns({
				onDelete: (_: number, id: number) => {
					// NOTE(eduardo): This is why we do the reverse().
					const index = selectedServices.findIndex((s) => s.id === id);
					remove(index);
					removeService(id);
				},
				editable: Boolean(editable) && isRootOrAgent,
				showControlls: currentFlow !== 'cucustomer',
				goToRate: (id: number) => {
					const service = selectedServices.find((s) => s.id === id);
					if (typeof service?.carrier === 'string') {
						const currentYear = getRateCurrentYear();
						let serviceState =
							parseInt(currentYear) <= 2024
								? formatServiceToRateName(service.name)
								: service.rates_service_description ?? service?.name;

						setService(serviceState);
						setSelectedCarrier(service.carrier);
					}

					// if (fromDispersion && onCreateRateFromDispersion) {
					// 	onCreateRateFromDispersion();
					// 	return;
					// }

					let url = `/tarifas/${customer?.id}`;
					history.push(url);
				},
				addedServices,
				disabled: !customer?.id,
				customer: customer as CustomerResponse,
				hasBalance: Boolean(customer && customerHasBalance(customer))
			})
		);
	}, [
		editable,
		remove,
		removeService,
		isRootOrAgent,
		currentFlow,
		selectedServices,
		history,
		id,
		setSelectedCarrier,
		setService,
		customer?.id,
		fromDispersion,
		addedServices,
		onCreateRateFromDispersion,
		customer,
		customerHasBalance
	]);

	// Fill forms
	useEffect(() => {
		if (loading && defaultValues) {
			// NOTE(eduardo): We do the reverse since we need to match the order
			// of items between the fields from `useFieldArray` and the ones
			// from our `useServices` hook. This order is important in the
			// onDelete callback used by getColumns
			defaultValues.forEach((value) => {
				append(value);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, defaultValues]);

	useEffect(() => {
		if (!!service && !!selectedYear && !hasFetchRates.current) {
			fetchRates();
			hasFetchRates.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasFetchRates, service, selectedYear]);

	useEffect(() => {
		if (id) {
			getClientRates(id, getRateCurrentYear());
		}
	}, [getClientRates, id]);

	// Clear errors
	useEffect(() => {
		if (selectedServices.length > 0) {
			onChange();
		}
		if (editable) {
			dispatch(setServicesCUCustomer([...selectedServices].reverse()));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedServices, dispatch, editable]);

	// When is editable for manage clients
	const handleEditCellChangeCommitted = useCallback(
		async ({ id, field, props }: GridEditCellPropsParams) => {
			updateSelectedServices((oldservices) =>
				produce(oldservices, (draft) => {
					const serviceToUpdate = oldservices.findIndex((s) => s.id === id);
					if (
						field === 'min_stock' ||
						field === 'discount' ||
						field === 'price_list' ||
						field === 'irregular_delivery_price' ||
						field === 'reissue_price' ||
						field === 'insurance_percentage'
					) {
						let nextValue = +(props.value as string);
						nextValue = nextValue <= -1 ? 0 : nextValue;
						if (serviceToUpdate > -1) {
							//@ts-ignore
							draft[serviceToUpdate][field] = nextValue;

							// HOTFIX to decide wether discount ot price_list
							if (field === 'discount' && nextValue > 0) {
								draft[serviceToUpdate].price_list = 0;
							} else if (field === 'price_list' && nextValue > 0) {
								draft[serviceToUpdate].discount = 0;
							} else if (field === 'min_stock' && nextValue <= 0) {
								draft[serviceToUpdate].min_stock = 1;
							}
						}
					} else {
						// the field is enables
						// @ts-ignore
						draft[serviceToUpdate][field] = props.value;
					}
				})
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// Format added service
	const selectAndFormatService = ({
		service,
		tariff,
		volume,
		proposal,
		carrier
	}: {
		service: BookletServiceForm;
		tariff?: { percentage_increase: number; name: string; id?: string };
		volume?: string;
		proposal?: Proposal;
		carrier: string;
	}) => {
		const extra: any = {
			price_list: 0,
			min_stock: 1,
			discount: tariff?.percentage_increase ?? 0,
			enabled: true,
			proposal_id: proposal?.id,
			volume: volume,
			carrier,
			tariff_name: isEstafeta && tariff ? `${tariff?.name} / ${volume}` : tariff?.name ?? ''
		};

		if (editable) {
			addService({ ...service, ...extra });
		} else {
			addService(service);
		}
		setAddedServices((prev) =>
			prev.concat(typeof service.id === 'string' ? service.id : service.id.toString())
		);
		append({ value: service.id });
		setVolumeForEstafetaRate(null);
		setSelectedService(null);
		setSelectedRate(null);
		setSelectedProposal(null);
	};

	return (
		<Grid container className={clsx(cardClasses.card, classes.root)}>
			<div>
				<HeadingOne text='Servicios' style={{ padding: '0 0 24px 0' }} />

				<p className={classes.helpText}>
					Seleccionar proveedor y servicio para visualizar opciones a dar de alta
				</p>
			</div>

			{isRootOrAgent || currentFlow !== 'cucustomer' ? (
				<Grid
					container
					direction='row'
					alignItems='flex-end'
					justifyContent='space-between'
					className={classes.settingsContainer}
					// spacing={4}
				>
					<Grid
						container
						item
						xs={isEstafeta ? 3 : 4}
						direction='row'
						justifyContent='center'
						alignItems='center'
						className={classes.container}
					>
						{!isEstafeta && (
							<Grid item xs={1}>
								<User className={classes.icon} />
							</Grid>
						)}

						<Grid item xs={isEstafeta ? 12 : 11}>
							<Autocomplete
								size='small'
								selectOnFocus
								value={carrier}
								options={carrierOptions}
								getOptionLabel={(option) => option.label}
								getOptionSelected={(option, value) => option.label === value.label}
								renderInput={(params) => (
									<Grid container alignItems='center' justifyContent='center'>
										<Grid item xs={12}>
											<TextField {...params} label='Proveedor' variant='filled' fullWidth />
										</Grid>
									</Grid>
								)}
								onChange={(_, value) => {
									value && updateCarrier(value);
								}}
								PaperComponent={(paperProps) => (
									<DropdownPaper>{paperProps.children}</DropdownPaper>
								)}
							/>
						</Grid>
					</Grid>
					<Selector
						options={services}
						onChange={(service) => {
							selectedRate && setSelectedRate(null);

							setSelectedService(service);
							if (service.name.includes('2 Dias')) {
								selectAndFormatService({
									service,
									carrier: service.carrier
								});
								return;
							}

							// Check if the rate exist for that service
							const existingRate =
								!!clientRates &&
								clientRates?.find((item) => item?.service === service?.rates_service_description);
							if (existingRate) {
								const volumeItem = existingRate?.prices[0]?.rangeVolumes?.find(
									(item: any) => item?.selected
								);
								const year = existingRate?.year || '';
								const volume = `${volumeItem?.from}-${volumeItem?.to}`;
								const name = existingRate?.proposal_name ?? 'Proposal';
								const carrier = service.carrier;

								setSelectedProposal({
									name,
									id: existingRate?.id.toString(),
									year,
									modality: '',
									volume
								});
								const tariff = {
									name,
									carrier,
									percentage_increase: 0,
									year
								};
								setSelectedRate(tariff);
								selectAndFormatService({
									service,
									tariff,
									carrier,
									volume
								});
							}

							// Set service to get proposals for estafeta
							if (!isEstafeta) return;
							service?.rates_service_description &&
								setService(service.rates_service_description ?? service?.name);
							!hasFetchRates.current &&
								setSelectedYear({ value: getRateCurrentYear(), fromUser: false });
						}}
						isEstafeta={isEstafeta}
						selectedService={selectedService ?? undefined}
					/>
					<Grid item xs={isEstafeta ? 3 : 4}>
						<Autocomplete
							size='small'
							selectOnFocus
							disabled={selectedService?.name.includes('2 Dias')}
							// @ts-ignore
							value={selectedRate ?? { name: '' }}
							// @ts-ignore
							options={tariffsOptions ?? []}
							getOptionLabel={(option) => option?.name}
							getOptionSelected={(option, value) => !!option?.name && option?.name === value?.name}
							renderInput={(params) => (
								<TextField {...params} fullWidth label='Tarifario' variant='filled' />
							)}
							onChange={(_, value: any) => {
								if (!selectedService || !value) return;

								setSelectedRate(value);
								if (!isEstafeta) {
									selectAndFormatService({
										service: selectedService,
										tariff: value,
										carrier: value.carrier
									});
									return;
								}
								setSelectedProposal(value);
							}}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					</Grid>
					{isEstafeta && (
						<Grid item xs={3}>
							<Autocomplete
								size='small'
								selectOnFocus
								disabled={selectedService?.name.includes('2 Dias')}
								value={volumeForEstafetaRate || ''}
								options={ranges?.map((item) => item.field)}
								getOptionLabel={(option) => option}
								getOptionSelected={(option, value) => option === value}
								renderInput={(params) => (
									<TextField {...params} fullWidth label='Volumen' variant='filled' />
								)}
								onChange={(_, value) => {
									if (!selectedService || !selectedRate || !value) return;
									setVolumeForEstafetaRate(value);
									selectAndFormatService({
										service: selectedService,
										proposal: selectedProposal ?? undefined,
										volume: value,
										tariff: selectedRate,
										carrier: getCarrierName(CARRIERS.ESTAFETA)
									});
									setSelectedService(null);
								}}
								PaperComponent={(paperProps) => (
									<DropdownPaper>{paperProps.children}</DropdownPaper>
								)}
							/>
						</Grid>
					)}
				</Grid>
			) : (
				<Grid
					container
					direction='row'
					alignItems='flex-end'
					justifyContent='space-between'
					className={classes.settingsContainer}
				></Grid>
			)}
			{error ? <p className={classes.error}>{error}</p> : <p />}
			{done && (
				<DataGrid
					columns={columns}
					style={{ color: 'white' }}
					pageSize={selectedServices.length}
					rowsPerPageOptions={[selectedServices.length]}
					rows={selectedServices}
					autoHeight
					// When managing customers
					onEditCellPropsChange={handleEditCellChangeCommitted}
					disableSelectionOnClick={Boolean(editable)}
				/>
			)}
			{fields.map((field, index) => (
				<input
					type='hidden'
					key={`${index}${field.id!}`}
					defaultValue={field.value}
					name={`services[${index}].value`}
					ref={register()}
				/>
			))}
		</Grid>
	);
};

export default ServicesTable;
