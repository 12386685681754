import React from 'react';
import { TextField } from '@mui/material';
import { Autocomplete } from '@material-ui/lab';
import { Controller, FieldErrors, Control } from 'react-hook-form';
import DropdownPaper from '~components/Forms/DropdownPaper';

export const enterpriseOperationsOptions = [
	{ title: 'Alimentos/bebidas', value: 'food' },
	{ title: 'Industria Agropecuaria', value: 'agriculture' },
	{ title: 'Marketing/Publicidad', value: 'marketing' },
	{ title: 'Automotriz', value: 'automotive' },
	{ title: 'Salud', value: 'health' },
	{ title: 'Inmobiliaria', value: 'real_estate' },
	{ title: 'Electrónica/Tecnología', value: 'technology' },
	{ title: 'Educación', value: 'education' },
	{ title: 'Servicios Profesionales', value: 'professional' },
	{ title: 'Servicios Gubernamentales', value: 'government' },
	{ title: 'Organización Civil', value: 'non_profit' },
	{ title: 'Transportación', value: 'transport' },
	{ title: 'Moda', value: 'fashion' },
	{ title: 'Otro', value: 'other' } // DO NOT DELETE this value
];

interface Props {
	// Function to pass the selected value
	control: Control;
	errors: FieldErrors;
	defaultValue?: { title: string; value: string };
	disabled?: boolean;
}

/**
 * Autocomplete for 'Tipo de empresa'
 */
const EnterpriseOperations: React.FC<Props> = (props) => {
	return (
		<Controller
			render={({ onChange, ...propsown }) => (
				<Autocomplete
					{...propsown}
					openOnFocus
					options={enterpriseOperationsOptions}
					getOptionLabel={(option) => option.title}
					getOptionSelected={(option, value) => option.title === value.title}
					onChange={(_, data) => onChange(data)}
					disabled={props.disabled}
					renderInput={(params) => (
						<TextField
							{...params}
							label='Giro'
							variant='filled'
							error={Boolean(props.errors?.enterprise_operations)}
							helperText={props.errors?.enterprise_operations?.message}
							autoComplete='none'
						/>
					)}
					PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
				/>
			)}
			name='enterprise_operations'
			control={props.control}
			// rules={{ required: 'Ingrese el giro de la empresa' }}
			defaultValue={!!props.defaultValue?.value ? props.defaultValue : null}
		/>
	);
};

export default EnterpriseOperations;
