import { useEffect, useMemo } from 'react';
//Libraries
import { Grid, Typography, TextField } from '@mui/material';
import { Control, Controller, useWatch } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { shallow } from 'zustand/shallow';
//Styles
import { useStyles } from './useReporTable.styles';
//Store
import useReportStore from '~store/ReportStore';
//Hooks
import usePermissions from '~hooks/usePermissions';
import useOptions from './hooks/useOptions';
import { subtypeOptions, cutOptions, reviewOptions, billingOptions } from './options';
import capitalize from '~util/capitalize';
import { FieldErrors } from 'react-hook-form';
import DropdownPaper from '~components/Forms/DropdownPaper';
import {
	ReportFileType,
	ReportItem,
	ReportNumber,
	ReportSubType,
	ReportType,
	SchemaType
} from '~store/actions/ActionTypes';

interface Props {
	control: Control;
	initialValues?: ReportItem;
	errors: FieldErrors;
	setValue?: (key: string, value: string) => void;
}

const InsuranceRow = ({ control, initialValues, errors, setValue }: Props) => {
	const classes = useStyles();

	const { isRoot } = usePermissions();
	const {
		dateOptions,
		handleDateOptions,
		handleSubtypes,
		handleReportNumber,
		reportNunberOptions
	} = useOptions({ setValue });

	const [
		overweigthReportNumber,
		setInsuranceReportNumber,
		insuranceCutoffDate,
		setInsirancetCutoffDate,
		guidesCutoffDate,
		overweightCutoffDate,
		insuranceReportNumber
	] = useReportStore(
		(state) => [
			state.overweigthReportNumber,
			state.setInsuranceReportNumber,
			state.insuranceCutoffDate,
			state.setInsirancetCutoffDate,
			state.guidesCutoffDate,
			state.overweightCutoffDate,
			state.insuranceReportNumber
		],
		shallow
	);

	const reportFileType = useWatch({
		control,
		name: 'report_file_type'
	});

	const guidesDate: string | undefined = useWatch({
		control,
		name: 'guides_date'
	});

	const hasSameFileSet = useMemo(
		() => reportFileType === ReportFileType.SAME_FILE,
		[reportFileType]
	);

	const hasFilledValues = useMemo(
		() => !!insuranceCutoffDate || !!guidesCutoffDate || !!overweightCutoffDate,
		[insuranceCutoffDate, guidesCutoffDate, overweightCutoffDate]
	);

	// TODO: this condition?
	const canNotEditCutData = useMemo(
		() => insuranceReportNumber === ReportNumber.ONE,
		[insuranceReportNumber]
	);

	useEffect(() => {
		handleReportNumber(overweigthReportNumber!);
	}, [handleReportNumber, overweigthReportNumber]);

	useEffect(() => {
		initialValues &&
			handleSubtypes(
				hasSameFileSet && canNotEditCutData ? guidesCutoffDate : insuranceCutoffDate,
				'insurance',
				initialValues!
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleSubtypes, initialValues, insuranceCutoffDate, guidesCutoffDate, canNotEditCutData]);

	useEffect(() => {
		setInsirancetCutoffDate(initialValues?.schema || '');
	}, [initialValues?.schema, setInsirancetCutoffDate]);

	useEffect(() => {
		handleDateOptions(insuranceCutoffDate);
	}, [handleDateOptions, insuranceCutoffDate]);

	useEffect(() => {
		if (initialValues?.type === ReportType.ONLY_LABELS) {
			setInsuranceReportNumber(ReportNumber.THREE);
		} else if (
			initialValues?.type === ReportType.LABELS_AND_OVERWEIGHTS ||
			initialValues?.type === ReportType.OVERWEIGHTS_AND_INSURANCES
		) {
			setInsuranceReportNumber(ReportNumber.TWO);
		} else {
			setInsuranceReportNumber(ReportNumber.ONE);
		}
	}, [initialValues?.type, setInsuranceReportNumber]);

	useEffect(() => {
		if (hasSameFileSet && guidesDate && canNotEditCutData) {
			setValue && setValue('insurance_date', guidesDate);
		}
	}, [guidesDate, hasSameFileSet, initialValues, setValue, canNotEditCutData]);

	useEffect(() => {
		if (initialValues) {
			setValue && setValue('insurance_date', initialValues?.date ?? '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Grid
			container
			direction='row'
			alignItems='center'
			justifyContent='space-between'
			className={classes.container}
		>
			{/* Num. Rep. */}
			<Grid item className={classes.small}>
				<Controller
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							disableClearable
							options={reportNunberOptions}
							disabled={!isRoot}
							getOptionLabel={(option) => option.num ?? ''}
							getOptionSelected={(option, value) => !!value?.num && option.num === value?.num}
							onChange={(_, data) => {
								onChange(data);
								setInsuranceReportNumber(data?.num);
							}}
							renderInput={(params) => (
								<TextField {...params} variant='filled' error={!!errors?.insurance_reportNumber} />
							)}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					name='insurance_reportNumber'
					control={control}
					defaultValue={
						initialValues?.type === ReportType.ALL ||
						initialValues?.type === ReportType.LABELS_AND_INSURANCES
							? { num: '1' }
							: initialValues?.type === ReportType.LABELS_AND_OVERWEIGHTS ||
							  initialValues?.type === ReportType.OVERWEIGHTS_AND_INSURANCES
							? { num: '2' }
							: initialValues?.type === ReportType.ONLY_INSURANCES
							? { num: '3' }
							: null
					}
					rules={isRoot && hasFilledValues ? { required: true } : { required: false }}
				/>
			</Grid>

			{/* Reports number of files */}
			<Grid item className={classes.medium}></Grid>

			{/* Report Type */}
			<Grid item className={classes.extraxBig}>
				<Typography className={classes.text}>Seguro</Typography>
			</Grid>

			{/* Cutoff day schema*/}
			<Grid item className={classes.big}>
				<Controller
					name='insurance_schema'
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							options={cutOptions}
							disabled={!isRoot || (canNotEditCutData && hasSameFileSet)}
							getOptionLabel={(option) => capitalize(option.title) ?? ''}
							getOptionSelected={(option, value) => !!value?.title && option.title === value?.title}
							onChange={(_, data) => {
								onChange(data);
								setInsirancetCutoffDate(data?.title);
								if (!setValue) return;
								setValue('insurance_date', '');
								setValue('insurance_subtype', '');
							}}
							renderInput={(params) => {
								return (
									<TextField
										{...params}
										label='Corte'
										variant='filled'
										error={!!errors?.insurance_schema}
									/>
								);
							}}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					control={control}
					defaultValue={initialValues?.schema ? { title: initialValues?.schema } : null}
					rules={{ required: isRoot && hasFilledValues }}
				/>
			</Grid>

			{/* Cutoff date */}
			<Grid item className={classes.big}>
				<Controller
					name='insurance_date'
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							options={dateOptions}
							disabled={
								!isRoot ||
								insuranceCutoffDate === SchemaType.IMMEDIATE ||
								insuranceCutoffDate === SchemaType.INDEPENDENT ||
								(canNotEditCutData && hasSameFileSet)
							}
							getOptionLabel={(option) => capitalize(option) || ''}
							getOptionSelected={(option, value) => option === value}
							onChange={(_, data) => {
								onChange(data);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Fecha'
									variant='filled'
									error={!!errors?.insurance_date}
								/>
							)}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					control={control}
					defaultValue={initialValues?.date}
					rules={{
						required:
							isRoot &&
							hasFilledValues &&
							insuranceCutoffDate !== SchemaType.IMMEDIATE &&
							insuranceCutoffDate !== SchemaType.INDEPENDENT
					}}
				/>
			</Grid>

			{/* Subtype */}
			<Grid item className={classes.big}>
				<Controller
					name='insurance_subtype'
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							options={
								insuranceCutoffDate === SchemaType.IMMEDIATE ||
								insuranceCutoffDate === SchemaType.BIWEEKLY ||
								insuranceCutoffDate === SchemaType.WEEKLY
									? [
											insuranceCutoffDate === SchemaType.IMMEDIATE
												? ''
												: insuranceCutoffDate === SchemaType.BIWEEKLY
												? ReportSubType.WEEK
												: ReportSubType.BIWEEK
									  ]
									: subtypeOptions.filter(
											(item) => item !== ReportSubType.BIWEEK && item !== ReportSubType.WEEK
									  )
							}
							disabled={
								!isRoot ||
								insuranceCutoffDate !== SchemaType.MONTHLY ||
								(canNotEditCutData && hasSameFileSet)
							}
							getOptionLabel={(option) => capitalize(option) ?? ''}
							getOptionSelected={(option, value) => option === value}
							onChange={(_, data) => {
								onChange(data);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Subtipo'
									variant='filled'
									error={!!errors?.insurance_subtype}
								/>
							)}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					control={control}
					defaultValue={initialValues?.sub_type}
					rules={{
						required: isRoot && hasFilledValues && insuranceCutoffDate === SchemaType.MONTHLY
					}}
				/>
			</Grid>

			{/* Review days */}
			<Grid item className={classes.big}>
				<Controller
					name='insurance_conditions_report'
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							options={reviewOptions}
							disabled={!isRoot || hasSameFileSet}
							getOptionLabel={(option) => option.title || ''}
							getOptionSelected={(option, value) => {
								return option.title === value?.title;
							}}
							onChange={(_, data) => {
								onChange(data);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Días de revisión'
									variant='filled'
									error={!!errors?.insurance_conditions_report}
								/>
							)}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					control={control}
					defaultValue={initialValues?.conditions ? { title: initialValues?.conditions } : null}
					rules={{ required: isRoot && hasFilledValues && !hasSameFileSet }}
				/>
			</Grid>

			{/* Billing */}
			<Grid item className={classes.big}>
				<Controller
					name='insurance_pre_invoice_days'
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							options={billingOptions}
							disabled={!isRoot || hasSameFileSet}
							getOptionLabel={(option) => option.title ?? ''}
							getOptionSelected={(option, value) => !!value?.title && option.title === value?.title}
							onChange={(_, data) => {
								onChange(data);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Días prefactura'
									variant='filled'
									error={!!errors?.insurance_pre_invoice_days}
								/>
							)}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					control={control}
					defaultValue={
						initialValues?.pre_invoice_days ? { title: initialValues?.pre_invoice_days } : null
					}
					rules={{ required: isRoot && hasFilledValues && !hasSameFileSet }}
				/>
			</Grid>
		</Grid>
	);
};

export default InsuranceRow;
