import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Fab, Fade, Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { RefreshCcw } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DrawContext from '~context/DrawContext';
import { maxWidthContainer, radiusCard } from '~hooks/useStyles';
import { useSelector } from '~store/store';
import { initFlow, resetSend, swipeAddressSend, triggerValidationsSend } from '~store/actions';
import AddressCard from '../Cards/AddressCard';
import PackageCard from '../Cards/PackageCard';
import ControlBar from '../Bar/ControlBar';
import RateOrSendControl from '../Bar/RateOrSendControl';
import usePermissions from '~hooks/usePermissions';
import { clearErrorsSimulatedUser, triggerErrorSimulatedUser } from '~store/actions/agents';
import { removePackageSend, addPkgSend } from '~store/actions';
import usePackagesStore from '~store/newstore/usePackagesStore';
import { shallow } from 'zustand/shallow';
import { resetCreatedLabels } from '~store/actions/DataActions';
import { Tooltip } from '@mui/material';
import ConfirmActionDialog from '~components/Modals/ConfirmActionDialog';
import { getUserBalance } from '~store/actions/DataActions';
import useBalanceStore from '~store/useBalanceStore';
import AuthContext from '~context/AuthContext';
import { Package } from '~store/actions/ActionTypes';

const useStyles = makeStyles((theme: Theme) => ({
	convertBtn: {
		position: 'absolute',
		marginTop: '55px',
		marginLeft: '10px',
		border: `6px solid ${theme.palette.background.paper}`,
		backgroundColor: theme.palette.background.default,
		color: theme.palette.text.primary
	},
	centerFab: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	root: {
		width: '100%',
		padding: theme.spacing(3),
		borderRadius: `0 0 ${radiusCard}px ${radiusCard}px`,
		maxWidth: maxWidthContainer
	}
}));

/**
 * The container for send
 */
const Send: React.FC = () => {
	const classes = useStyles();
	const [setHasOldContent, hasOldContent] = usePackagesStore(
		(state) => [state.setHasOldContent, state.hasOldContent],
		shallow
	);
	const { setNotification, setAvailableBalance } = useContext(DrawContext);
	const { currentUser } = useContext(AuthContext);

	const dispatch = useDispatch();
	const history = useHistory();
	const {
		originAddressSend,
		destinationAddressSend,
		currentPackagesSend,
		canContinueToRateSend,
		showInsuranceOnSummary
	} = useSelector((state) => state.send);
	const { canPostSimulatedUser } = useSelector((state) => state.simulatedUser);
	const { disableGlobalInsuranceFlow, contentOptions } = useSelector((state) => state.flow);
	const { simulatedCustomer, simulatedUser } = useSelector((state) => state.simulatedUser);

	const { isRootOrAgent } = usePermissions();

	const [reset, setReset] = useState(false);
	const [wantsToMove, setWantsToMove] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState<'insurance' | 'charge' | null>(null);
	const [addInsurance, setAddInsurance] = useState<boolean>(
		!disableGlobalInsuranceFlow || showInsuranceOnSummary
	);
	const { customerHasBalance } = useBalanceStore();

	// 1. Change the card
	// 2. Reset errors when the container gets mounted anyway can not move util is valid
	// 3. Init the flow
	// 4. Clear error if is an agent or customer
	const { setRCardStatus } = useContext(DrawContext);
	useEffect(() => {
		dispatch(triggerValidationsSend(false));
		dispatch(initFlow('send'));
		setRCardStatus('SUMMARY_ADDRESS');
		dispatch(clearErrorsSimulatedUser());

		return () => {
			setRCardStatus('HIDDEN');
		};
	}, [setRCardStatus, dispatch]);

	useEffect(() => {
		if (currentPackagesSend?.some((item) => item?.merchandise?.length)) {
			currentPackagesSend?.forEach((item) => {
				const packageItem = { ...item, merchandise: [] };
				dispatch(removePackageSend(item?.pkg_id));
				dispatch(addPkgSend(packageItem));
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPackagesSend]);

	const checkPackageContent = useCallback(
		async (packages: Package[]) => {
			try {
				let hasOld = false;
				packages.forEach((item) => {
					if (item.merchandise?.length === 0 || item.merchandise === null) {
						const {
							alias,
							content,
							height,
							id,
							insurance,
							length,
							measurement,
							pkg_id,
							quantity,
							weight,
							type,
							width
						} = item;
						const packageItem = {
							alias,
							content,
							height,
							id,
							insurance,
							length,
							measurement,
							pkg_id,
							quantity,
							weight,
							type,
							width
						};
						dispatch(removePackageSend(pkg_id));
						dispatch(addPkgSend(packageItem));
					}
					const localSearch = contentOptions.find((content) => content === item.content);
					hasOld = !localSearch;
				});
				setHasOldContent(hasOld);
			} catch (error) {
				console.error(error);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setHasOldContent, contentOptions]
	);

	useEffect(() => {
		if (currentPackagesSend.length > 0 && !!contentOptions?.length) {
			checkPackageContent(currentPackagesSend);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPackagesSend, checkPackageContent]);

	useEffect(() => {
		if (hasOldContent) {
			setNotification({
				message:
					'El contenido del paquete ha cambiado, en necesario actualizarlo para continuar con la creación de la guía',
				severity: 'error'
			});
		}
	}, [hasOldContent, setNotification]);

	useEffect(() => {
		return () => {
			setHasOldContent(false);
		};
	}, [setHasOldContent]);

	// validate before move to rate when sending
	useEffect(() => {
		setWantsToMove(false);

		if (!(wantsToMove && canContinueToRateSend)) {
			return;
		}
		if (!isRootOrAgent) {
			history.push('/resultados');
			return;
		}
		if (canPostSimulatedUser) {
			history.push('/resultados');
		}
	}, [wantsToMove, history, canContinueToRateSend, dispatch, isRootOrAgent, canPostSimulatedUser]);

	const canContinueWhenSending = (confirm?: boolean) => {
		if (hasOldContent) {
			return setNotification({
				message:
					'El contenido del paquete ha cambiado, en necesario actualizarlo para continuar con la creación de la guía',
				severity: 'error'
			});
		}

		if (
			addInsurance &&
			currentPackagesSend.every((item) => !item.insurance || item?.insurance === 0)
		) {
			return setNotification({
				message: 'Todos tus empaques tienen $0.00 en Valor Declarado',
				severity: 'error',
				time: 2000
			});
		}

		// Alert for insured package with no declared value
		if (addInsurance && currentPackagesSend.some((item) => item.insurance === 0) && !confirm) {
			setOpenConfirmDialog('insurance');
			return;
		}

		// Alert for envelope with a content other than documents
		if (
			currentPackagesSend.some(
				(item) =>
					item.type?.toLowerCase() === 'sobre' &&
					!item.content?.toLowerCase()?.includes('documentos')
			) &&
			!confirm
		) {
			setOpenConfirmDialog('charge');
			return;
		}

		setWantsToMove(true);
		dispatch(triggerValidationsSend(true));
		if (isRootOrAgent) {
			dispatch(triggerErrorSimulatedUser());
		}
	};

	useEffect(() => {
		dispatch(resetCreatedLabels());
	}, [dispatch]);

	useEffect(() => {
		if (customerHasBalance(isRootOrAgent ? simulatedCustomer : currentUser?.customer)) {
			getUserBalance(isRootOrAgent, setAvailableBalance, simulatedCustomer?.id, simulatedUser?.id);
		}
	}, [
		isRootOrAgent,
		simulatedCustomer,
		simulatedUser,
		currentUser,
		customerHasBalance,
		setAvailableBalance
	]);

	return (
		<Fade in timeout={1500} mountOnEnter unmountOnExit>
			<div>
				<ConfirmActionDialog
					isOpen={!!openConfirmDialog}
					onClose={() => setOpenConfirmDialog(null)}
					handleClickConfirm={() => {
						setOpenConfirmDialog(null);
						canContinueWhenSending(true);
					}}
					handleClickCancel={() => setOpenConfirmDialog(null)}
					bodyText={
						openConfirmDialog === 'insurance'
							? `Algunos de tus empaques tienen $0.00 en Valor Declarado.
					¿Deseas Continuar?
					`
							: `El transportista podrá cobrar un extra por exceder dimensiones del sobre.
					¿Deseas Continuar?
					`
					}
					confirmBtnLabel='Sí'
					cancelBtnLabel='No'
				/>

				<div className={classes.root}>
					{/* Container */}
					<Grid container alignItems='center'>
						{/* Origen */}
						<Grid item xs={12} md={6}>
							<AddressCard for='Origen' reset={reset} />
						</Grid>
						{(Boolean(originAddressSend) || Boolean(destinationAddressSend)) && (
							<div className={classes.centerFab}>
								<Tooltip title='Invertir Direcciones' placement='top'>
									<span>
										<Fab
											className={classes.convertBtn}
											onClick={() => dispatch(swipeAddressSend())}
										>
											<RefreshCcw />
										</Fab>
									</span>
								</Tooltip>
							</div>
						)}

						{/* Destino */}
						<Grid item xs={12} md={6}>
							<AddressCard for='Destino' reset={reset} />
						</Grid>
					</Grid>
					{/* Paquetes */}
					<br />
					<PackageCard
						currentPackages={currentPackagesSend}
						setAddInsurance={setAddInsurance}
						addInsurance={addInsurance}
					/>
				</div>
				{/* Bottom Bar */}
				<ControlBar
					onContinue={canContinueWhenSending}
					close
					onLeftControl={() => {
						dispatch(resetSend());
						dispatch(resetCreatedLabels());
					}}
					rightText='Continuar'
				>
					<RateOrSendControl />
				</ControlBar>
			</div>
		</Fade>
	);
};

export default Send;
