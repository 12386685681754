//Libraries
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Control, FieldErrors } from 'react-hook-form';
import clsx from 'clsx';
//Hooks
import { useCardStyles } from '~hooks/useStyles';
//Components
import ColumnsReportsConfig from './ReportTableConfig/ColumnsReportConfig';
import GuidesRow from './ReportTableConfig/GuidesRow';
import OverweightRow from './ReportTableConfig/OverweightRow';
import InsuranceRow from './ReportTableConfig/InsuranceRow';
import { HeadingOne } from '~components/Headings';
import { ReportItem, ReportName } from '~store/actions/ActionTypes';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
		marginTop: theme.spacing(4),
		width: '100%'
	},
	tableContainer: {
		border: '1px solid #515151',
		borderRadius: '8px',
		padding: '20px'
	}
}));
interface Props {
	control: Control;
	initialValues?: ReportItem[];
	errors: FieldErrors;
	setValue?: (key: string, value: unknown) => void;
}

const ClientReportsConfig: React.FC<Props> = ({ control, initialValues, errors, setValue }) => {
	const classes = useStyles();
	const { cardClasses } = useCardStyles();

	return (
		<div className={clsx(cardClasses.card, classes.root)}>
			<HeadingOne text='Reportes' style={{ padding: '0 0 24px 0' }} />

			<Grid
				container
				direction='column'
				alignItems='center'
				justifyContent='space-between'
				className={classes.tableContainer}
			>
				{/* Columns top table */}
				<ColumnsReportsConfig />
				<hr style={{ border: '1px solid #515151', width: '100%' }} />

				{/* Rows */}
				<>
					{/* Guides config */}
					<GuidesRow
						control={control}
						initialValues={
							initialValues && initialValues.find((item) => item.name === ReportName.LABELS)
						}
						errors={errors}
						setValue={setValue}
					/>
					<hr style={{ border: '1px solid #515151', width: '100%' }} />

					{/* Sobrepesos */}
					<OverweightRow
						control={control}
						initialValues={
							initialValues &&
							initialValues.find(
								(item) =>
									item.name === ReportName.OVERWEIGHTS ||
									item.name === ReportName.OVERWEIGHTS_SUPPLIER
							)
						}
						errors={errors}
						setValue={setValue}
					/>
					<hr style={{ border: '1px solid #515151', width: '100%' }} />

					{/* Seguros */}
					<InsuranceRow
						control={control}
						initialValues={
							initialValues && initialValues.find((item) => item.name === ReportName.INSURANCES)
						}
						errors={errors}
						setValue={setValue}
					/>
				</>
			</Grid>
		</div>
	);
};

export default ClientReportsConfig;
