/* eslint-disable no-useless-escape */
import isURL from 'validator/lib/isURL';

export const Validators = {
	// Min-length 12 and Max-length 13
	RFC: /^[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$/,

	Email:
		/^(([^'<>()[\]\\óáéíúüïëöäñ.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*))@(([a-zA-Z\-0-9áéíóúüïëöä]+\.)+[a-zA-Záéíóúüïëöä]{2,})$/,
	PhoneNumber:
		/^\s*(((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})|((\+?52)?(1|01)?\d{10,10}$))\s*$/,

	AddressAlias: /^\s*[A-Za-zÁÉÍÓÚÜÑñáéíóúü\s0-9,.@#$%&*()-_=+:'"]{1,60}\s*$/,
	AddressBusinessName: /^\s*[A-Za-zÁÉÍÓÚÜÑñáéíóú\s0-9,.@#$%&*()-_=+:'"]{1,50}\s*$/,
	ShortBusinessName: /^\s*[A-Za-zÁÉÍÓÚÑñáéíóú\s0-9,.@#$%&*()-_=+:'"]{1,100}\s*$/,
	LongBusinessName: /^\s*[A-Za-zÁÉÍÓÚÑñáéíóú\s0-9,.@#$%&*()-_=+:'"]{1,250}\s*$/,
	Country: /^\s*[A-Za-zÁÉÍÓÚÑñáéíóúü\s0-9,.@#$%&*()-_=+:'"]{1,50}\s*$/,
	ZipCode: /^\s*[0-9][0-9][0-9][0-9][0-9]\s*$/,
	Neighborhood: /^\s*[A-Za-zÁÉÍÓÚÑñáéíóúü\s0-9,.@#$%&*()-_=+:'"]{1,50}\s*$/,
	City: /^\s*[A-Za-zÁÉÍÓÚÑñáéíóúü\s0-9,.@#$%&*()-_=+:'"]{1,50}\s*$/,
	State: /^\s*[A-Za-zÁÉÍÓÚÑñáéíóúü\s0-9,.@#$%&*()-_=+:'"]{1,50}\s*$/,
	Street: /^\s*[A-Za-zÁÉÍÓÚÑñáéíóúü\s0-9,.@#$%&*()-_=+:'"]{1,60}\s*$/,
	Number: /^\s*[A-Za-zÁÉÍÓÚÑñáéíóúü\s0-9,.@#$%&*()-_=+:'"]{1,25}\s*$/,
	ReferenceStreets: /^\s*[A-Za-zÁÉÍÓÚÑñáéíóúü\s0-9,.@#$%&*()-_=+:'"]{1,360}\s*$/,
	ContactName: /^\s*[A-Za-zÁÉÍÓÚÑñáéíóúü\s0-9,.@#$%&*()-_=+:'"]{1,50}\s*$/,

	URL: (url: string) => isURL(url)
};

export const EstafetaValidator = {
	Street: /^\s*[^$^|~]{1,25}\s*$/,
	BusinessName: /^\s*[^$^|~]{1,50}\s*$/,
	InternalNumber: /^\s*[^$^|~]{1,5}\s*$/,
	ExternalNumber: /^\s*[^$^|~]{1,5}\s*$/,
	ContactName: /^\s*[^$^|~]{1,30}\s*$/,
	PhoneNumber:
		/^\s*(((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})|((\+?52)?(1|01)?\d{10,11}))\s*$/
};

export const DhlValidator = {
	CompanyName: /^\s*[A-Za-zÁÉÍÓÚÑñáéíóúü\s0-9,.@#$%&*()-_=+:'"]{2,35}\s*$/,
	PersonName: /^\s*[A-Za-zÁÉÍÓÚÑñáéíóúü\s0-9,.@#$%&*()-_=+:'"]{2,35}\s*$/,
	Street: /^\s*[A-Za-zÁÉÍÓÚÑñáéíóúü\s0-9,.@#$%&*()-_=+:'"]{1,45}\s*$/
};
