import React, { useEffect, useState, useContext } from 'react';
import { TextField } from '@mui/material';
import { Autocomplete } from '@material-ui/lab';
import { Controller, FieldErrors, Control } from 'react-hook-form';
import AuthContext from '~context/AuthContext';
import { UserRole } from '~store/actions/ActionTypes';
import DropdownPaper from '~components/Forms/DropdownPaper';

export const channelOptions = [
	{ title: 'Vendedor', value: 'vendor' },
	{ title: 'Facebook', value: 'facebook' },
	{ title: 'Grupo de Facebook', value: 'facebook_group' },
	{ title: 'Instagram', value: 'instagram' },
	{ title: 'Google', value: 'google' },
	{ title: 'Agencia MKTG', value: 'agency_mktg' },
	{ title: 'Empresa Desarrollo', value: 'company_dev' },
	{ title: 'Recomendación', value: 'recommendation' },
	{ title: 'Evento', value: 'event' },
	{ title: 'Otro', value: 'other' }
];

interface Props {
	// Function to pass the selected value
	control: Control;
	errors: FieldErrors;
	defaultValue?: { title: string; value: string };
}

/**
 * Autocomplete for 'Cómo no encontraste?'
 */
const ChannelInput: React.FC<Props> = (props) => {
	const { currentUser } = useContext(AuthContext);

	const [localChannelOptions, setChannelOptions] = useState(
		channelOptions.filter((f) => f.value !== 'vendor')
	);

	useEffect(() => {
		if (currentUser.role === UserRole.AGENT || currentUser.role === UserRole.ROOT) {
			setChannelOptions((prev) => [{ title: 'Vendedor', value: 'vendor' }, ...prev]);
		}
	}, [currentUser.role]);

	return (
		<Controller
			render={({ onChange, ...propsown }) => (
				<Autocomplete
					{...propsown}
					openOnFocus
					options={localChannelOptions}
					getOptionLabel={(option) => option.title}
					getOptionSelected={(option, value) => option.title === value.title}
					onChange={(_, data) => onChange(data)}
					renderInput={(params) => (
						<TextField
							{...params}
							label='¿Cómo te enteraste?'
							variant='filled'
							error={Boolean(props.errors?.channel)}
							helperText={props.errors?.channel?.message}
							autoComplete='none'
						/>
					)}
					PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
				/>
			)}
			name='channel'
			control={props.control}
			// rules={{ required: 'Ingresé el medio por el cual se enteró de Encamino' }}
			defaultValue={props.defaultValue ? props.defaultValue : null}
		/>
	);
};

export default ChannelInput;
