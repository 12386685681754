import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	// General data tab
	dataContainer: {
		'& > div': {
			borderTopLeftRadius: 0,
			borderTopRightRadius: 0
		}
	},
	// Rates tab
	ratesContainer: {
		padding: '24px',
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0
		//minHeight: '60vh'
	},
	subsectionTitle: {
		padding: '12px 0 !important'
	},
	spacing: {
		paddingBottom: `${theme.spacing(3)} !important`
	},
	uploadBtn: {
		display: 'flex',
		alignItems: 'center',
		minWidth: '100px',
		padding: '6px 14px',
		color: '#3f403f !important',
		backgroundColor: '#E5E5E5',
		outline: '0px',
		border: '0px',
		borderRadius: '8px',
		fontWeight: 500,
		fontSize: '0.875rem',
		fontFamily: 'Roboto',
		cursor: 'pointer',
		'&:disabled': {
			color: 'grey',
			opacity: 0.9
		},
		'&:hover': {
			background: '#E5E5E5',
			opacity: 0.9
		}
	}
}));
