import { makeStyles } from '@mui/styles';
import { radiusCard } from '~hooks/useStyles';
import { maxWidthContainer } from '~hooks/useStyles';
const useStyles = makeStyles((theme) => ({
	root: {
		width: maxWidthContainer,
		padding: theme.spacing(3)
	},
	container: {
		padding: theme.spacing(3),
		width: '100%',
		borderRadius: radiusCard
	},
	boxSpacing: {
		padding: `${theme.spacing(1)} 0`
	},
	title: {
		// padding: `${theme.spacing(3)} 0 0 0 !important`,
		marginLeft: '-24px'
	},
	leftArrow: {
		color: '#57BAD7',
		backgroundColor: 'transparent',
		border: 'none',
		'&:focus': {
			outline: 'none'
		},
		'&:hover': {
			cursor: 'pointer'
		}
	},
	saveButton: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: 'black',
		backgroundColor: 'white',
		border: 'none',
		padding: '10px',
		width: '90%',
		borderRadius: '20px',
		'&:focus': {
			outline: 'none'
		},
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: 'rgba(200,200,200,.9)'
		}
	},

	// CustomerDocuments
	nameContainer: {
		padding: `0 0 ${theme.spacing(3)} 0`
	}
}));

export default useStyles;
