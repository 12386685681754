import { FC } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { User, CheckCircle } from 'react-feather';

const useStyles = makeStyles(() => ({
	font: {
		fontWeight: 'bold',
		fontSize: 15
	}
}));

interface Props {
	generalInfo: boolean;
}

const CreateOrUpdateClientControl: FC<Props> = ({ generalInfo }) => {
	const classes = useStyles();

	return (
		<Grid container justifyContent='flex-start' className={classes.font}>
			<Grid item container alignItems='center' xs={4}>
				{generalInfo ? <CheckCircle color='#6FE46D' /> : <User />}
				&nbsp;&nbsp;Generales
			</Grid>
		</Grid>
	);
};

export default CreateOrUpdateClientControl;
