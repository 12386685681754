import { useMemo, useCallback, Dispatch, SetStateAction } from 'react';
import { Tooltip, CircularProgress } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Upload as UploadIcon } from 'react-feather';
// Hooks
import { useStyles } from './styles';
import useDocumentsForm from './useDocumentsForm';
// Utils
import { DocumentRow, NAME_REF } from './DocumentsForm';
import { DocumentRequestType } from '~store/actions/ActionTypes';
import { useTheme } from '~hooks/useTheme';

export type DocumentFileType = { url: string; type: string; name: string };

const UploadDocumentBtn = ({
	rowData,
	files,
	setFiles,
	updateRows
}: {
	rowData: DocumentRow;
	files: DocumentFileType[] | [];
	setFiles: Dispatch<SetStateAction<DocumentFileType[] | []>>;
	updateRows?: () => void;
}) => {
	const classes = useStyles();

	const { isSubmitting, saveDocument } = useDocumentsForm();

	const disabled = useMemo(() => !!rowData.status, [rowData]);
	const { themeV4 } = useTheme();

	// Save document
	const handleUpload = useCallback(
		async (payload: DocumentRequestType) => {
			await saveDocument(payload);
			updateRows && updateRows();
		},
		[saveDocument, updateRows]
	);

	// Format files to preview
	const formatImg = useCallback(
		async (file: File) => {
			let resultFile: Partial<DocumentFileType> = {};
			if (file.type.includes('image')) {
				// @ts-ignore
				const response = await fetch(file.url);
				const data = await response.blob();
				const fileItem = new File([data], file.name, {
					type: file.type
				});

				Object.assign(fileItem, {
					url: URL.createObjectURL(fileItem)
				});

				const reader = new FileReader();
				reader.onload = async function () {
					reader.result &&
						(await handleUpload({
							// @ts-ignore
							files: fileItem,
							file_type: fileItem.type,
							document: NAME_REF[rowData.name]
						}));
				};
				resultFile = fileItem;
			} else {
				const fileItem = new File([file], file.name, {
					type: file.type
				});

				await handleUpload({
					// @ts-ignore
					files: fileItem,
					file_type: file.type,
					document: NAME_REF[rowData.name]
				});

				resultFile = file;
			}

			setFiles([
				...files,
				{ url: resultFile.url ?? '', type: resultFile.type ?? '', name: resultFile.name ?? '' }
			]);
		},
		[setFiles, files, handleUpload, rowData.name]
	);

	const onDrop = useCallback(
		(acceptedFile: any) => {
			if (!acceptedFile?.length) return;
			const newFile = { type: acceptedFile[0].type, path: acceptedFile[0].path, url: '' };
			Object.assign(newFile, {
				url: URL.createObjectURL(acceptedFile[0])
			});
			formatImg(acceptedFile[0]);
		},
		[formatImg]
	);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		noDrag: true,
		maxFiles: 1,
		multiple: false
	});

	return (
		<Tooltip
			title={!disabled ? 'Subir archivo' : 'Subido'}
			aria-label={!disabled ? 'Subir archivo' : 'Subido'}
		>
			<div
				{...getRootProps()}
				className={classes.upload}
				style={
					!disabled
						? {
								border: '1px solid rgba(78, 192, 223, 0.5)',
								color: themeV4.palette.primary.main
						  }
						: {
								border: '1px solid rgba(255, 255, 255, 0.12)',
								color: 'rgba(255, 255, 255, 0.3)',
								cursor: 'auto'
						  }
				}
			>
				<input {...getInputProps()} disabled={disabled} />
				{!isSubmitting ? (
					<>
						<UploadIcon size={20} style={disabled ? { color: 'grey' } : {}} />
						{/* <span>Subir archivo</span> */}
					</>
				) : (
					<div className={classes.btnLoader}>
						<CircularProgress size={18} color='primary' />
					</div>
				)}
			</div>
		</Tooltip>
	);
};

export default UploadDocumentBtn;
