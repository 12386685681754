import { TextField, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Autocomplete } from '@material-ui/lab';
import { BookletServiceForm } from '~store/actions/ActionTypes';
import { Archive } from 'react-feather';
import DropdownPaper from '~components/Forms/DropdownPaper';

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: theme.palette.background.paper,
		marginTop: theme.spacing(2),
		borderRadius: theme.spacing(1)
	},
	icon: {
		marginBottom: theme.spacing(-1),
		marginRight: theme.spacing(1.25)
	}
}));

type SelectorProps = {
	options: BookletServiceForm[];
	onChange: (user: BookletServiceForm) => void;
	isEstafeta?: boolean;
	selectedService?: BookletServiceForm;
};

export default function Selector({
	options,
	onChange,
	isEstafeta,
	selectedService
}: SelectorProps) {
	const classes = useStyles();

	return (
		<Grid
			container
			item
			xs={isEstafeta ? 3 : 4}
			direction='row'
			justifyContent='center'
			alignItems='center'
			className={classes.container}
		>
			{!isEstafeta && (
				<Grid item xs={1}>
					<Archive className={classes.icon} />
				</Grid>
			)}

			<Grid item xs={isEstafeta ? 12 : 11}>
				<Autocomplete
					size='small'
					selectOnFocus
					// @ts-ignore
					value={selectedService ?? { name: '' }}
					options={options}
					getOptionLabel={(option) => option.name}
					getOptionSelected={(option, value) => !!option.name && option.name === value.name}
					renderInput={(params) => (
						<Grid container alignItems='center' justifyContent='center'>
							<Grid item xs={12}>
								<TextField {...params} label='Servicio' variant='filled' />
							</Grid>
						</Grid>
					)}
					clearOnBlur
					onChange={(_, value) => {
						if (value) {
							onChange(value);
							// To clear it
							// setValue(null);
						}
					}}
					PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
				/>
			</Grid>
		</Grid>
	);
}
