import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
	ArrowRight,
	ArrowLeft,
	Save as SaveIcon,
	Send as SendIcon,
	Truck,
	Eye,
	Edit
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
	btn: {
		color: '#fff',
		background: 'linear-gradient(180deg, #57BBD8 0%, #0088D5 100%), #57BBD8 !important',
		'&:hover': {
			background: '#1A84BF !important'
		},
		borderRadius: '8px',
		padding: `${theme.spacing(1.5)} ${theme.spacing(2.5)}`,
		minWidth: 120
	},
	text: {
		padding: '0 6px'
	}
}));

interface Props extends ButtonProps {
	redirectTo?: string;
	children?: string;
	isLoading?: boolean;
	icon?: 'save' | 'arrow' | 'package' | 'create-guide' | 'arrow_left' | 'pickup' | 'eye' | 'edit';
	noIcon?: boolean;
}

export default function SaveButton({
	redirectTo,
	children,
	icon,
	isLoading,
	noIcon,
	...props
}: Props) {
	const classes = useStyles();

	return (
		<Button className={classes.btn} {...props}>
			{isLoading ? (
				<CircularProgress size={24} />
			) : (
				<>
					{/* TODO: remove (with button prop for icon) */}
					{icon === 'save' && <SaveIcon size='20' />}
					{icon === 'create-guide' && <SendIcon size='20' />}
					{icon === 'arrow_left' && <ArrowLeft size='20' />}
					{icon === 'pickup' && <Truck size='20' />}
					{icon === 'eye' && <Eye size='20' />}
					{icon === 'edit' && <Edit size='20' />}
					<span className={classes.text}>{children ?? 'Continuar'}</span>
					{!icon && !noIcon && <ArrowRight size='24' color='#fff' />}
				</>
			)}
		</Button>
	);
}
