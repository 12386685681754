import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { maxWidthContainerCard } from '~hooks/useStyles';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(3),
		width: maxWidthContainerCard
	},
	container: {
		'&>*': {
			marginTop: theme.spacing(2),
			padding: theme.spacing(1)
		}
	}
}));

export default useStyles;
