import { FC, useMemo } from 'react';
import { Grid, TextField } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Autocomplete } from '@material-ui/lab';
import { Controller, useForm } from 'react-hook-form';
// Components
import { Transition, DialogTitle, DialogContent, Dialog } from '~pages/shipments/components';
import { HeadingTwo } from '~components/Headings';
import SaveButton from '~components/Tables/Controls/SaveButton';
import DropdownPaper from '~components/Forms/DropdownPaper';
import { CustomerDocumentType } from '~store/actions/ActionTypes';
// Utils
import { radiusCard } from '~hooks/useStyles';
import { STATUS_REF } from './CustomerDocuments';

const INFO_FIELDS = [
	'document_type',
	'file_type',
	'file_name',
	'url',
	'last_update',
	'created_date',
	'description',
	'status',
	'id',
	'name'
];

const FIELD_LABEL_REF: { [key: string]: string } = {
	status: 'Estatus',
	// description: '',
	// created_date: '',
	// last_update: '',
	// comment: 'Razón de rechazo',
	// url: '',
	// document_type: '',
	// file_type:'',
	// file_name:'',
	last_name: 'Apellido',
	second_last_name: 'Segundo Apellido',
	address: 'Dirección',
	neighborhood: 'Barrio',
	city: 'Ciudad',
	country: 'País',
	business_name: 'Empresa',
	company_name: 'Razón Social',
	companny_name: 'Razón Social',
	tax_id: 'RFC',
	number: 'Número exterior',
	suite_number: 'Número interior',
	state: 'Estado',
	street: 'Calle',
	admin_name: '',
	denomination: 'Denominación',
	date: 'Fecha',
	public_deed: '',
	notary_number: '',
	public_notary: '',
	zip_code: 'Código Postal',
	date_of_issue: 'Fecha',
	validity: 'Válido hasta',
	names: 'Nombre'
};

interface Props {
	isOpen: boolean; // is shown
	handleOpen: (s: boolean) => void;
	data: Partial<CustomerDocumentType | null>;
	isProfile?: boolean;
	onSubmit?: (data: any) => void;
	isLoading?: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		backgroundColor: theme.palette.background.paper,
		borderRadius: radiusCard,
		padding: theme.spacing(3),
		'&>h4': {
			margin: `${theme.spacing(2)} 0 ${theme.spacing(2)} ${theme.spacing(2)}`,
			width: '100%',
			textAlign: 'left'
		}
	},
	titleContainer: {
		padding: '16px'
	},
	leftSide: {
		padding: `0 ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)}`
		// '& > button': {
		// 	width: '100%',
		// 	marginTop: theme.spacing(2)
		// }
	},
	btnContainer: {
		padding: theme.spacing(3)
	},
	headerContainer: {
		padding: `${theme.spacing(3)} 0`,
		'& button': {
			top: 10
		}
	},
	statusBox: {
		padding: `${theme.spacing(2)} 0 ${theme.spacing(3)} 0`
	}
}));

const DocumentDetailModal: FC<Props> = ({
	isOpen,
	handleOpen,
	data,
	isProfile,
	onSubmit,
	isLoading
}: Props) => {
	const classes = useStyles();
	const { handleSubmit, errors, control } = useForm();

	const editableFields = useMemo(() => {
		const resulFields: any[] = [];
		if (!data) return [];
		Object.entries(data).forEach(([key, value]) => {
			if (!INFO_FIELDS.includes(key)) {
				resulFields.push({ name: key, label: FIELD_LABEL_REF[key], value });
			}
		});
		return resulFields;
	}, [data]);

	const handleClose = () => {
		handleOpen(false);
	};

	return (
		<Dialog fullScreen TransitionComponent={Transition} onClose={handleClose} open={isOpen}>
			<DialogContent className={classes.container}>
				<Grid container alignItems='flex-start' justifyContent='center'>
					<Grid
						item
						xs={12}
						container
						justifyContent='space-between'
						alignItems='flex-start'
						className={classes.headerContainer}
					>
						<DialogTitle onClose={() => handleOpen(false)}>
							{data?.description ?? data?.document_type}
						</DialogTitle>
					</Grid>

					<Grid container justifyContent='center' alignItems={isProfile ? 'center' : 'flex-start'}>
						<Grid item xs={isProfile ? 10 : 7}>
							{data?.url && (
								<embed
									style={{ minHeight: '90vh' }}
									src={data?.url}
									type={data?.file_type}
									width={'100%'}
									height={'100%'}
									key={data?.id}
								/>
							)}
						</Grid>

						<Grid item xs={12} md={isProfile ? 1 : 5} container className={classes.leftSide}>
							{!isProfile && (
								<>
									<HeadingTwo text='Revisar datos' />

									<Grid item xs={12} container spacing={3} className={classes.statusBox}>
										<Grid item xs={12}>
											<Controller
												render={({ onChange, ...propsown }) => (
													<Autocomplete
														{...propsown}
														size='small'
														selectOnFocus
														options={['Pendiente', 'Validado', 'Rechazado']}
														getOptionLabel={(option) => option}
														getOptionSelected={(option, value) => option === value}
														renderInput={(params) => (
															<TextField
																{...params}
																label='Estatus'
																variant='outlined'
																fullWidth
																size='small'
															/>
														)}
														PaperComponent={(paperProps) => (
															<DropdownPaper>{paperProps.children}</DropdownPaper>
														)}
														onChange={(_, value) => {
															onChange(value);
														}}
													/>
												)}
												name='status'
												control={control}
												// rules={{ required: 'Ingresé el rol del colaborador' }}
												defaultValue={data?.status ? STATUS_REF[data?.status] : null}
											/>
										</Grid>
									</Grid>
								</>
							)}
							{!isProfile && (
								<Grid item xs={12} container spacing={3}>
									{editableFields.map((item) => (
										<Grid item xs={12} key={item.name}>
											<Controller
												as={
													<TextField
														error={Boolean(errors[item.name])}
														helperText={errors[item.name]?.message}
														variant='outlined'
														fullWidth
														size='small'
														label={item.label}
													/>
												}
												rules={{ required: `Ingrese el ${item.label}` }}
												name={item.name}
												control={control}
												defaultValue={item.value}
											/>
										</Grid>
									))}
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
			{onSubmit && (
				<Grid item container justifyContent='flex-end' className={classes.btnContainer}>
					<SaveButton
						icon='save'
						type='submit'
						onClick={handleSubmit(onSubmit)}
						disabled={isLoading}
						isLoading={isLoading}
					>
						Guardar
					</SaveButton>
				</Grid>
			)}
		</Dialog>
	);
};

export default DocumentDetailModal;
