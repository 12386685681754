import { create } from 'zustand';
import { Option } from '~components/Forms/Select';
import {
	NewBusiness,
	DispersionService,
	AvailableService,
	DispersionPurchaseType
} from '~store/actions/ActionTypes';

type CreateDispersion = {
	id?: string;
	services: DispersionService[];
	business?: NewBusiness;
	payment_method?: string;
	payment_type?: string;
	cfdi_use?: string;
	tax_regimen?: string;
	order?: string;
	cfdi_use_description?: string;
};

export type ServiceCharge = {
	id: number;
	carrier: string;
	type: string;
	description: string;
	price?: number;
	quantity: number;
	iva: number;
};

export type AdditionalCharge = {
	id: number;
	price: number;
	quantity: number;
};

type DispersionState = {
	dispersion: CreateDispersion;
	setDispersion: (dispersion: CreateDispersion) => void;
	setServices: (services: DispersionService[]) => void;
	addService: (service: DispersionService) => void;
	setServiceQuantity: (serviceId: number, quantity: number) => void;
	setServicePrice: (serviceId: number, price: number) => void;
	deleteServiceSelected: (serviceId: number) => void;
	setBusiness: (business?: NewBusiness) => void;
	paymentMethod?: string;
	setPaymentMethod: (paymentMethod: string) => void;
	paymentType?: string;
	setPaymentType: (paymentType: string) => void;
	cfdiUse?: string;
	setCfdiUse: (cfdiUse: string) => void;
	cfdiUseDescription?: string;
	setCfdiDescription: (cfdiUseDescription: string) => void;
	setTaxRegimen: (taxRegimen: string) => void;
	carrier?: Option | null;
	setCarrier: (carrier: Option | null) => void;
	customerServices: AvailableService[];
	setCustomerServices: (customerServices: AvailableService[]) => void;
	availableServices: AvailableService[];
	setServicesAvailable: (availableServices: AvailableService[]) => void;
	prices: RateConfig[];
	setPrices: (prices: RateConfig[]) => void;
	customerId?: number;
	setCustomerId: (customerID: number | undefined) => void;
	setOrder: (order: string) => void;
	setId: (id: string) => void;
	type?: DispersionPurchaseType;
	setType: (newType: DispersionPurchaseType) => void;
	servicesChargesOptionList: ServiceCharge[];
	setServicesChargesOptionList: (servicesCharges: ServiceCharge[]) => void;
	setAdditionalCharges: (additionalCharge: ServiceCharge[] | []) => void;
	additionalCharges: ServiceCharge[] | [];
	addAdditionalCharge: (additionalCharge: ServiceCharge) => void;
	updateAdditionalCharge: ({
		chargeId,
		price,
		quantity,
		iva
	}: {
		chargeId: number;
		price: number;
		quantity: number;
		iva: number;
	}) => void;
	removeAdditionalCharge: (chargeId: number) => void;
};

export const usePurchaseStore = create<DispersionState>((set) => ({
	dispersion: {
		services: []
	},
	customerServices: [],
	availableServices: [],
	prices: [],
	servicesChargesOptionList: [],
	additionalCharges: [],
	setDispersion: (dispersion) => set({ dispersion }),
	setCarrier: (carrier) =>
		set((state) => ({
			carrier: carrier
		})),
	setServicesAvailable: (availableServices) =>
		set((state) => ({
			availableServices
		})),
	setServices: (services) =>
		set((state) => ({
			dispersion: {
				...state.dispersion,
				services
			}
		})),
	setPrices: (prices) =>
		set((state) => ({
			prices
		})),
	addService: (service) =>
		set((state) => ({
			dispersion: {
				...state.dispersion,
				services: [...state.dispersion.services, service]
			}
		})),
	deleteServiceSelected: (serviceId) =>
		set((state) => ({
			dispersion: {
				...state.dispersion,
				services: state.dispersion.services.filter((service) => service.id !== serviceId)
			}
		})),
	setServiceQuantity: (serviceId, quantity) =>
		set((state) => ({
			dispersion: {
				...state.dispersion,
				services: state.dispersion.services.map((service) => {
					if (service.id === serviceId) {
						return {
							...service,
							original_quantity: quantity
						};
					}
					return service;
				})
			}
		})),
	setServicePrice: (serviceId, price) =>
		set((state) => ({
			dispersion: {
				...state.dispersion,
				services: state.dispersion.services.map((service) => {
					if (service.id === serviceId) {
						return {
							...service,
							price
						};
					}
					return service;
				})
			}
		})),
	setCustomerId: (customerId) =>
		set((state) => ({
			customerId
		})),
	setBusiness: (business) => {
		set((state) => ({
			dispersion: {
				...state.dispersion,
				business
			}
		}));
		if (!business) {
			set((_) => ({
				paymentType: undefined,
				paymentMethod: undefined,
				cfdiUse: undefined,
				cfdiUseDescription: undefined
			}));
		}
	},
	paymentMethod: undefined,
	setPaymentMethod: (paymentMethod) =>
		set((state) => ({
			paymentMethod
		})),
	paymentType: undefined,
	setPaymentType: (paymentType) =>
		set((state) => ({
			paymentType
		})),
	cfdiUse: undefined,
	setCfdiUse: (cfdiUse) =>
		set((state) => ({
			cfdiUse
		})),
	cfdiUseDescription: undefined,
	setCfdiDescription: (cfdiUseDescription) =>
		set((state) => ({
			cfdiUseDescription
		})),
	setTaxRegimen: (taxRegimen) =>
		set((state) => ({
			dispersion: {
				...state.dispersion,
				tax_regimen: taxRegimen
			}
		})),
	setCustomerServices: (customerServices) =>
		set((state) => ({
			customerServices
		})),
	setOrder: (order) =>
		set((state) => ({
			dispersion: {
				...state.dispersion,
				order
			}
		})),
	setId: (id) =>
		set((state) => ({
			dispersion: {
				...state.dispersion,
				id
			}
		})),
	setType: (type) =>
		set((state) => ({
			type: type
		})),
	setServicesChargesOptionList: (servicesCharges) =>
		set((state) => ({
			servicesChargesOptionList: servicesCharges
		})),
	setAdditionalCharges: (additionalCharges) =>
		set((state) => ({
			additionalCharges: additionalCharges
		})),
	addAdditionalCharge: (additionalCharge) =>
		set((state) => ({
			additionalCharges: [...(state.additionalCharges ?? []), additionalCharge]
		})),
	updateAdditionalCharge: ({
		chargeId,
		price,
		quantity,
		iva
	}: {
		chargeId: number;
		price: number;
		quantity: number;
		iva: number;
	}) =>
		set((state) => ({
			additionalCharges: state.additionalCharges?.map((charge) => {
				if (charge.id === chargeId) {
					return {
						...charge,
						price,
						quantity,
						iva
					};
				}
				return charge;
			})
		})),
	removeAdditionalCharge: (chargeId) =>
		set((state) => ({
			additionalCharges: state.additionalCharges?.filter((item) => item.id !== chargeId)
		}))
}));
