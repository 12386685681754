//Libraries
import { Grid, Typography } from '@mui/material';
//Styles
import { useStyles } from './useReporTable.styles';

const VeritcalBar = () => <div style={{ height: '20px', border: '1px solid #515151' }} />;

const ColumnsReportsConfig = () => {
	const classes = useStyles();
	return (
		<Grid
			container
			direction='row'
			alignItems='center'
			justifyContent='space-between'
			className={classes.container}
		>
			<Grid item className={classes.small}>
				<Typography className={classes.text}>Num.</Typography>
			</Grid>
			<VeritcalBar />

			<Grid item className={classes.medium}>
				<Typography className={classes.text}>Num. de Archivos</Typography>
			</Grid>
			<VeritcalBar />
			<Grid item className={classes.extraxBig}>
				<Typography className={classes.text}>Tipo de Reporte</Typography>
			</Grid>
			<VeritcalBar />
			<Grid item className={classes.big}>
				<Typography className={classes.text}>Corte</Typography>
			</Grid>
			<VeritcalBar />
			<Grid item className={classes.big}>
				<Typography className={classes.text}>Fecha</Typography>
			</Grid>
			<VeritcalBar />
			<Grid item className={classes.big}>
				<Typography className={classes.text}>Subtipo</Typography>
			</Grid>
			<VeritcalBar />
			<Grid item className={classes.big}>
				<Typography className={classes.text}>Días Rev.</Typography>
			</Grid>
			<VeritcalBar />
			<Grid item className={classes.big}>
				<Typography className={classes.text}>Días Prefac.</Typography>
			</Grid>
		</Grid>
	);
};

export default ColumnsReportsConfig;
