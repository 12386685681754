import React, { useEffect, useRef, useMemo } from 'react';
import clsx from 'clsx';
import { Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Control, FieldErrors } from 'react-hook-form';
import usePermissions from '../../../hooks/usePermissions';
import { useCardStyles } from '../../../hooks/useStyles';
import SignUpForm from '../SignUpForm';
import IOSSwitch from '~components/Buttons/IOSSwitch';
import { useLocation } from 'react-router-dom';
import { HeadingOne } from '~components/Headings';
import { ErrorOption } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: theme.spacing(3),
		width: '100%',
		marginTop: theme.spacing(4)
	}
}));

interface Props {
	control: Control;
	errors: FieldErrors;
	initialValues: any;
	customID: string;
	oldBussinesName: string;
	oldEmail: string;
	responseError: string;
	disableSpecial: boolean;
	blocked: boolean;
	handleStatus?: () => void;
	setError?: (name: string, error: ErrorOption) => void;
	clearErrors?: (name?: string | string[] | undefined) => void;
	isProfile?: boolean;
}

/**
 * Client form
 */
const ClientForm: React.FC<Props> = ({
	control,
	errors,
	initialValues,
	customID,
	oldBussinesName,
	oldEmail,
	responseError,
	disableSpecial,
	blocked,
	handleStatus,
	setError,
	clearErrors,
	isProfile
}) => {
	const classes = useStyles();
	const { cardClasses } = useCardStyles();
	const infoRef = useRef<HTMLElement | null>(null);
	const { isRoot } = usePermissions();
	const location = useLocation();

	const isProspectPage = useMemo(() => location.pathname.includes('prospecto'), [location]);

	useEffect(() => {
		if (
			errors.cfdi ||
			errors.agents ||
			errors.business_name ||
			errors.business_type ||
			errors.channel ||
			errors.email ||
			errors.enterprise_operations ||
			errors.last_name ||
			errors.name ||
			errors.phone_number
		) {
			infoRef.current?.scrollIntoView({ behavior: 'smooth' });
		}
	}, [errors]);

	return (
		<Grid
			ref={(instance) => (infoRef.current = instance)}
			container
			className={clsx(cardClasses.card, classes.container)}
			style={{
				alignItems: 'center',
				marginTop: 0,
				borderTopLeftRadius: 0,
				borderTopRightRadius: 0
			}}
		>
			<Grid item container spacing={3}>
				<Grid item container alignItems='center'>
					<Grid item xs={isRoot && !isProspectPage ? 4 : 6}>
						<HeadingOne text='Datos Generales' />
					</Grid>
					{!isProfile && (
						<Grid item xs={isRoot && !isProspectPage ? 4 : 6}>
							<Typography align='right' component='p'>
								{customID ? `ID: ${customID}` : ''}
							</Typography>
						</Grid>
					)}
					{isRoot && !isProspectPage && (
						<Grid item xs={4}>
							<Typography align='right' component='p'>
								Bloquear Cliente
								<IOSSwitch checked={blocked} onChange={handleStatus} useRed={true} />
							</Typography>
						</Grid>
					)}
				</Grid>
				<SignUpForm
					initialValues={initialValues}
					control={control}
					errors={errors}
					oldBussinesName={oldBussinesName}
					oldEmail={oldEmail}
					responseError={responseError}
					disableSpecial={disableSpecial}
					clearErrors={clearErrors}
					setError={setError}
					isProfile={isProfile}
				/>
			</Grid>
		</Grid>
	);
};

export default ClientForm;
