import { useEffect, useMemo } from 'react';
//Libraries
import { Grid, Typography, TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { shallow } from 'zustand/shallow';
//Styles
import { useStyles } from './useReporTable.styles';
//Hooks
import usePermissions from '~hooks/usePermissions';
import useOptions from './hooks/useOptions';
import { subtypeOptions, cutOptions, reviewOptions, billingOptions } from './options';
//Store
import useReportStore from '~store/ReportStore';
import capitalize from '~util/capitalize';
import { FieldErrors } from 'react-hook-form';
import DropdownPaper from '~components/Forms/DropdownPaper';
import { ReportItem, ReportNumber, ReportSubType, SchemaType } from '~store/actions/ActionTypes';

interface Props {
	control: Control;
	initialValues?: ReportItem;
	errors: FieldErrors;
	setValue?: (key: string, value: string) => void;
}

const GuidesRow = ({ control, initialValues, errors, setValue }: Props) => {
	const classes = useStyles();

	const { isRoot } = usePermissions();
	const { dateOptions, handleDateOptions, handleSubtypes, setSubtype } = useOptions({
		setValue
	});

	const [setGuidesCutoffDate, guidesCutoffDate, insuranceCutoffDate, overweightCutoffDate] =
		useReportStore(
			(state) => [
				state.setGuidesCutoffDate,
				state.guidesCutoffDate,
				state.insuranceCutoffDate,
				state.overweightCutoffDate
			],
			shallow
		);

	const hasFilledValues = useMemo(
		() => !!insuranceCutoffDate || !!guidesCutoffDate || !!overweightCutoffDate,
		[insuranceCutoffDate, guidesCutoffDate, overweightCutoffDate]
	);

	useEffect(() => {
		setValue && setValue('guides_subtype', '');
		handleSubtypes(guidesCutoffDate, 'guides', initialValues!);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [guidesCutoffDate, handleSubtypes, initialValues]);

	useEffect(() => {
		setGuidesCutoffDate(initialValues?.schema || '');
	}, [initialValues?.schema, setGuidesCutoffDate]);

	useEffect(() => {
		handleDateOptions(guidesCutoffDate);
	}, [guidesCutoffDate, handleDateOptions]);

	useEffect(() => {
		if (initialValues) {
			setValue && setValue('guides_date', initialValues?.date ?? '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Grid
			container
			direction='row'
			alignItems='center'
			justifyContent='space-between'
			className={classes.container}
		>
			{/* Num. Rep. */}
			<Grid item className={classes.small}>
				<Controller
					render={() => (
						<TextField
							disabled
							variant='filled'
							fullWidth
							autoComplete='none'
							value={ReportNumber.ONE}
						/>
					)}
					name='guides_reportNumber'
					control={control}
					defaultValue={ReportNumber.ONE}
				/>
			</Grid>

			{/* Reports number of files */}
			<Grid item className={classes.medium}></Grid>

			{/* Report Type */}
			<Grid item className={classes.extraxBig}>
				<Typography className={classes.text}>Guías</Typography>
			</Grid>

			{/* Cutoff day schema*/}
			<Grid item className={classes.big}>
				<Controller
					name='guides_schema'
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							options={cutOptions}
							disabled={!isRoot}
							getOptionLabel={(option) => capitalize(option.title) || ''}
							getOptionSelected={(option, value) => !!value?.title && option.title === value?.title}
							onChange={(_, data) => {
								onChange(data);
								setGuidesCutoffDate(data?.title);
								if (!setValue) return;
								setValue('guides_date', '');
								setValue('guides_subtype', '');
							}}
							renderInput={(params) => {
								return (
									<TextField
										{...params}
										label='Corte'
										variant='filled'
										error={!!errors?.guides_schema}
									/>
								);
							}}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					control={control}
					defaultValue={initialValues?.schema ? { title: initialValues?.schema } : null}
					rules={{ required: isRoot && hasFilledValues }}
				/>
			</Grid>

			{/* Cutoff date */}
			<Grid item className={classes.big}>
				<Controller
					name='guides_date'
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							options={dateOptions}
							disabled={
								!isRoot ||
								guidesCutoffDate === SchemaType.IMMEDIATE ||
								guidesCutoffDate === SchemaType.INDEPENDENT
							}
							getOptionLabel={(option) => capitalize(option) || ''}
							getOptionSelected={(option, value) => option === value}
							onChange={(_, data) => {
								onChange(data);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Fecha'
									variant='filled'
									error={!!errors?.guides_date}
								/>
							)}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					control={control}
					defaultValue={initialValues?.date}
					rules={{
						required:
							isRoot &&
							hasFilledValues &&
							guidesCutoffDate !== SchemaType.IMMEDIATE &&
							guidesCutoffDate !== SchemaType.INDEPENDENT
					}}
				/>
			</Grid>

			{/* Subtype */}
			<Grid item className={classes.big}>
				<Controller
					name='guides_subtype'
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							options={
								guidesCutoffDate === SchemaType.IMMEDIATE ||
								guidesCutoffDate === SchemaType.BIWEEKLY ||
								guidesCutoffDate === SchemaType.WEEKLY
									? [
											guidesCutoffDate === SchemaType.IMMEDIATE
												? ''
												: guidesCutoffDate === SchemaType.BIWEEKLY
												? ReportSubType.WEEK
												: ReportSubType.BIWEEK
									  ]
									: subtypeOptions.filter(
											(item) => item !== ReportSubType.BIWEEK && item !== ReportSubType.WEEK
									  )
							}
							disabled={!isRoot || guidesCutoffDate !== SchemaType.MONTHLY}
							getOptionLabel={(option) => capitalize(option) || ''}
							getOptionSelected={(option, value) => option === value}
							onChange={(_, data) => {
								onChange(data);
								setSubtype(data ?? '');
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Subtipo'
									variant='filled'
									error={!!errors?.guides_subtype}
								/>
							)}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					rules={
						isRoot && hasFilledValues && guidesCutoffDate === SchemaType.MONTHLY
							? { required: true }
							: {}
					}
					control={control}
					defaultValue={initialValues?.sub_type}
				/>
			</Grid>

			{/* Review days */}
			<Grid item className={classes.big}>
				<Controller
					name='guides_conditions_report'
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							options={reviewOptions}
							disabled={!isRoot}
							getOptionLabel={(option) => option.title || ''}
							getOptionSelected={(option, value) => !!value?.title && option.title === value?.title}
							onChange={(_, data) => {
								onChange(data);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Días de revisión'
									variant='filled'
									error={!!errors?.guides_conditions_report}
								/>
							)}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					control={control}
					defaultValue={initialValues?.conditions ? { title: initialValues?.conditions } : null}
					rules={{ required: isRoot && hasFilledValues }}
				/>
			</Grid>

			{/* Billing */}
			<Grid item className={classes.big}>
				<Controller
					name='guides_pre_invoice_days'
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							options={billingOptions}
							disabled={!isRoot}
							getOptionLabel={(option) => option.title || ''}
							getOptionSelected={(option, value) => !!value?.title && option.title === value?.title}
							onChange={(_, data) => {
								onChange(data);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Días prefactura'
									variant='filled'
									error={!!errors?.guides_pre_invoice_days}
								/>
							)}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					control={control}
					defaultValue={
						initialValues?.pre_invoice_days ? { title: initialValues?.pre_invoice_days } : null
					}
					rules={{ required: isRoot && hasFilledValues }}
				/>
			</Grid>
		</Grid>
	);
};

export default GuidesRow;
