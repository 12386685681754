import { useState, useEffect, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import axiosInstance, { instanceRate } from '~util/axios';
import axios from 'axios';
import { initFlow, resetFlow } from '~store/actions';
import { enterpriseOperationsOptions } from '~components/Inputs/EnterpriseOperations';
import { CARRIERS, getCarrierName } from '~util/logos';
import usePermissions from '~hooks/usePermissions';
import { useParams } from 'react-router';
import DrawContext from '../../../context/DrawContext';
import {
	BookletServiceForm,
	CustomerDispersionType,
	CustomerResponse,
	ReportFileType,
	ReportItem,
	ReportName,
	SellerResponse
} from '~store/actions/ActionTypes';
import { setServicesCUCustomer, setSimulatedCustomer } from '~store/actions/agents';
import { AxiosResponse } from 'axios';
import { channelOptions } from '~components/Inputs/ChannelInput';
import useQuery from '~hooks/useQuery';
import { useSelector } from '~store/store';
import { shallow } from 'zustand/shallow';
import { getRateCurrentYear } from '~util/rateYear';

export type ServiceDataItem = {
	id: number;
	enabled: boolean;
	discount: number;
	min_stock: number;
	price_list: number;
	irregular_delivery_price?: number;
	reissue_price?: number;
	insurance_percentage?: number;
};

export type CustomerFormValues = {
	// General Info
	name: string;
	last_name: string;
	email: string;
	phone_number: string;
	business_name: string;
	company_type: { title: string; value: string };
	enterprise_operations: { title: string; value: string };
	channel: { title: string; value: string };
	webpage: string;
	validation_status: string;
	// Address
	city: string;
	external_number: string;
	internal_number: string;
	neighborhood: string;
	state: string;
	street: string;
	zip_code: string;

	// Fiscal Info
	modality: string;
	conditions: string;
	billing: string;
	review_reports: string;
	dispersion_type: string;

	// Report
	reports: [];

	guides_schema: { title: string } | string;
	guides_subtype: string;
	guides_date: string;
	guides_conditions_report: { title: string } | string;
	guides_pre_invoice_days: { title: string } | string;

	// overweight_schema: string;
	overweight_subtype: string;
	overweight_date: string;
	overweight_conditions_report: { title: string } | string;
	overweight_pre_invoice_days: { title: string } | string;
	overweight_name: { title: string; value: ReportName };

	// insurance_schema: string;
	insurance_subtype: string;
	insurance_date: string;
	insurance_conditions_report: { title: string } | string;
	insurance_pre_invoice_days: { title: string } | string;

	report_file_type?: string;

	seller: SellerResponse;

	add_services_to_users?: boolean;
	business_type: string;
};

export const DISPERSION_TYPE_REF = [
	{ label: 'Global', value: CustomerDispersionType.GLOBAL },
	{ label: 'Restrictivo', value: CustomerDispersionType.RESTRICTED }
];

const useCustomerForm = (fromDispersion?: boolean) => {
	const dispatch = useDispatch();
	const { id } = useParams<{ id?: string }>();
	const history = useHistory();
	const [editing, setEditing] = useState(true);
	const [loading, setLoading] = useState(true);
	const [posting, setPosting] = useState(false);
	const [customer, setCustomer] = useState<CustomerResponse | undefined>();
	const [responseError, setResponseError] = useState<null | string>(null);

	const { isCustomer, isRoot } = usePermissions();
	const query = useQuery();

	const { setNotification, setRCardStatus } = useContext(DrawContext);
	const { servicesInfoDraftCUCustomer } = useSelector((state) => state.cuCustomer, shallow);
	const { simulatedCustomer } = useSelector((state) => state.simulatedUser);

	// Hide the card
	useEffect(() => {
		setRCardStatus('HIDDEN');
	}, [setRCardStatus]);

	const fetchCustomer = useCallback(async () => {
		if (id) {
			setEditing(true);
			try {
				const { data } = await axiosInstance.get(`/api/customer/${id}`);
				const customer = data.contact as any;
				// data.company_type = enterpriseTypeOptions.find((et) => et.value === data.company_type);
				data.enterprise_operations = enterpriseOperationsOptions.find(
					(eo) => eo.value === data.set_of_operations
				);
				data.name = customer.name;
				data.last_name = customer.last_name;
				data.email = customer.email;
				// data.operation_description = data.description_of_operation;
				data.channel = channelOptions.find((channel) => channel.value === data.channel);

				data.dispersion_type = DISPERSION_TYPE_REF.find(
					(item) => item.value === data?.dispersion_type
				)?.label;
				setCustomer(data as CustomerResponse);

				dispatch(
					setServicesCUCustomer(
						data.services.map((s: any) => ({ ...s, carrier: getCarrierName(s.carrier) }))
					)
				);
			} catch (err) {
				console.error('Error while fetching customer', err);
				setNotification({
					message: 'No se puedo obtener la información, asegurese que exista o tenga los permisos',
					severity: 'error'
				});
				history.goBack();
			}
		} else {
			setEditing(false);
			dispatch(setServicesCUCustomer([]));
		}
		dispatch(initFlow('cucustomer'));
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, history]);

	// Fetch when is editing
	useEffect(() => {
		fetchCustomer();
	}, [fetchCustomer]);

	const getNewServices = (onlyEstafeta?: boolean) => {
		const previousServicesIds = customer?.services.map((item) => item.id.toString());

		return servicesInfoDraftCUCustomer.filter(
			(item) =>
				!previousServicesIds?.includes(
					typeof item.id === 'number' ? item.id.toString() : item.id
				) && (onlyEstafeta ? item.carrier?.toLowerCase() === CARRIERS.ESTAFETA : true)
		);
	};

	const saveRates = useCallback(
		async (customerId: string) => {
			const estafetaNewServices = getNewServices(true);
			const toSavedService: BookletServiceForm[] = estafetaNewServices?.filter(
				(item) => !item.name.includes('2 Dias')
			);
			estafetaNewServices?.forEach(
				(service) =>
					!toSavedService?.find((item) => item.rates_service_description === service.rates_service_description) &&
					toSavedService.push(service)
			);

			await Promise.all(
				toSavedService?.map(async (service: any) => {
					const year = getRateCurrentYear();
					const payload: any = {
						customer_id: parseInt(customerId),
						year,
						proposal_id: service?.proposal_id,
						volume: service?.volume
					};
					try {
						await instanceRate.post('/rates', payload);
					} catch (err) {
						console.error(err);
					}
				})
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[servicesInfoDraftCUCustomer]
	);

	const save = useCallback(
		async (form: any) => {
			form.reports = form.reports?.map((r: { schema: string; [key: string]: number | string }) => ({
				...r,
				schema: r.schema?.toLowerCase()
			}));

			let success = true;
			let customerId;

			setPosting(true);
			let response: Promise<AxiosResponse<any>>;
			// Then we want to update
			if (id) {
				response = axiosInstance.put(`/api/customer/${id}`, form);
			} else {
				response = axiosInstance.post('/api/customer', form);
			}

			try {
				const { data } = await response;
				customerId = id ?? data?.id;

				if (isCustomer) {
					setPosting(false);
					setNotification({
						message: 'Se actulizó la información correctamente',
						severity: 'success'
					});
				} else {
					setNotification({
						message: id
							? 'Se guardaron los cambios correctamente'
							: 'Se creó el cliente correctamente',
						severity: 'success'
					});

					if (simulatedCustomer?.id === data?.id) {
						dispatch(setSimulatedCustomer(data));
					}

					// Save customer and return to customer table
					dispatch(resetFlow());
					dispatch(setServicesCUCustomer([]));
					history.push('/clientes');
				}
			} catch (err: any) {
				let message = 'Hubo un error crear al cliente';
				if (axios.isAxiosError(err)) {
					if (err?.response?.data?.description) {
						if (err.response?.data.description.includes('User')) {
							message = 'Ya existe un usuario con el correo especificado';
							setResponseError('USER_ALREADY_EXISTS');
						} else if (err.response?.data.description.includes('Customer')) {
							message = 'Ya existe un cliente con el nombre de empresa especificado';
							setResponseError('CUSTOMER_ALREADY_EXISTS');
						} else if (
							err.response?.data.description.some((item: { [key: string]: string }) =>
								Object.keys(item).some((item) => item.includes('report'))
							)
						) {
							const messages = err.response?.data.description
								.map((item: { [key: string]: string }) => Object.values(item))
								.flat();
							message = messages?.includes('Must be separate file when schema is different')
								? 'Debe ser un archivo separado cuando el esquema es diferente'
								: 'Hay un error en los valores de reportes';
						}
					}
				}
				success = false;
				setNotification({
					message: message,
					severity: 'error'
				});
				setPosting(false);
			}

			return { success, customerId };
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			id,
			dispatch,
			isCustomer,
			setNotification,
			history,
			customer,
			servicesInfoDraftCUCustomer,
			query,
			fromDispersion,
			simulatedCustomer?.id
		]
	);

	const getPayloadServices = useCallback(() => {
		return servicesInfoDraftCUCustomer.map((s) => {
			const payloadServices = {
				id: s.id,
				enabled: s.enabled,
				min_stock: s.min_stock,
				price_list: s.price_list,
				discount: s.discount
			} as ServiceDataItem;
			if (!!s.reissue_price) {
				payloadServices.reissue_price = s.reissue_price;
			}
			if (!!s.irregular_delivery_price) {
				payloadServices.irregular_delivery_price = s.irregular_delivery_price;
			}
			if (!!s.insurance_percentage) {
				payloadServices.insurance_percentage = s.insurance_percentage;
			}
			return payloadServices;
		});
	}, [servicesInfoDraftCUCustomer]);

	const formatPayload = useCallback(
		({
			data,
			guidesRowOfReport,
			overweightRowOdReport,
			insuranceRowOfReport,
			status
		}: {
			data: CustomerFormValues;
			guidesRowOfReport?: ReportItem;
			overweightRowOdReport?: ReportItem;
			insuranceRowOfReport?: ReportItem;
			status?: string;
		}) => {
			const payload: { [key: string]: any } = {
				// General Info
				name: data.name.trim(),
				last_name: data.last_name.trim(),
				email: data.email.trim(),
				phone_number: data.phone_number.trim(),
				business_name: data.business_name.toUpperCase().trim(),
				company_type: data.company_type?.value,
				set_of_operations: data.enterprise_operations.value,
				webpage: data.webpage || undefined,
				validation_status: data?.validation_status?.toLowerCase(),
				business_type: data?.business_type
			};

			//Status
			if (status) {
				payload.status = status;
			}

			if (data.zip_code && data.neighborhood && data.city && data.state && data.street) {
				// Address
				payload.business_address = {
					zip_code: data.zip_code,
					neighborhood: data.neighborhood,
					city: data.city,
					state: data.state,
					street: data.street,
					number: data.external_number,
					suite_number: data.internal_number,
					country: 'México'
				};
			}

			if (data.channel) {
				payload.channel = data.channel.value;
			}

			if (data.modality) {
				payload.modality = data.modality.toLowerCase();
			}

			if (data.conditions) {
				payload.conditions = data.conditions;
			}

			if (data.billing) {
				payload.billing = data.billing.toLowerCase();
			}

			// if (data.review_reports) {
			// 	payload.review_reports = data.review_reports;
			// }

			payload.required_fiscal_information = false;

			// Type of dispersion: global or restrictive
			payload.dispersion_type = DISPERSION_TYPE_REF.find(
				(item) => item.label === data?.dispersion_type
			)?.value;

			// Same file config: same values
			const hasSameFileSet = data.report_file_type === ReportFileType.SAME_FILE;
			//Reports formatted data
			payload.reports = [
				{ ...guidesRowOfReport, report_file_type: data.report_file_type },
				{
					...overweightRowOdReport,
					report_file_type: data.report_file_type,
					pre_invoice_days: !hasSameFileSet
						? overweightRowOdReport?.pre_invoice_days
						: guidesRowOfReport?.pre_invoice_days,
					conditions: !hasSameFileSet
						? overweightRowOdReport?.conditions
						: guidesRowOfReport?.conditions
				},
				{
					...insuranceRowOfReport,
					report_file_type: data.report_file_type,
					pre_invoice_days: !hasSameFileSet
						? insuranceRowOfReport?.pre_invoice_days
						: guidesRowOfReport?.pre_invoice_days,
					conditions: !hasSameFileSet
						? insuranceRowOfReport?.conditions
						: guidesRowOfReport?.conditions
				}
			];

			payload.report_file_type = data.report_file_type;

			if (
				!payload.reports?.every(
					(item: any) => item.schema && item.pre_invoice_days && item.conditions
				)
			) {
				delete payload.reports;
			}

			payload.services = getPayloadServices();

			if (isRoot) {
				payload.seller_id = data.seller?.id;
			}

			return payload;
		},
		[getPayloadServices, isRoot]
	);

	return {
		save,
		editing,
		customer,
		posting,
		responseError,
		loading,
		getPayloadServices,
		formatPayload,
		saveRates,
		getNewServices
	};
};

export default useCustomerForm;
