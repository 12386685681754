import usePagination from './usePagination';
import { Package } from '../store/actions/ActionTypes';
import { Items } from '../util/pagination';
import usePermissions from './usePermissions';

export default function useInputSearchPackages(limit = Items.InputSearch) {
	const { isRootOrAgent, can } = usePermissions();
	const { data: packages } = usePagination<Package>({
		url: '/api/items',
		limit: limit,
		param: 'page',
		queryParams: isRootOrAgent ? ['user_id'] : [],
		canHaveActions: can('canRead.packages')
	});

	return {
		data: packages
	};
}
