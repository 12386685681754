import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import 'react-phone-number-input/style.css';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { ErrorOption, useWatch } from 'react-hook-form';

interface Props {
	disabled?: boolean;
	control: Control;
	errors: FieldErrors;
	name: string;
	defaultValue?: string;
	required?: boolean;
	setError?: (name: string, error: ErrorOption) => void;
	clearErrors?: (name?: string | string[] | undefined) => void;
	error?: Record<string, string>;
}

const useStyles = makeStyles((theme) => ({
	inputContainer: {
		backgroundColor: '#353535',
		borderTopLeftRadius: '8px',
		borderTopRightRadius: '8px',
		'& div': {
			backgroundColor: '#353535',
			borderTopLeftRadius: '8px',
			borderTopRightRadius: '8px',
			transition: 'none',
			'& > input': {
				backgroundColor: '#353535 !important',
				color: '#fff !important',
				fontFamily: 'Roboto !important',
				fontSize: '14px !important',
				padding: '30px 12px 10px',
				border: 'none',
				borderBottom: '1px solid #c5c5c5',
				borderTopRightRadius: '8px',
				'&:focus': {
					borderBottom: '1px solid #03b2cb',
					outline: 'none'
				}
			}
		},
		'& > div > div:nth-child(1)': {
			'& > div:nth-child(2)': {
				borderRadius: 0
			}
		},
		'& .PhoneInputCountry': {
			paddingLeft: theme.spacing(2),
			borderBottom: '1px solid #c5c5c5',
			marginRight: 0,
			'& > .PhoneInputCountryIcon': {
				borderRadious: '0 !important'
			}
		},
		'& .PhoneInput--disabled': {
			opacity: 0.5
		}
	}
}));

const PhoneNumberInput: React.FC<Props> = ({
	disabled,
	errors,
	defaultValue,
	control,
	name,
	required = true,
	setError,
	clearErrors,
	error
}) => {
	const classes = useStyles();
	const phoneNumber: string | undefined = useWatch({
		control,
		name,
		defaultValue
	});
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	useEffect(() => {
		if (!required) {
			clearErrors && clearErrors(name);
			setErrorMessage(null);
			return;
		}
		if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
			setError &&
				setError(name, {
					type: 'custom',
					message: 'El teléfono es inválido.'
				});
			setErrorMessage('El teléfono es inválido.');
		} else if (!!errors[name] && errors[name]?.type !== 'custom') {
			setError &&
				setError(name, {
					type: 'custom',
					message: errors[name]?.message
				});
			setErrorMessage(errors[name]?.message);
		} else if (!!error) {
			setError &&
				setError(name, {
					type: 'custom',
					message: error?.message
				});
			setErrorMessage(error?.message);
		} else {
			clearErrors && clearErrors(name);
			setErrorMessage(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [phoneNumber, errors, name, required]);

	const label = useMemo(() => `Teléfono${required ? ' *' : ''}`, [required]);

	const phoneRef: MutableRefObject<any> = useRef(null);

	useEffect(() => {
		!!phoneRef.current && phoneRef.current?.blur();
	}, [phoneRef]);

	return (
		<div>
			<div className={classes.inputContainer}>
				<Controller
					name={name}
					rules={{
						required: {
							value: required,
							message: 'Ingrese su Teléfono'
						}
					}}
					error={Boolean(!disabled && errorMessage)}
					render={({ onChange, ...props }) => (
						<PhoneInputWithCountrySelect
							{...props}
							ref={phoneRef}
							disabled={disabled}
							variant='filled'
							defaultCountry='MX'
							label={label}
							onChange={(value) => {
								onChange(value);
							}}
						/>
					)}
					control={control}
					defaultValue={defaultValue}
				/>
			</div>
			{!disabled && !!errorMessage && (
				<Typography color='error' variant='caption'>
					{errorMessage}
				</Typography>
			)}
		</div>
	);
};

export default PhoneNumberInput;
