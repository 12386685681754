import { CircularProgress, Grid } from '@mui/material';
import React, { useState, useCallback, useContext, useEffect } from 'react';
import ClientForm from '~components/Forms/Client/ClientForm';
import AuthContext from '~context/AuthContext';
import { CustomerDetails } from '~store/actions/ActionTypes';
import axiosInstance from '~util/axios';
import { useForm } from 'react-hook-form';
import { useStyles } from './styles';
import ClientFiscalForm from '~components/Forms/Client/ClientFiscalForm';

const ProfileData = () => {
	const classes = useStyles();

	const { currentUser } = useContext(AuthContext);

	const [customer, setCustomer] = useState<CustomerDetails>();
	const { control, errors } = useForm();

	const getCustomer = useCallback(async (id: number) => {
		try {
			const { data } = await axiosInstance.get(`/api/customer/${id}`);
			setCustomer(data);
		} catch (error) {
			// setNotification({
			// 	severity: 'error',
			// 	message: 'Hubo un error',
			// 	time: 3000
			// });
		}
	}, []);

	useEffect(() => {
		if (currentUser?.customer?.id) {
			getCustomer(currentUser?.customer?.id);
		}
	}, [currentUser, getCustomer]);

	if (!customer) {
		return (
			<Grid container justifyContent='center' alignItems='center' style={{ minHeight: '400px' }}>
				<CircularProgress />
			</Grid>
		);
	}

	return (
		<Grid container className={classes.dataContainer}>
			{/* Datos generales */}
			<Grid item xs={12}>
				<ClientForm
					customID={
						customer?.id
							? typeof customer?.id === 'number'
								? customer?.id?.toString()
								: (customer?.id as string)
							: ''
					}
					initialValues={
						customer.phone_number?.length === 10
							? { ...customer, phone_number: `+52${customer.phone_number}` }
							: customer
					}
					oldBussinesName={''}
					oldEmail={''}
					responseError={''}
					control={control}
					errors={{}}
					disableSpecial={false}
					blocked={false}
					isProfile={true}
				/>{' '}
			</Grid>

			{/* Facturacion */}
			<Grid item xs={12}>
				<ClientFiscalForm
					initialValues={customer}
					control={control}
					errors={errors}
					isProfile={true}
				/>
			</Grid>
		</Grid>
	);
};

export default ProfileData;
