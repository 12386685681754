import { GridColDef, GridCellParams } from '@mui/x-data-grid';
import MenuActionButton from '../../../components/Tables/_MenuActionsButton';
import StatusCell from '~components/Tables/_StatusCell';
import { ProspectValidationStatus } from '~store/actions/ActionTypes';

type CustomColumns = {
	onEdit: (customer_id: number) => void;
	onDelete?: (id: number) => void;
};

type StatusLabel = {
	[key: string]: string;
};

export const STATUS_LABELS: StatusLabel = {
	[ProspectValidationStatus.PROSPECTING]: 'Prospección',
	[ProspectValidationStatus.VALIDATED]: 'Validado',
	[ProspectValidationStatus.DOCUMENTATION]: 'Documentación',
	[ProspectValidationStatus.REGISTER]: 'Registro',
	// TODO:
	validado_pero_pendiente_tarifas: 'Tarifas Pendientes'
};

export default function customerTableColumns({ onEdit, onDelete }: CustomColumns): GridColDef[] {
	const columns: GridColDef[] = [
		{
			field: 'Id',
			headerName: 'ID',
			flex: 0.5,
			renderCell: (params) => {
				return <p>{params.row.prospect_id}</p>;
			}
		},
		{
			field: 'date',
			headerName: 'Fecha',
			flex: 0.5,
			renderCell: (params) => {
				return <p>{params.row.created_at}</p>;
			}
		},
		{
			field: 'email',
			headerName: 'Email',
			flex: 1,
			renderCell: (params) => {
				return <p>{params.row.contact.email}</p>;
			}
		},
		{
			field: 'name',
			headerName: 'Nombre',
			flex: 1,
			renderCell: (params) => {
				return <p>{params.row.contact.name}</p>;
			}
		},
		{
			field: 'phone',
			headerName: 'Teléfono',
			flex: 1,
			renderCell: (params) => {
				return <p>{params.row.phone_number}</p>;
			}
		},
		{
			field: 'status',
			headerName: 'Estatus',
			flex: 0.75,
			renderCell: (params) => {
				const buttonBackground =
					params.row.validation_status === ProspectValidationStatus.DOCUMENTATION
						? '#409cff'
						: params.row.validation_status === ProspectValidationStatus.DOCUMENTATION
						? '#57BBD8'
						: 'dimgray';
				return (
					<StatusCell
						status={STATUS_LABELS[params.row.validation_status as keyof StatusLabel]}
						backgroundColor={buttonBackground}
					/>
				);
			}
		},
		{
			field: 'actions',
			headerName: 'Acciones',
			width: 120,
			sortable: false,
			renderCell: (params: GridCellParams) => {
				const menuProps: any = {};
				if (onEdit) menuProps.onEdit = () => onEdit(params.row.id);
				//if (onDelete) menuProps.onDelete = () => onDelete(params.id as number);

				return (
					<MenuActionButton
						title='¿Esta seguto de eliminar este cliente?'
						body='Esta acción no se puede deshacer'
						showAlert={true}
						{...menuProps}
					/>
				);
			}
		}
	];

	return columns;
}
