// Libraries
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
// Components
import { HeadingOne, TextTwo } from '~components/Headings';
// import LabelContainer from '~components/Modals/_LabelContainer';
// import { PDF } from '~pages/profile/MOCKUP';

// Hooks
import { radiusCard } from '~hooks/useStyles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		backgroundColor: theme.palette.background.paper,
		borderRadius: `0 0 ${radiusCard}px ${radiusCard}px !important`,
		padding: theme.spacing(3)
		// '&>h4': {
		// 	margin: `${theme.spacing(2)} 0 ${theme.spacing(2)} ${theme.spacing(2)}`,
		// 	width: '100%',
		// 	textAlign: 'left'
		// }
	}
}));

const ProfileContract = () => {
	const classes = useStyles();
	return (
		<Grid container alignItems='center' justifyContent='center' className={classes.container}>
			<Grid container alignItems='center' justifyContent='center'>
				<Grid item xs={12} container justifyContent='space-between' alignItems='flex-start'>
					<HeadingOne text='Contrato' />
				</Grid>

				<Grid container justifyContent='center' alignItems='center' sx={{ minHeight: '200px' }}>
					<TextTwo text='Sin información' />
					{/* <Grid item xs={12}>
						<LabelContainer pdf={PDF} />
					</Grid> */}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ProfileContract;
