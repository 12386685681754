import React, { useMemo, useRef } from 'react';
import { Grid, TextField } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Controller, Control, FieldErrors, useWatch } from 'react-hook-form';
import clsx from 'clsx';
import { useCardStyles } from '~hooks/useStyles';
import { HeadingOne } from '~components/Headings';
import { Autocomplete } from '@material-ui/lab';
import DropdownPaper from '../DropdownPaper';
import { PaymentConditions } from '~store/actions/ActionTypes';
import capitalize from '~util/capitalize';
import { shallow } from 'zustand/shallow';
import { useSelector } from '~store/store';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: theme.spacing(3),
		width: '100%',
		marginTop: theme.spacing(4)
	},
	spaceWithCollapse: {
		marginTop: theme.spacing(2)
	}
}));

interface Props {
	control: Control;
	errors: FieldErrors;
	initialValues: any;
	clearSchema?: () => void;
	isProfile?: boolean;
	setValue?: (name: string, value: any) => void;
}

const MODALITY_OPTIONS = {
	TRACKING: 'Seguimiento',
	INDEPENDENT: 'Independiente',
	SC: 'SC'
};

const DISPERSION_TYPE_OPTIONS = ['Global', 'Restrictivo'];
const BILLING_OPTIONS = ['Inmediata', 'Reportes'];

/**
 * This form is used to create or update a Customer by an Agent,
 * it is imported in CreateOrUpdateCustomer
 */

const ClientFiscalForm: React.FC<Props> = ({
	control,
	errors,
	initialValues,
	clearSchema,
	isProfile,
	setValue
}) => {
	const classes = useStyles();
	const { cardClasses } = useCardStyles();

	const { servicesInfoDraftCUCustomer } = useSelector((state) => state.cuCustomer, shallow);

	const hasPrepaid = useMemo(
		() =>
			servicesInfoDraftCUCustomer?.some((item) =>
				(item?.name ?? item?.service)?.includes('Prepago')
			),
		[servicesInfoDraftCUCustomer]
	);

	const infoRef = useRef<HTMLElement | null>(null);

	const modality = useWatch({
		name: 'modality',
		control,
		defaultValue: initialValues?.modality || ''
	});

	return (
		<Grid
			ref={(instance) => (infoRef.current = instance)}
			container
			className={clsx(cardClasses.card, classes.container)}
		>
			{/* Title */}
			<Grid item xs={12} sm={6} style={{ padding: 0 }}>
				<HeadingOne text='Esquema' />
			</Grid>

			{/* Info outside the Collapse */}
			<Grid container className={classes.spaceWithCollapse} spacing={2}>
				{/* Modalidad */}
				<Grid item xs={12} sm={hasPrepaid ? 3 : 4}>
					<Controller
						render={({ onChange, ...propsown }) => (
							<Autocomplete
								{...propsown}
								disabled={isProfile}
								openOnFocus
								options={Object.values(MODALITY_OPTIONS)}
								getOptionLabel={(option) => capitalize(option) ?? ''}
								getOptionSelected={(option, value) => value && option === value}
								onChange={(_, data) => {
									onChange(data);
									if (data === MODALITY_OPTIONS.SC && setValue) {
										setValue('conditions', PaymentConditions.CASH);
										setValue('conditions', PaymentConditions.CASH);
									}
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Modalidad&nbsp;'
										variant='filled'
										error={Boolean(errors?.modality)}
										//helperText={}
									/>
								)}
								PaperComponent={(paperProps) => (
									<DropdownPaper>{paperProps.children}</DropdownPaper>
								)}
							/>
						)}
						name='modality'
						control={control}
						defaultValue={initialValues?.modality || ''}
						//rules={{}}
					/>
				</Grid>

				{/* Condiciones */}
				<Grid item xs={12} sm={hasPrepaid ? 3 : 4}>
					<Controller
						render={({ onChange, ...propsown }) => (
							<Autocomplete
								{...propsown}
								disabled={isProfile || modality === MODALITY_OPTIONS.SC}
								openOnFocus
								options={[
									PaymentConditions.CASH,
									PaymentConditions.SEVEN_DAYS,
									PaymentConditions.FIFTEEN_DAYS,
									PaymentConditions.TWENTYTWO_DAYS,
									PaymentConditions.THIRTY_DAYS,
									PaymentConditions.SIXTY_DAYS
								]}
								getOptionLabel={(option) => option ?? ''}
								getOptionSelected={(option, value) => value && option === value}
								onChange={(_, data) => {
									onChange(data);
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Condiciones de pago&nbsp;'
										variant='filled'
										error={Boolean(errors?.conditions)}
										disabled={modality === MODALITY_OPTIONS.SC}
										//helperText={}
									/>
								)}
								PaperComponent={(paperProps) => (
									<DropdownPaper>{paperProps.children}</DropdownPaper>
								)}
							/>
						)}
						name='conditions'
						control={control}
						defaultValue={initialValues?.conditions || ''}
						//rules={{}}
					/>
				</Grid>

				{/* Facturación */}
				{!isProfile && (
					<Grid item xs={12} sm={hasPrepaid ? 3 : 4}>
						<Controller
							render={({ onChange, ...propsown }) => (
								<Autocomplete
									{...propsown}
									openOnFocus
									options={BILLING_OPTIONS}
									getOptionLabel={(option) => capitalize(option) ?? ''}
									getOptionSelected={(option, value) => value && option === value}
									onChange={(_, data) => {
										onChange(data);
									}}
									disabled={modality === MODALITY_OPTIONS.SC}
									renderInput={(params) => (
										<TextField
											{...params}
											label='Facturación&nbsp;'
											variant='filled'
											error={Boolean(errors?.billing)}
											disabled={modality === MODALITY_OPTIONS.SC}
											//helperText={}
										/>
									)}
									PaperComponent={(paperProps) => (
										<DropdownPaper>{paperProps.children}</DropdownPaper>
									)}
								/>
							)}
							name='billing'
							control={control}
							defaultValue={initialValues?.billing || ''}
							//rules={{}}
						/>
					</Grid>
				)}

				{/* Envío reportes */}
				{/* {!isProfile && (
					<Grid item xs={12} sm={3}>
						<Controller
							render={({ onChange, ...propsown }) => (
								<Autocomplete
									{...propsown}
									openOnFocus
									options={Object.values(CustomerReportsReview)}
									getOptionLabel={(option) => capitalize(option) ?? ''}
									getOptionSelected={(option, value) => value && option === value}
									onChange={(_, data) => {
										onChange(data);
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label='Envío Reportes&nbsp;'
											variant='filled'
											error={Boolean(errors?.review_reports)}
											//helperText={}
										/>
									)}
									PaperComponent={(paperProps) => (
										<DropdownPaper>{paperProps.children}</DropdownPaper>
									)}
								/>
							)}
							name='review_reports'
							control={control}
							defaultValue={initialValues?.review_reports || ''}
							//rules={{}}
						/>
					</Grid>
				)} */}

				{/* Tipo de dispersion (asignación a asuarios o global) */}
				{!isProfile && hasPrepaid && (
					<Grid item xs={12} sm={3}>
						<Controller
							render={({ onChange, ...propsown }) => (
								<Autocomplete
									{...propsown}
									disabled={isProfile}
									openOnFocus
									options={DISPERSION_TYPE_OPTIONS}
									getOptionLabel={(option) => option ?? ''}
									getOptionSelected={(option, value) => value && option === value}
									onChange={(_, data) => {
										onChange(data);
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label='Dispersiones&nbsp;'
											variant='filled'
											error={Boolean(errors?.dispersion_type)}
											//helperText={}
										/>
									)}
									PaperComponent={(paperProps) => (
										<DropdownPaper>{paperProps.children}</DropdownPaper>
									)}
								/>
							)}
							name='dispersion_type'
							control={control}
							defaultValue={initialValues?.dispersion_type ?? null}
							//rules={{}}
						/>
					</Grid>
				)}

				{isProfile && (
					<>
						<Grid item xs={12} sm={3}>
							<Controller
								disabled={isProfile}
								as={<TextField fullWidth label='Esquema de corte&nbsp;' variant='filled' />}
								name='customer_schema'
								control={control}
								defaultValue={
									capitalize(
										initialValues?.reports?.find((item: any) => item.name === 'guías')?.schema
									) ?? ''
								}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Controller
								disabled={isProfile}
								as={<TextField fullWidth label='Esquema de corte&nbsp;' variant='filled' />}
								name='customer_cutoffdate'
								control={control}
								defaultValue={
									capitalize(
										initialValues?.reports?.find((item: any) => item.name === 'guías')?.date
									) ?? ''
								}
							/>
						</Grid>
					</>
				)}
			</Grid>
		</Grid>
	);
};

export default ClientFiscalForm;
