import { useState, useEffect, useMemo } from 'react';
import { Grid, Checkbox, TextField } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Autocomplete } from '@material-ui/lab';
import { Controller, Control, FieldErrors, useWatch, ErrorOption } from 'react-hook-form';
import { RootStore } from '~store/store';
import { useSelector } from 'react-redux';
import DropdownPaper from '~components/Forms/DropdownPaper';
import { NotificationType } from '~store/actions/ActionTypes';
import { useRouteMatch } from 'react-router-dom';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneNumberInput from '~components/Inputs/InputForms/PhoneNumberInput';

enum NotificationStatusOption {
	DELIVERED = 'Entregado',
	ANY = 'Cualquier movimiento'
}

const STATUS_OPTIONS = [NotificationStatusOption.DELIVERED, NotificationStatusOption.ANY];
// const MODALITY_OPTIONS = ['Todos los envíos', 'Este envío'];
export const DEFAULT_MODALITY = 'Este envío';

export type NotificationsContactsFields = {
	alias: string;
	phone_number: string;
	guide_status: string;
	shipping_mode: string;
	active: boolean;
	user_id?: number;
	contact_id?: number;
	type?: NotificationType;
};

type NotificationProps = {
	control: Control;
	errors: FieldErrors;
	defaultValues?: NotificationsContactsFields | null;
	backgroundColor?: string;
	type?: 'user' | 'contact';
	canEdit: boolean;
	setError: (name: string, error: ErrorOption) => void;
	clearErrors: (name?: string | string[] | undefined) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
		'& > div': {
			paddingLeft: '12px'
		},
		'& > .MuiGrid-item': {
			// padding: '8px',
			'& input': {
				padding: '10px 0'
			},
			'& > div > div > div': {
				paddingRight: '8px'
			}
		},
		// phone input
		'& > .MuiGrid-item:nth-child(4)': {
			marginTop: '16px'
		}
	},
	phoneInput: {
		backgroundColor: 'inherit',
		borderTopLeftRadius: '8px',
		borderTopRightRadius: '8px',
		'& div': {
			backgroundColor: 'inherit !important',
			borderTopLeftRadius: '8px',
			borderTopRightRadius: '8px',
			transition: 'none',
			'& > input': {
				backgroundColor: 'inherit !important',
				color: '#fff !important',
				fontFamily: 'Roboto !important',
				fontSize: '14px !important',
				// padding: '30px 12px 10px',
				border: 'none',
				borderBottom: '1px solid #c5c5c5',
				borderTopRightRadius: '8px',
				'&:focus': {
					borderBottom: '1px solid #c5c5c5',
					outline: 'none'
				}
			}
		},
		'& > div > div:nth-child(1)': {
			'& > div:nth-child(2)': {
				borderRadius: 0
			}
		},
		'& .PhoneInputCountry': {
			borderBottom: '1px solid #c5c5c5',
			marginRight: 0,
			paddingRight: '8px',
			'& > .PhoneInputCountryIcon': {
				borderRadious: '0 !important'
			}
		},
		'& .PhoneInput--disabled': {
			opacity: 0.5
		}
	}
}));

const NotificationRow = ({
	control,
	errors,
	defaultValues,
	backgroundColor,
	type,
	canEdit,
	setError,
	clearErrors
}: NotificationProps) => {
	const classes = useStyles();
	const { originAddressSend, destinationAddressSend } = useSelector(
		(state: RootStore) => state.send
	);
	const [isActive, setIsActive] = useState(
		!canEdit ? defaultValues?.active ?? false : type !== 'user'
	);
	// const [isEdittingPhone, setToggleEdittingPhone] = useState(false);

	const phoneFieldName = useMemo(() => `${type}_phone_number`, [type]);
	const phoneFieldValue: string | undefined = useWatch({
		name: phoneFieldName,
		control,
		defaultValue: defaultValues?.phone_number
			? defaultValues.phone_number
			: type === 'user'
			? originAddressSend?.contact?.phone_number ?? undefined
			: type === 'contact'
			? destinationAddressSend?.contact?.phone_number ?? undefined
			: undefined
	});

	let matchSendFlow = useRouteMatch('/resumen');
	// const phoneMaxLength = useMemo(
	// 	() =>
	// 		phoneField?.charAt(0) === '+'
	// 			? 13
	// 			: phoneField?.charAt(0) === '5' && phoneField?.charAt(1) === '2'
	// 			? 12
	// 			: 10,
	// 	[phoneField]
	// );

	useEffect(() => {
		if (phoneFieldValue && !isValidPhoneNumber(phoneFieldValue) && isActive && canEdit) {
			setError(phoneFieldName, {
				type: 'custom',
				message: 'El teléfono es inválido'
			});
		} else if (!!errors[phoneFieldName] && errors[phoneFieldName]?.type !== 'custom') {
			setError(phoneFieldName, {
				type: 'custom',
				message: errors[phoneFieldName]?.message
			});
		} else {
			clearErrors && clearErrors(phoneFieldName);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [phoneFieldValue, phoneFieldName, errors, isActive, canEdit]);

	return (
		<Grid
			item
			xs={12}
			container
			alignItems='flex-end'
			style={{
				backgroundColor: backgroundColor ?? '#2d2d2d'
			}}
			className={classes.root}
		>
			<Grid item md={1} container alignItems='center' justifyContent='flex-start'>
				<Grid item xs={12}>
					<Controller
						control={control}
						name={`${type}_active`}
						type={'boolean'}
						defaultValue={isActive}
						render={({ onChange }) => (
							<Checkbox
								color={'primary'}
								checked={isActive}
								onChange={(e) => {
									onChange(e.target.checked);
									setIsActive(e.target.checked);
								}}
								disabled={!canEdit}
							/>
						)}
						disabled={!canEdit}
					/>
				</Grid>
			</Grid>
			<Grid item md={3} container alignItems='center'>
				<Grid item xs={12}>
					<Controller
						rules={isActive ? { required: { value: true, message: 'Ingresa un alias' } } : {}}
						as={
							<TextField
								variant='standard'
								size='small'
								error={
									isActive &&
									Boolean(errors?.notifications) &&
									Boolean(errors?.notifications[`${type}_alias`])
								}
								// helperText={
								// 	isActive &&
								// 	Boolean(errors?.notifications) &&
								// 	errors?.notifications[`${type}_alias`]?.message
								// }
								fullWidth
								label={type === 'user' ? 'Remitente' : 'Destinatario'}
								disabled={!!matchSendFlow || !canEdit}
							/>
						}
						name={`${type}_alias`}
						control={control}
						defaultValue={
							defaultValues?.alias
								? defaultValues.alias
								: type === 'user'
								? originAddressSend?.contact?.name ?? null
								: type === 'contact'
								? destinationAddressSend?.contact?.name ?? null
								: null
						}
					/>
				</Grid>
			</Grid>
			<Grid item md={4} container alignItems='center'>
				<Grid item xs={12}>
					<Controller
						render={({ onChange, ...propsown }) => (
							<Autocomplete
								{...propsown}
								openOnFocus
								options={STATUS_OPTIONS}
								getOptionLabel={(option) => option}
								getOptionSelected={(option, value) =>
									option.normalize().toLowerCase() === value.normalize().toLowerCase()
								}
								onChange={(_, data) => {
									if (!data) return;
									onChange(data);
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										variant='standard'
										size='small'
										label=''
										// helperText={
										// 	isActive &&
										// 	Boolean(errors[`${type}_guide_status`]) &&
										// 	errors[`${type}_guide_status`]?.message
										// }
										error={isActive && Boolean(errors[`${type}_guide_status`])}
										disabled={!canEdit}
									/>
								)}
								disableClearable
								disabled={!canEdit}
								PaperComponent={(paperProps) => (
									<DropdownPaper>{paperProps.children}</DropdownPaper>
								)}
							/>
						)}
						name={`${type}_guide_status`}
						control={control}
						rules={isActive ? { required: 'Selecciona un status' } : {}}
						defaultValue={defaultValues?.guide_status || NotificationStatusOption.DELIVERED}
					/>
				</Grid>
			</Grid>
			{/* <Grid item md={3} container alignItems='center'>
				<Grid item xs={12}>
					<Controller
						render={({ onChange, ...propsown }) => (
							<Autocomplete
								{...propsown}
								openOnFocus
								options={MODALITY_OPTIONS}
								getOptionLabel={(option) => option}
								getOptionSelected={(option, value) =>
									option.normalize().toLowerCase() === value.normalize().toLowerCase()
								}
								onChange={(_, data) => {
									if (!data) return;
									onChange(data);
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										variant='standard'
										size='small'
										error={isActive && Boolean(errors[`${type}_shipping_mode`])}
										// helperText={
										// 	isActive &&
										// 	Boolean(errors[`${type}_shipping_mode`]) &&
										// 	errors[`${type}_shipping_mode`]?.message
										// }
										disabled={!canEdit}
									/>
								)}
								disableClearable
								disabled={!canEdit}
								PaperComponent={(paperProps) => (
									<DropdownPaper>{paperProps.children}</DropdownPaper>
								)}
							/>
						)}
						name={`${type}_shipping_mode`}
						control={control}
						rules={isActive ? { required: 'Selecciona una modalidad' } : {}}
						defaultValue={defaultValues?.shipping_mode ?? ''}
					/>
				</Grid>
			</Grid> */}
			<Grid item md={4} container alignItems='center'>
				<div className={classes.phoneInput}>
					<PhoneNumberInput
						required={isActive && canEdit}
						control={control}
						errors={errors}
						name={phoneFieldName}
						defaultValue={
							defaultValues?.phone_number
								? defaultValues.phone_number
								: type === 'user'
								? originAddressSend?.contact?.phone_number
								: type === 'contact'
								? destinationAddressSend?.contact?.phone_number
								: ''
						}
						setError={setError}
						clearErrors={clearErrors}
						error={errors[phoneFieldName]}
					/>

					{/* <Controller
						name={phoneFieldName}
						control={control}
						rules={
							isActive && canEdit
								? {
										required: 'Ingrese un número de teléfono',
										maxLength: {
											value:
												phoneFieldValue?.charAt(0) === '+'
													? 13
													: phoneFieldValue?.charAt(0) === '5' && phoneFieldValue?.charAt(1) === '2'
													? 12
													: 10,
											message: 'El máximo es 10 caracteres'
										}
								  }
								: { required: false }
						}
						error={isActive && Boolean(errors[phoneFieldName])}
						helperText={
							isActive && Boolean(errors[phoneFieldName]) && errors[phoneFieldName]?.message
						}
						render={({ onChange, ...props }) => (
							<PhoneInputWithCountrySelect
								{...props}
								disabled={!canEdit}
								variant='standard'
								defaultCountry='MX'
								label=''
								onChange={(value) => {
									onChange(value);
								}}
							/>
						)}
						defaultValue={
							defaultValues?.phone_number
								? defaultValues.phone_number
								: type === 'user' && originAddressSend?.contact?.type === ContactPhoneType.MOBILE
								? originAddressSend?.contact?.phone_number ?? null
								: type === 'contact' &&
								  destinationAddressSend?.contact?.type === ContactPhoneType.MOBILE
								? destinationAddressSend?.contact?.phone_number ?? null
								: null
						}
					/> */}
				</div>
			</Grid>
			{/* {canEdit && (
				<Grid
					item
					md={1}
					container
					justifyContent='center'
					style={{ height: '100%', cursor: 'pointer', marginBottom: '6px' }}
				>
					<Edit
						onClick={() => {
							setToggleEdittingPhone((prev) => !prev);
						}}
					/>
				</Grid>
			)} */}
		</Grid>
	);
};

export default NotificationRow;
