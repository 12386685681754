import { useState, useEffect, useCallback, useMemo } from 'react';
import { BookletServiceForm } from '~store/actions/ActionTypes';
import { Option } from '~components/Forms/Select';
import axios from '~util/axios';
import { CARRIERS, getCarrierName } from '~util/logos';
import { getRateCurrentYear } from '~util/rateYear';

type ServiceMap = {
	[key: string]: BookletServiceForm[];
};

type Props = {
	initialValues: any;
	editable?: boolean;
	isCustomer?: boolean;
};

export type TariffListItem = {
	name: string;
	carrier: string;
	percentage_increase: number;
	year: string;
};

export default function useServices({ initialValues, editable, isCustomer }: Props) {
	const [carrier, updateCarrier] = useState<Option | null>(null);
	const [servicesMap, updateServicesMap] = useState<ServiceMap>({});
	const [selectedServices, updateSelectedServices] = useState<BookletServiceForm[]>([]);
	const [loading, updateLoading] = useState(true);
	const [tariffsList, setTariffsList] = useState<TariffListItem[]>([]);
	const [selectedService, setSelectedService] = useState<BookletServiceForm | null>(null);

	// Get all services
	useEffect(() => {
		(async () => {
			const servicesMap: ServiceMap = {};
			try {
				let services: any[] = [];
				if (editable) {
					services = initialValues;
				} else {
					services = initialValues.map((e: any) => e.value);
				}

				const selected: BookletServiceForm[] = [];

				const response = await axios.get('/api/services');

				// Map Services
				response.data.forEach((service: any) => {
					const coolCarrierName = getCarrierName(service.carrier);
					// Create map of carriers
					if (!servicesMap[coolCarrierName]) {
						servicesMap[coolCarrierName] = [] as BookletServiceForm[];
					}

					const toAdd: any = {
						name: service.service,
						id: service.id,
						carrier: coolCarrierName,
						mode: service.mode
					};

					if (service.carrier === CARRIERS.ESTAFETA) {
						toAdd.rates_service_description = service?.rates_service_description;
					}

					// We only hit this case when editing an existing group in which we might already have
					// some selected services
					// TODO fix this complicated logic of being editable
					if (editable) {
						const serviceIndex = services.findIndex((s) => s.id === service.id);
						if (serviceIndex > -1) {
							if (isCustomer && !services[serviceIndex].enabled) {
								return;
							}

							const extra = {
								enabled: services[serviceIndex].enabled,
								min_stock: services[serviceIndex].min_stock,
								price_list: services[serviceIndex].price_list,
								discount: services[serviceIndex].discount,
								irregular_delivery_price: services[serviceIndex].irregular_delivery_price,
								reissue_price: services[serviceIndex].reissue_price,
								insurance_percentage: services[serviceIndex].insurance_percentage,
								tariff_name: services[serviceIndex].tariff_name
							};
							selected.push({ ...toAdd, ...extra });
							return;
						}
					} else {
						if (services.includes(service.id)) {
							selected.push(toAdd as BookletServiceForm);
							return;
						}
					}

					servicesMap[coolCarrierName].push(toAdd);
				});

				updateSelectedServices(selected);

				updateServicesMap(servicesMap);
				updateLoading(false);

				getTariffs();
			} catch (err) {
				console.error('Error while fetching users from useServices', err);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Set carriers
	const carrierOptions = useMemo(() => {
		return Object.entries(servicesMap).map(([carrier]) => ({
			label: carrier,
			value: carrier
		}));
	}, [servicesMap]);

	const getTariffs = useCallback(async () => {
		try {
			const { data } = await axios.get(`/api/service/tariffs?year=${getRateCurrentYear()}`);
			setTariffsList(data);
		} catch (error) {
			console.error(error);
		}
	}, []);

	const addService = useCallback(
		(service: BookletServiceForm) => {
			const newCarrierServices = servicesMap[service.carrier].filter((s) => s.id !== service.id);
			updateServicesMap((prevState) => ({
				...prevState,
				[service.carrier]: newCarrierServices
			}));
			updateSelectedServices((prev) => [...prev, service]);
		},
		[servicesMap]
	);

	const removeService = useCallback(
		(id: number) => {
			const serviceToMove = selectedServices.findIndex((service) => service.id === id);
			if (serviceToMove < 0)
				return console.error('There is something wrong at removeService in useServices');

			const service = selectedServices[serviceToMove];

			const newSelectedServices = selectedServices.filter((service) => service.id !== id);
			updateServicesMap((prevState) => ({
				...prevState,
				[service.carrier]: [...prevState[service.carrier], service]
			}));
			updateSelectedServices(newSelectedServices);
		},
		[selectedServices]
	);

	// Function to get services by carrier
	const services = useMemo(() => {
		if (!carrier) return [];

		return servicesMap[carrier.label];
	}, [carrier, servicesMap]);

	return {
		selectedServices,
		updateSelectedServices,
		carrierOptions,
		addService,
		carrier,
		updateCarrier,
		services,
		removeService,
		loading,
		tariffsList,
		selectedService,
		setSelectedService
	};
}
