import { Checkbox, IconButton, TextField, Tooltip } from '@mui/material';
import { GridRenderEditCellParams, GridColDef, useGridApiContext } from '@mui/x-data-grid';
import { Trash2, DollarSign } from 'react-feather';
import { GridValueFormatterParams } from '@mui/x-data-grid';
import { NotSuitableForRates } from '.';
import { CustomerResponse } from '~store/actions/ActionTypes';

type GetColumnsProps = {
	onDelete: (index: number, id: number) => any;
	editable: boolean;
	showControlls: boolean;
	goToRate: (id: number) => void;
	addedServices: string[];
	fromDispersion?: boolean;
	disabled: boolean;
	customer?: CustomerResponse;
	hasBalance: boolean;
};

const RenderIsEnabled = (params: any) => {
	return <Checkbox disabled={true} color='primary' checked={params.value} />;
};

function EnabledEditCheckboxCell(props: GridRenderEditCellParams, customerBalance?: boolean) {
	const { id, value, field } = props;
	const apiRef = useGridApiContext();

	const handleChange = () => {
		apiRef.current.setEditCellValue({ id, field, value: !Boolean(value) });
	};

	return (
		<Checkbox
			disabled={customerBalance}
			color='primary'
			checked={Boolean(value)}
			onChange={handleChange}
		/>
	);
}

function EnabledInputCell(params: GridRenderEditCellParams) {
	return (
		<TextField
			sx={{
				'& div::before': {
					border: 'none',
					borderBottomStyle: 'none !important'
				},
				'& div input': {
					textAlign: 'right'
				}
			}}
			disabled={!params.row.tariff_name?.includes('Propuestas Únicas')}
			value={`${Number(params.value).toLocaleString()} %`}
		/>
	);
}

function EnabledEditInputCell(params: GridRenderEditCellParams) {
	const { id, field } = params;
	const apiRef = useGridApiContext();

	const handleChange = (e: any) => {
		apiRef.current.setEditCellValue({ id, field, value: e.target.value });
	};

	return (
		<TextField
			onChange={handleChange}
			disabled={!params.row.tariff_name?.includes('Propuestas Únicas')}
			value={!params.value ? 0 : params.value}
		/>
	);
}

const editableCols = (customerBalance?: boolean): GridColDef[] => {
	return [
		{
			field: 'enabled',
			headerName: 'Cargos / Seguro',
			flex: 1,
			editable: true,
			renderCell: RenderIsEnabled,
			renderEditCell: (params) => EnabledEditCheckboxCell(params, customerBalance)
		},
		{
			field: 'discount',
			headerName: 'Aumento',
			flex: 1,
			editable: true,
			type: 'number',
			renderCell: (params) => EnabledInputCell(params),
			renderEditCell: (params) => EnabledEditInputCell(params)
		},
		{
			field: 'irregular_delivery_price',
			headerName: 'Manejo Especial',
			flex: 0.75,
			editable: true,
			type: 'number',
			description: 'Precio especial para envío irregular'
		},
		{
			field: 'reissue_price',
			headerName: 'Reexpedición',
			flex: 0.75,
			editable: true,
			type: 'number',
			description: 'Precio especial para reexpediciones'
		},
		{
			field: 'insurance_percentage',
			headerName: 'Seguro',
			flex: 1,
			editable: true,
			type: 'number',
			valueFormatter: (params: GridValueFormatterParams<number>) => {
				if (!params.value) {
					return '';
				}
				if (params.value > 100) {
					return 100;
				}
				const valueFormatted = Number(params.value).toLocaleString();
				return `${valueFormatted} %`;
			},
			description: 'Precio especial para seguro'
		}
	];
};

export function getColumns({
	onDelete,
	editable,
	showControlls,
	goToRate,
	addedServices,
	fromDispersion,
	disabled,
	customer,
	hasBalance
}: GetColumnsProps) {
	const columns: GridColDef[] = [
		{
			field: 'carrier',
			headerName: 'Proveedor',
			flex: 1
		},
		{
			field: 'name',
			headerName: 'Servicio',
			flex: 2.5
		},
		{
			field: 'tariff_name',
			headerName: 'Tarifario',
			flex: 1.5
		}
	];

	if (editable) {
		columns.push(...editableCols(Boolean(customer && hasBalance)));
	}

	if (showControlls || editable) {
		columns.push({
			field: 'actions',
			headerName: 'Acciones',
			width: 120,
			sortable: false,
			// disableClickEventBubbling: true,
			renderCell: ({ id, tabIndex, row }) => {
				// NOTE(eduardo): tabIndex is not used anymore. We should delete
				// it and make sure that the change does not affect the current
				// behaviour.
				const disableButton = disabled || addedServices.includes(id.toString());

				return (
					<div style={{ display: 'flex', gap: '8px' }}>
						<Tooltip title='Eliminar'>
							<IconButton
								onClick={() => onDelete(tabIndex, id as number)}
								aria-label='delete'
								color='default'
							>
								<Trash2 size={20} />
							</IconButton>
						</Tooltip>
						{row.carrier?.toLowerCase() === 'estafeta' &&
							!row.name.includes('2 Dias') &&
							row.name !== NotSuitableForRates.ESTAFETA_TRACKING &&
							row.name !== NotSuitableForRates.ESTAFETA_PICKUP &&
							row.name !== NotSuitableForRates.ESTAFETA_TRACKING_PICKUP && (
								<Tooltip title='Tarifas'>
									<IconButton
										onClick={() => goToRate(id as number)}
										aria-label='rates'
										disabled={disableButton}
									>
										<DollarSign size={20} />
									</IconButton>
								</Tooltip>
							)}
					</div>
				);
			}
		});
	}
	return columns;
}
