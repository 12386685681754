import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import axiosInstance from '~util/axios';
import { DocumentRequestType, DocumentResponseType, Entity } from '~store/actions/ActionTypes';
import DrawContext from '~context/DrawContext';
import { DocumentRow } from './DocumentsForm';

const all = ['ine', 'tax', 'proof_address'];
const onlyMoral = ['constitutive_act', 'assembly_act'];

const useDocumentsForm = () => {
	const params: { token?: string } = useParams();

	const [isLoading, setIsLoading] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [documentsData, setDocumentsData] = useState<DocumentResponseType | null>(null);
	const [rows, setRows] = useState<DocumentRow[]>([]);

	const { setNotification } = useContext(DrawContext);

	const getDocuments = useCallback(async () => {
		if (!params?.token) return;
		try {
			const { data } = await axiosInstance.get(`/api/prospect/documents/${params?.token}`);

			const result: DocumentResponseType = {
				...data,
				documents: {
					ine: data.documents?.ine || data.ineData,
					tax: data.documents?.tax || data.taxData,
					proof_address: data.documents?.proof_address || data.proofAddressData,
					constitutive_act: data.documents?.constitutive_act || data.constitutiveActData,
					assembly_act: data.documents?.assembly_act || data.assemblyActData
				}
			};
			setDocumentsData(result);
			const requiredDocs = result?.prospect_type === Entity.PHYSICAL ? all : [...all, ...onlyMoral];
			const resultRows = Object.entries(result?.documents)
				.filter(([key, _]) => requiredDocs?.includes(key))
				.map(([key, value]) => ({
					...value,
					name: key
				}));
			setRows(resultRows);
		} catch (error) {
			if (!axios.isAxiosError(error)) return;
			const errorMessage =
				error.response?.data?.message === 'TOKEN_EXPIRED'
					? 'Este link expiró. Si crees que es un error, comunícate con el equipo de Encamino.'
					: 'Hubo un error al cargar los documentos';
			setNotification({
				message: errorMessage,
				severity: 'error'
			});
		} finally {
			setIsLoading(false);
		}
	}, [params?.token, setNotification]);

	const saveDocument = useCallback(
		async (payload: DocumentRequestType) => {
			setIsSubmitting(true);
			const formData = new FormData();
			formData.append('files', payload.files);
			formData.append('file_type', payload.file_type);
			formData.append('document', payload.document);

			// TODO: for root and customer profile
			try {
				const { data } = await axiosInstance.post(`/api/prospect/files/${params?.token}`, formData);
				const updatedData: DocumentResponseType = {
					...documentsData!,
					documents: { ...data }
				};
				setDocumentsData(updatedData);
				setNotification({
					message: 'Se subió el documento con éxito',
					severity: 'success'
				});
				setIsLoading(true);
				getDocuments();
			} catch (error) {
				if (!axios.isAxiosError(error)) return;
				let errorMessage = 'Hubo un error al cargar el archivo';
				if (error.response?.data?.message === 'WRONG_DATA') {
					errorMessage = error.response?.data?.description;
				}
				setNotification({
					message: errorMessage,
					severity: 'error'
				});
			} finally {
				setIsSubmitting(false);
			}
		},
		[params?.token, setNotification, documentsData, getDocuments]
	);

	useEffect(() => {
		setIsLoading(true);
		getDocuments();
	}, [getDocuments]);

	return { isLoading, documentsData, saveDocument, isSubmitting, rows };
};

export default useDocumentsForm;
