import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { ArrowLeft } from 'react-feather';
import { CircularProgress, Grid } from '@mui/material';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory, useParams } from 'react-router';
import ClientForm from '~components/Forms/Client/ClientForm';
import { useForm } from 'react-hook-form';
import ControlBar from '~components/Bar/ControlBar';
import CreateOrUpdateProspectControl from './control';
import { useCardStyles } from '~hooks/useStyles';
import usePermissions from '~hooks/usePermissions';
import useProspects from '../ProspectsTable/useProspects';
import { ProspectRequestType, ProspectValidationStatus } from '~store/actions/ActionTypes';
import { SellerResponse } from '~store/actions/ActionTypes';
import ServicesSelector from '~components/Forms/ServicesSelector';
import { useSelector } from 'react-redux';
import { RootStore } from '~store/store';
import { ServiceDataItem } from '../CreateOrUpdateCustomer/useCustomerForm';
import { makeStyles } from '@mui/styles';
import { BookletServiceForm } from '~store/actions/ActionTypes';
import { HeadingOne } from '~components/Headings';
import ClientFiscalForm from '~components/Forms/Client/ClientFiscalForm';
import ClientReportsConfig from '~components/Forms/Client/ClientReports/ClientReportsConfig';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		padding: `0 ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)}`,
		width: '100%'
	},
	formContainer: {
		padding: `0 0 ${theme.spacing(3)} 0px`,
		width: '100%'
	},
	title: {
		padding: `${theme.spacing(3)} 0 0 0 !important`,
		marginLeft: '-24px'
	},
	leftArrow: {
		color: '#57BAD7',
		backgroundColor: 'transparent',
		border: 'none',
		'&:focus': {
			outline: 'none'
		},
		'&:hover': {
			cursor: 'pointer'
		}
	}
}));

type FormValues = {
	// General Info
	name: string;
	last_name: string;
	email: string;
	phone_number: string;
	business_name: string;
	company_type: { title: string; value: string };
	enterprise_operations: { title: string; value: string };
	channel: { title: string; value: string };
	webpage: string;
	seller: SellerResponse;
	validation_status: {
		title: 'Prospección' | 'Validado' | 'Documentación' | 'Registro';
		value: ProspectValidationStatus;
	};
	business_type: string;
};

const CreateOrUpdateProspect: React.FC = () => {
	const classes = useStyles();
	const { cardClasses } = useCardStyles();
	const history = useHistory();
	const params: { id?: string } = useParams();
	const { isRoot } = usePermissions();

	const { handleSubmit, control, errors, watch, register, clearErrors, setValue } =
		useForm<FormValues>({
			mode: 'onTouched'
		});
	const { getProspect, prospect, loading, saveProspect } = useProspects();
	const reportsRef = useRef<HTMLElement | null>(null);

	const { servicesInfoDraftCUCustomer } = useSelector((state: RootStore) => state.cuCustomer);

	useEffect(() => {
		if (params.id) {
			getProspect(params.id);
		}
	}, [params.id, getProspect]);

	const {
		name,
		last_name,
		email,
		phone_number,
		business_name,
		enterprise_operations,
		company_type,
		validation_status
	} = watch();

	// Handle Submit
	const onSubmit = async (data: FormValues) => {
		const payload: { [key: string]: any } = {
			name: data.name.trim(),
			last_name: data.last_name.trim(),
			email: data.email.trim(),
			phone_number: data.phone_number.trim(),
			business_name: data.business_name.trim() || undefined,
			company_type: data.company_type.value || undefined,
			set_of_operations: data.enterprise_operations.value || undefined,
			webpage: data.webpage || undefined,
			validation_status: data.validation_status.value,
			channel: data.channel.value || undefined,
			business_type: data.business_type
		};

		if (isRoot) {
			payload.seller_id = data.seller?.id;
		}

		payload.services = servicesInfoDraftCUCustomer.map((s) => {
			const payloadServices = {
				id: s.id,
				enabled: s.enabled,
				min_stock: s.min_stock,
				price_list: s.price_list,
				discount: s.discount
			} as ServiceDataItem;
			if (!!s.reissue_price) {
				payloadServices.reissue_price = s.reissue_price;
			}
			if (!!s.irregular_delivery_price) {
				payloadServices.irregular_delivery_price = s.irregular_delivery_price;
			}
			if (!!s.insurance_percentage) {
				payloadServices.insurance_percentage = s.insurance_percentage;
			}
			return payloadServices;
		});

		saveProspect(payload as ProspectRequestType, params.id);
	};

	if (loading) {
		return (
			<React.Fragment>
				<Skeleton variant='rect' height={550} className={clsx(cardClasses.card, classes.root)} />
			</React.Fragment>
		);
	}

	return (
		<Grid container direction='row' justifyContent='center' className={classes.root}>
			<Grid item xs={12} container alignItems='center' className={classes.title}>
				<button className={classes.leftArrow} onClick={() => history.goBack()}>
					<ArrowLeft size={32} />
				</button>
				<HeadingOne
					text={params.id ? `Edición de ${prospect?.contact.name}` : 'Alta de Prospecto'}
				/>
			</Grid>

			<form autoComplete='off' className={classes.formContainer}>
				<Grid container direction='row' justifyContent='center'>
					{/* Datos generales */}
					<Grid item xs={12}>
						<ClientForm
							customID={prospect?.prospect_id || ''}
							initialValues={prospect}
							oldBussinesName={''}
							oldEmail={''}
							responseError={''}
							control={control}
							errors={errors}
							disableSpecial={false}
							blocked={false}
						/>
					</Grid>
					{/* Servicios */}
					<Grid item xs={12}>
						{prospect?.services?.length && !servicesInfoDraftCUCustomer ? (
							<CircularProgress />
						) : (
							<ServicesSelector
								register={register}
								control={control}
								onChange={() => clearErrors('services')}
								defaultValues={servicesInfoDraftCUCustomer as BookletServiceForm[]}
								editable
								customer={prospect ?? undefined}
							/>
						)}
					</Grid>

					{/* Facturacion */}
					<Grid item xs={12}>
						<ClientFiscalForm
							clearSchema={() => clearErrors('schema')}
							initialValues={prospect}
							control={control}
							errors={errors}
							setValue={setValue}
						/>
					</Grid>

					{/* Reportes */}
					<Grid item xs={12} ref={(instance) => (reportsRef.current = instance)}>
						<ClientReportsConfig
							control={control}
							initialValues={prospect?.reports}
							errors={errors}
							setValue={setValue}
						/>
					</Grid>
				</Grid>
			</form>

			<ControlBar
				onContinue={handleSubmit(onSubmit)}
				onLeftControl={history.goBack}
				isLoading={loading}
				rightText={
					params.id && validation_status?.value === ProspectValidationStatus.VALIDATED
						? 'A cliente'
						: 'Guardar'
				}
			>
				<CreateOrUpdateProspectControl
					generalInfo={Boolean(
						name &&
							last_name &&
							email &&
							phone_number &&
							business_name &&
							enterprise_operations &&
							company_type &&
							!errors?.name &&
							!errors?.last_name &&
							!errors?.email &&
							!errors?.phone_number &&
							!errors?.business_name &&
							!errors?.enterprise_operations &&
							!errors?.company_type
					)}
				/>
			</ControlBar>
		</Grid>
	);
};

export default CreateOrUpdateProspect;
