import { create } from 'zustand';
import produce from 'immer';

type ExportedReportQueue = {
	id: string;
	spreadsheet_id: string;
	status: string;
	url: string;
	type?: string;
};

interface ReportExport {
	reportExports: ExportedReportQueue[];
	setReportExports: (reportExports: ExportedReportQueue) => void;
	removeReportExports: (id: string) => void;
}

const useReportExportsStore = create<ReportExport>((set) => ({
	reportExports: [],
	setReportExports: (reportExports) =>
		set((state) =>
			produce(state, (draft) => {
				draft.reportExports.push(reportExports);
			})
		),
	removeReportExports: (id) =>
		set((state) =>
			produce(state, (draft) => {
				draft.reportExports = draft.reportExports.filter(
					(reportExports) => reportExports.spreadsheet_id !== id
				);
			})
		)
}));

export default useReportExportsStore;
