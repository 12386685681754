import React from 'react';
import { TextField } from '@mui/material';
import { Autocomplete } from '@material-ui/lab';
import { Controller, FieldErrors, Control } from 'react-hook-form';
import DropdownPaper from '~components/Forms/DropdownPaper';

export const enterpriseTypeOptions = [
	{ title: 'Micro (1-10 empleados)', value: 'micro' },
	{ title: 'Pequeña (11-30 empleados)', value: 'pequeña' },
	{ title: 'Mediana (31-100 empleados)', value: 'mediana' },
	{ title: 'Grande (más de 100 empleados)', value: 'grande' }
];

interface Props {
	// Function to pass the selected value
	control: Control;
	errors: FieldErrors;
	defaultValue?: { title: string; value: string };
}

/**
 * Autocomplete for "Tipo de empresa"
 */
const EnterpriseType: React.FC<Props> = (props) => {
	return (
		<Controller
			render={({ onChange, ...propsown }) => (
				<Autocomplete
					{...propsown}
					openOnFocus
					options={enterpriseTypeOptions}
					getOptionLabel={(option) => option.title}
					getOptionSelected={(option, value) => option.title === value.title}
					onChange={(_, data) => onChange(data)}
					renderInput={(params) => (
						<TextField
							{...params}
							label='Tipo de empresa'
							variant='filled'
							error={Boolean(props.errors?.company_type)}
							helperText={props.errors?.company_type?.message}
							autoComplete='off'
						/>
					)}
					PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
				/>
			)}
			name='company_type'
			control={props.control}
			// rules={{ required: 'Ingrese el Tipo de Empresa' }}
			defaultValue={!!props.defaultValue?.value ? props.defaultValue : null}
		/>
	);
};

export default EnterpriseType;
