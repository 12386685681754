import {
	ConditionTypeReport,
	PreInvoiceDays,
	ReportDateBiweekly,
	ReportDateMonthly,
	ReportDateWeekly,
	ReportSubType,
	SchemaType
} from '~store/actions/ActionTypes';

export const subtypeOptions = [
	ReportSubType.CURRENT_MONTH,
	ReportSubType.PREVIOUS_MONTH,
	ReportSubType.BIWEEK,
	ReportSubType.WEEK
];

export const cutOptions = [
	{ title: SchemaType.IMMEDIATE },
	{ title: SchemaType.WEEKLY },
	{ title: SchemaType.BIWEEKLY },
	{ title: SchemaType.MONTHLY }
];

export const cutOptionsOverweightSupplier = [
	{ title: SchemaType.IMMEDIATE },
	{ title: SchemaType.INDEPENDENT }
];

export const monthDateOptions = [
	ReportDateMonthly.DAY_1,
	ReportDateMonthly.DAY_6,
	ReportDateMonthly.DAY_12,
	ReportDateMonthly.DAY_16,
	ReportDateMonthly.DAY_22,
	ReportDateMonthly.DAY_26,
	ReportDateMonthly.DAY_31
];

export const weeklyDateOptions = [
	ReportDateWeekly.MONDAY,
	ReportDateWeekly.TUESDAY,
	ReportDateWeekly.WEDNESDAY,
	ReportDateWeekly.THURSDAY,
	ReportDateWeekly.FRIDAY,
	ReportDateWeekly.SATURDAY,
	ReportDateWeekly.SUNDAY
];
export const biweeklyDateOptions = [ReportDateBiweekly.FIRST_WEEK];

export const reviewOptions = [
	{ title: ConditionTypeReport.INMEDIATE },
	{ title: ConditionTypeReport.THREE_DAYS },
	{ title: ConditionTypeReport.FIVE_DAYS }
];

export const billingOptions = [
	{ title: PreInvoiceDays.INMEDIATE },
	{ title: PreInvoiceDays.ONE_DAY },
	{ title: PreInvoiceDays.TWO_DAYS },
	{ title: PreInvoiceDays.THREE_DAYS },
	{ title: PreInvoiceDays.PURCHASE_ORDER }
];
