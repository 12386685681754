import { FC } from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

interface Props {
	currentTab: number;
	handleChange: (tab: number) => void;
	tabs: { name: string; path: string }[];
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
		borderRadius: '20px 20px 0 0',
		'&>*': {
			flex: 1,
			maxWidth: 'unset',
			fontWeight: 700,
			justifySelf: 'flex-start'
		}
	},
	bookletOption: {
		borderBottom: 'solid 0.9px rgb(81, 81, 81)'
	},
	label: {
		fontSize: 25
	},
	bookletOptionSelected: {
		borderBottom: 'solid 4px ' + theme.palette.primary.main,
		backgroundColor: '#4d4d4d',
		borderTopLeftRadius: '20px',
		borderTopRightRadius: '20px'
	},
	tabBorder: {
		boxSizing: 'border-box',
		borderBottom: 'solid 0.9px rgb(81, 81, 81)',
		paddingLeft: 0,
		paddingRight: 0,
		'& > span:nth-child(1)': {
			marginRight: '-5px',
			borderRight: '1px solid #505050',
			padding: '4px 0'
		}
	}
}));

const ProfileTabs: FC<Props> = (props) => {
	const classes = useStyles();

	return (
		<BottomNavigation
			value={props.currentTab}
			onChange={(_, newValue) => {
				props.handleChange(newValue);
			}}
			showLabels
			className={classes.root}
		>
			{props.tabs.map((tab, index) => (
				<BottomNavigationAction
					key={tab.path}
					className={clsx([
						classes.bookletOption,
						props.currentTab === index && classes.bookletOptionSelected,
						index !== props.tabs.length - 1 && props.currentTab !== index && classes.tabBorder
					])}
					label={tab.name}
				/>
			))}
		</BottomNavigation>
	);
};

export default ProfileTabs;
