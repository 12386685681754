import { IconButton, Tooltip } from '@mui/material/';
import { Search } from 'react-feather';

const GoToDetailIconButton = ({ onClick, title }: { onClick: () => void; title?: string }) => {
	return (
		<Tooltip title={title ?? 'Ver detalle'}>
			<IconButton onClick={onClick}>
				<Search size={20} />
			</IconButton>
		</Tooltip>
	);
};

export default GoToDetailIconButton;
