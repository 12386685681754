import { useEffect, useMemo } from 'react';
//Libraries
import { Grid, TextField, Tooltip } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { shallow } from 'zustand/shallow';
//Styles
import { useStyles } from './useReporTable.styles';
//Store
import useReportStore from '~store/ReportStore';
//Hooks
import usePermissions from '~hooks/usePermissions';
import useOptions from './hooks/useOptions';
import {
	subtypeOptions,
	cutOptions,
	reviewOptions,
	billingOptions,
	cutOptionsOverweightSupplier
} from './options';
import capitalize from '~util/capitalize';
import { FieldErrors } from 'react-hook-form';
import DropdownPaper from '~components/Forms/DropdownPaper';
import {
	ReportFileType,
	ReportItem,
	ReportName,
	ReportNumber,
	ReportSubType,
	ReportType,
	SchemaType
} from '~store/actions/ActionTypes';
import { useWatch } from 'react-hook-form';

interface Props {
	control: Control;
	initialValues?: ReportItem;
	errors: FieldErrors;
	setValue?: (key: string, value: unknown) => void;
}

const REPORT_FILE_TYPE_OPTIONS = Object.values(ReportFileType);

const OverweightRow = ({ control, initialValues, errors, setValue }: Props) => {
	const classes = useStyles();

	const { isRoot } = usePermissions();
	const { dateOptions, handleDateOptions, handleSubtypes } = useOptions({
		setValue
	});

	const [
		setOverweightReportNumber,
		overweightCutoffDate,
		setoverweightCutoffDate,
		guidesCutoffDate,
		insuranceCutoffDate,
		overweigthReportNumber
	] = useReportStore(
		(state) => [
			state.setOverweightReportNumber,
			state.overweightCutoffDate,
			state.setoverweightCutoffDate,
			state.guidesCutoffDate,
			state.insuranceCutoffDate,
			state.overweigthReportNumber
		],
		shallow
	);
	const overweightTypeName: { name: string; value: ReportName } | undefined = useWatch({
		control,
		name: 'overweight_name'
	});

	const reportFileType = useWatch({
		control,
		name: 'report_file_type'
	});

	const guidesDate: string | undefined = useWatch({
		control,
		name: 'guides_date'
	});

	const hasSameFileSet = useMemo(
		() => reportFileType === ReportFileType.SAME_FILE,
		[reportFileType]
	);

	const hasFilledValues = useMemo(
		() => !!insuranceCutoffDate || !!guidesCutoffDate || !!overweightCutoffDate,
		[insuranceCutoffDate, guidesCutoffDate, overweightCutoffDate]
	);

	const reportNameConfigForOverweight = useMemo(
		() => guidesCutoffDate === SchemaType.IMMEDIATE || guidesCutoffDate === SchemaType.INDEPENDENT,
		[guidesCutoffDate]
	);

	const canNotEditCutData = useMemo(
		() =>
			overweightTypeName?.value === ReportName.OVERWEIGHTS &&
			overweigthReportNumber === ReportNumber.ONE,
		[overweigthReportNumber, overweightTypeName]
	);

	useEffect(() => {
		initialValues &&
			handleSubtypes(
				hasSameFileSet && canNotEditCutData ? guidesCutoffDate : overweightCutoffDate,
				'overweight',
				initialValues
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		overweightCutoffDate,
		handleSubtypes,
		initialValues,
		hasSameFileSet,
		guidesCutoffDate,
		canNotEditCutData
	]);

	useEffect(() => {
		setoverweightCutoffDate(initialValues?.schema || '');
	}, [initialValues?.schema, setoverweightCutoffDate]);

	useEffect(() => {
		handleDateOptions(
			hasSameFileSet && canNotEditCutData ? guidesCutoffDate : overweightCutoffDate
		);
	}, [
		overweightCutoffDate,
		handleDateOptions,
		guidesCutoffDate,
		hasSameFileSet,
		canNotEditCutData
	]);

	useEffect(() => {
		if (hasSameFileSet && canNotEditCutData && guidesDate) {
			setValue && setValue('overweight_date', guidesDate);
		}
	}, [guidesDate, hasSameFileSet, canNotEditCutData, initialValues, setValue]);

	useEffect(() => {
		if (initialValues) {
			setValue && setValue('overweight_date', initialValues?.date ?? '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			initialValues?.type === ReportType.ONLY_OVERWEIGHTS ||
			initialValues?.type === ReportType.OVERWEIGHTS_AND_INSURANCES ||
			initialValues?.type === ReportType.LABELS_AND_INSURANCES
		) {
			setOverweightReportNumber(ReportNumber.TWO);
		} else {
			setOverweightReportNumber(ReportNumber.ONE);
		}
	}, [initialValues?.type, setOverweightReportNumber]);

	return (
		<Grid
			container
			direction='row'
			alignItems='center'
			justifyContent='space-between'
			className={classes.container}
		>
			{/* Num Rep */}
			<Grid item className={classes.small}>
				<Controller
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							disableClearable
							options={[{ num: '1' }, { num: '2' }]}
							disabled={!isRoot}
							getOptionLabel={(option) => option.num ?? ''}
							getOptionSelected={(option, value) => option.num === value?.num}
							onChange={(_, data) => {
								onChange(data);
								setOverweightReportNumber(data?.num);
							}}
							renderInput={(params) => (
								<TextField {...params} variant='filled' error={!!errors?.overweight_reportNumber} />
							)}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					name='overweight_reportNumber'
					control={control}
					defaultValue={
						initialValues?.type === 'todos' || initialValues?.type === 'guías y sobrepesos'
							? { num: '1' }
							: initialValues?.type === ReportType.ONLY_OVERWEIGHTS ||
							  initialValues?.type === ReportType.OVERWEIGHTS_AND_INSURANCES ||
							  initialValues?.type === ReportType.LABELS_AND_INSURANCES
							? { num: '2' }
							: null
					}
					rules={isRoot && hasFilledValues ? { required: true } : { required: false }}
				/>
			</Grid>

			{/* Number of report files */}
			<Grid item className={classes.medium}>
				<Controller
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							disabled={
								!hasSameFileSet &&
								canNotEditCutData &&
								!reportNameConfigForOverweight &&
								overweightTypeName?.value !== ReportName.OVERWEIGHTS
							}
							options={REPORT_FILE_TYPE_OPTIONS}
							getOptionSelected={(option, value) => !!value && option === value}
							getOptionLabel={(option) => option || ''}
							renderInput={(params) => {
								return (
									<Tooltip
										title={
											!hasSameFileSet &&
											canNotEditCutData &&
											!reportNameConfigForOverweight &&
											overweightTypeName?.value !== ReportName.OVERWEIGHTS
												? 'Sobrepesos Proveedor solo puede tener corte inmediato o independiente'
												: 'Número de Archivos'
										}
									>
										<TextField
											label='Reportes'
											{...params}
											variant='filled'
											error={!!errors?.report_file_type}
										/>
									</Tooltip>
								);
							}}
							onChange={(_, data) => {
								onChange(data);
							}}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					name='report_file_type'
					control={control}
					defaultValue={initialValues?.report_file_type}
					rules={{ required: isRoot && hasFilledValues }}
				/>
			</Grid>

			{/* Report type */}
			<Grid item className={classes.extraxBig}>
				<Controller
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							// disabled={!reportNameConfigForOverweight}
							options={[
								{ title: 'Sobrepeso Plataforma', value: ReportName.OVERWEIGHTS },
								{ title: 'Sobrepeso Proveedor', value: ReportName.OVERWEIGHTS_SUPPLIER }
							]}
							getOptionSelected={(option, value) => !!value.value && option.value === value?.value}
							getOptionLabel={(option) => option.title || ''}
							renderInput={(params) => {
								return <TextField label='Sobrepeso' {...params} variant='filled' />;
							}}
							onChange={(_, data) => {
								setoverweightCutoffDate('');
								setValue && setValue('overweight_schema', '');
								onChange(data);
							}}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					name='overweight_name'
					control={control}
					defaultValue={{
						title: initialValues
							? initialValues?.name === ReportName.OVERWEIGHTS
								? 'Sobrepeso Plataforma'
								: 'Sobrepeso Proveedor'
							: 'Sobrepeso Plataforma',
						value: initialValues?.name ?? ReportName.OVERWEIGHTS
					}}
					rules={isRoot && hasFilledValues ? { required: true } : { required: false }}
				/>
			</Grid>

			{/* Cutoff day schema */}
			<Grid item className={classes.big}>
				<Controller
					name='overweight_schema'
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							options={
								overweightTypeName?.value === ReportName.OVERWEIGHTS_SUPPLIER
									? cutOptionsOverweightSupplier
									: cutOptions
							}
							disabled={!isRoot || (canNotEditCutData && hasSameFileSet)}
							getOptionLabel={(option) => capitalize(option.title) || ''}
							getOptionSelected={(option, value) => option.title === value?.title}
							onChange={(_, data) => {
								onChange(data);
								setoverweightCutoffDate(data?.title);
								if (!setValue) return;
								setValue('overweight_date', '');
								setValue('overweight_subtype', '');
							}}
							renderInput={(params) => {
								return (
									<TextField
										label='Corte'
										{...params}
										variant='filled'
										error={
											!!errors?.overweight_schema ||
											(!reportNameConfigForOverweight &&
												hasSameFileSet &&
												overweightTypeName?.value === ReportName.OVERWEIGHTS_SUPPLIER &&
												canNotEditCutData)
										}
									/>
								);
							}}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					control={control}
					defaultValue={initialValues?.schema ? { title: initialValues?.schema } : null}
					rules={{ required: isRoot && hasFilledValues }}
				/>
			</Grid>

			{/* Cutoff date */}
			<Grid item className={classes.big}>
				<Controller
					name='overweight_date'
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							options={dateOptions}
							disabled={
								!isRoot ||
								overweightCutoffDate === SchemaType.IMMEDIATE ||
								overweightCutoffDate === SchemaType.INDEPENDENT ||
								(canNotEditCutData && hasSameFileSet)
							}
							getOptionLabel={(option) => capitalize(option) || ''}
							getOptionSelected={(option, value) => option === value}
							onChange={(_, data) => {
								onChange(data);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Fecha'
									variant='filled'
									error={!!errors?.overweight_date}
								/>
							)}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					control={control}
					defaultValue={initialValues?.date}
					rules={{
						required:
							isRoot &&
							hasFilledValues &&
							overweightCutoffDate !== SchemaType.IMMEDIATE &&
							overweightCutoffDate !== SchemaType.INDEPENDENT
					}}
				/>
			</Grid>

			{/* Subtype */}
			<Grid item className={classes.big}>
				<Controller
					name='overweight_subtype'
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							options={
								overweightCutoffDate === SchemaType.IMMEDIATE ||
								overweightCutoffDate === SchemaType.BIWEEKLY ||
								overweightCutoffDate === SchemaType.WEEKLY
									? [
											overweightCutoffDate === SchemaType.IMMEDIATE
												? ''
												: overweightCutoffDate === SchemaType.BIWEEKLY
												? ReportSubType.WEEK
												: ReportSubType.BIWEEK
									  ]
									: subtypeOptions.filter(
											(item) => item !== ReportSubType.BIWEEK && item !== ReportSubType.WEEK
									  )
							}
							disabled={
								!isRoot ||
								overweightCutoffDate !== SchemaType.MONTHLY ||
								(canNotEditCutData && hasSameFileSet)
							}
							getOptionLabel={(option) => capitalize(option) || ''}
							getOptionSelected={(option, value) => option === value}
							onChange={(_, data) => {
								onChange(data);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Subtipo'
									variant='filled'
									error={!!errors?.overweight_subtype}
								/>
							)}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					control={control}
					defaultValue={initialValues?.sub_type}
					rules={{
						required: isRoot && hasFilledValues && overweightCutoffDate === SchemaType.MONTHLY
					}}
				/>
			</Grid>

			{/* Reports number of files */}
			<Grid item className={classes.big}>
				<Controller
					name='overweight_conditions_report'
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							options={reviewOptions}
							disabled={!isRoot || hasSameFileSet}
							getOptionLabel={(option) => option.title || ''}
							getOptionSelected={(option, value) => option.title === value?.title}
							onChange={(_, data) => {
								onChange(data);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Días de revisión'
									variant='filled'
									error={!!errors?.overweight_conditions_report}
								/>
							)}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					control={control}
					defaultValue={initialValues?.conditions ? { title: initialValues?.conditions } : null}
					rules={{ required: isRoot && hasFilledValues && !hasSameFileSet }}
				/>
			</Grid>

			{/* Billing */}
			<Grid item className={classes.big}>
				<Controller
					name='overweight_pre_invoice_days'
					render={({ onChange, ...propsown }) => (
						<Autocomplete
							{...propsown}
							openOnFocus
							options={billingOptions}
							disabled={!isRoot || hasSameFileSet}
							getOptionLabel={(option) => option.title || ''}
							getOptionSelected={(option, value) => option.title === value?.title}
							onChange={(_, data) => {
								onChange(data);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Días prefactura'
									error={!!errors?.overweight_pre_invoice_days}
									variant='filled'
								/>
							)}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					)}
					control={control}
					defaultValue={
						initialValues?.pre_invoice_days ? { title: initialValues?.pre_invoice_days } : null
					}
					rules={{ required: isRoot && hasFilledValues && !hasSameFileSet }}
				/>
			</Grid>
		</Grid>
	);
};

export default OverweightRow;
